<form class="portfolio-index" (ngSubmit)="saveIndex($event)">
  <mat-form-field *ngIf="auth.folio.is_aggregated" appearance="outline" class="inptbox">
    <mat-label>Portfolio</mat-label>
    <mat-select [(ngModel)]="aggregatedPortfolios" [ngModelOptions]="{standalone: true}" multiple>
      <mat-option *ngFor="let portfolio of auth.folios" [value]="portfolio.uid" [disabled]="portfolio.uid === auth.folio.uid">
        {{portfolio.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field *ngIf="!auth.folio.is_aggregated" appearance="outline" class="inptbox">
    <mat-label>{{ 'MENU_ABOVE.SEARCH' | translate }}...</mat-label>
    <input matInput type="text" [formControl]="myIndex" [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="goSelect(myIndex.value)" [autoActiveFirstOption]="true" panelWidth="auto" class="search-panel">
      <div *ngFor="let items of items$|async|groupBy:'entity.type'|orderByType|pairs">
        <mat-optgroup>
          <div class="optgroup-search">
            <div class="indexo-left">{{items[0] | nicetxt}}</div>
            <div class="indexo-right">
              <div class="optgroup-column column-1">{{((items[0] === 'company') || (items[0] === 'organization')) ? 'Country' : ''}}</div>
              <div class="optgroup-column" *ngIf="items[0] !== 'pne'"># Articles</div>
            </div>
          </div>
          <mat-option *ngFor="let option of items[1]" [value]="option">
            <div class="portfolio-indexo">
              <div class="indexo-left">
                <span>{{option.entity.name}}</span>
                <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                <!--<span class="match" *ngIf="iMatch(option.entity)">{{option.entity.matched_form}}</span>-->
              </div>
              <div class="indexo-right">
                <!--<span>{{option.sentiment|percent}}</span>
                <div class="trendicon">
                  <span class="brak">(</span>
                  <span>{{option.sentiment_delta*100|round|trend}}</span>
                  <span>{{option.sentiment_delta*100|round|negative}}</span>
                  <span class="brak">)</span>
                </div>-->
                <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : (((items[0] === 'company') || (items[0] === 'organization')) ? '\u2014' : '')}}</div>
                <div class="optgroup-column" *ngIf="items[0] !== 'pne'">{{numberFormat(option.count)}}</div>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
      </div>
    </mat-autocomplete>
  </mat-form-field>
  <div class="action">
    <button mat-raised-button color="primary" type="submit">Save</button>
    <button mat-raised-button color="warn" (click)="deleteIndex()" *ngIf="data.action=='edit'">Delete</button>
  </div>
</form>
