import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PortfolioNameComponent } from './portfolio-name/portfolio-name.component';
import { autofolio } from './preset-folio';
import { PortfolioImportComponent } from './portfolio-import/portfolio-import.component';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-portfolio',
  templateUrl: './news-portfolio.component.html',
  styleUrls: ['./news-portfolio.component.scss']
})
export class NewsPortfolioComponent {

  qparams: any;
  previousValue: any;
  empty: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    public dialog: MatDialog,
  ) {

    this.route.queryParams.subscribe(qparams => {

      if (this.auth && this.auth.folio) {
        this.empty = false;
      } else {
        this.empty = true;
      }

      if (routing.reFresh(qparams, this.previousValue, ['id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks'])) {
        this.qparams = qparams;
      }
      this.previousValue = qparams;
    });

  }

  newFolio() {
    this.dialog.open(PortfolioNameComponent, {
      data: { folio: '', action: 'new' }
    });
  }

  // inTab(uid) {
  //   this.router.navigate([], {
  //     queryParams: {
  //       id: uid,
  //       tag: null
  //     },
  //     queryParamsHandling: 'merge',
  //   });
  // }

  loadSamplePortfolio() {
    this.auth.setFolios(autofolio).subscribe(result => {
      const portfolioId = JSON.parse(result).id[0];
      // this.router.navigate([], { queryParams: { folioupdate: Math.random() }, queryParamsHandling: 'merge' });
      this.router.navigate([], {
        queryParams: {
          id: portfolioId,
          update: Math.random()
        }, queryParamsHandling: 'merge'
      });
    });
  }

  /**
   * Import list of isins or portfolios from files
   */
  iImport(event, type) {
    if ((event === null) && (type === 'isinsCreate')) {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: true
          // uid: this.params.folio,
        }
      });
    } else if ((event === null) && (type === 'isinsAdd')) {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: false
          // uid: this.params.folio,
        }
      });
    } else if ((event !== null) && (type === 'portfolio')) {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const result = JSON.parse(e.target.result);
            this.auth.newFolio(result.name).subscribe(result2 => {
              this.auth.addEntitiesToPortfolio(result.content, result2.id).subscribe(result3 => {
                this.router.navigate([], {
                  queryParams: {
                    id: result3.id,
                    update: Math.random()
                  }, queryParamsHandling: 'merge'
                });
              });
            });
          };
          reader.readAsText(event.target.files[i]);
        }
      }
    }
  }

}
