import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { ConfigService } from '../service/config.service';

/**
 * This guard redirects to some cockpit's url based on a given link with 'redirect' query parameter
 */
@Injectable()
export class RedirectGuard implements CanActivate {

  redirected: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private config: ConfigService,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const params = state.root.queryParams;
    const isRedirected = params.redirect;
    const isReferrer = params.referrer;
    if (isRedirected) {
      if (params.referrer) {
        const source = params.referrer;
        const url = window.location.href;
        const payload = {};
        Object.keys(params).forEach(item => {
          if ( ( item !== 'referrer' ) && ( item !== 'redirect' ) ) {
            payload[item] = params[item];
          }
        });
        this.auth.postReferrer(source, url, payload).subscribe();
      }
      let path = '/';
      let type = null;
      if (params.type) {
        if (params.type === 'search_query') {
          path = '/cockpit/news/query/chart';
        } else if (params.type === 'search_query_group') {
          path = '/cockpit/news/query/chart';
        } else if (params.type === 'portfolio') {
          path = '/cockpit/news/portfolio/chart';
        } else {
          path = '/cockpit/news/market/chart';
          type = params.type;
        }
      }
      this.router.navigate([path], {
        queryParams: {
          news: params.news ? params.news : null,
          type: type,
          id: params.id ? params.id : null,
          redirect: null,
          referrer: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
      return false;
    } else if (isReferrer) {
      const source = params.referrer;
      const url = window.location.href;
      const payload = {};
      Object.keys(params).forEach(item => {
        if ( !['referrer', 'redirect', 'jwt'].includes(item) ) {
          payload[item] = params[item];
        }
      });
      if (params.jwt) {
        this.auth.token = params.jwt;
      }
      this.auth.postReferrer(source, url, payload).subscribe();
      this.router.navigate([state.url.split('?')[0]], {
        queryParams: Object.assign({}, params, {referrer: null}),
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
      return false;
    } else {
      return true;
    }
  }
}
