<div class="ilabel" *ngIf="label!='Search'">{{label|ucfirst}}{{(label !== '')?' is:':''}}</div>
<mat-form-field floatLabel="never" appearance="legacy" class="query2-search" [class.mat-form-field-invalid]="error">
  <input matInput autocomplete="off" placeholder="Search..." [formControl]="mySearch" [matAutocomplete]="menuQuery" (click)="inBlur(mySearch.value)" (input)="onSearchChange(mySearch.value)" matAutocompletePosition="below">
  <mat-autocomplete #menuQuery="matAutocomplete" (optionSelected)="goSelect($event.option.value)" [autoActiveFirstOption]="true" class="searchLetter search-panel" panelWidth="auto">
    <div *ngIf="(data.operator !== 'near')">
      <div *ngIf="items$|async as search">
        <div *ngFor="let items of search.entity_prefix_search.hits | groupBy:'entity.type' | orderByType | pairs ">
          <mat-optgroup>
            <div class="optgroup-search">
              <div class="indexo-left">{{items[0] | nicetxt}}</div>
              <div class="indexo-right">
                <div class="optgroup-column column-1">{{((items[0] === 'company') || (items[0] === 'organization')) ? 'Country' : ''}}</div>
                <div class="optgroup-column"># Articles</div>
              </div>
            </div>
            <mat-option *ngFor="let option of items[1]" [value]="option">
              <div class="search2-query">
                <div class="indexo-left">
                  <span>{{option.entity.name}}</span>
                  <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                  <!--<span class="match" *ngIf="iMatch(option.entity)">{{option.entity.matched_form}}</span>-->
                </div>
                <div class="indexo-right">
                  <!--<span>{{option.sentiment|percent}}</span>
                  <div class="trendicon">
                    <span class="brak">(</span>
                    <span>{{option.sentiment_delta*100|round|trend}}</span>
                    <span>{{option.sentiment_delta*100|round|negative}}</span>
                    <span class="brak">)</span>
                  </div>-->
                  <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : (((items[0] === 'company') || (items[0] === 'organization')) ? '\u2014' : '')}}</div>
                  <div class="optgroup-column">{{numberFormat(option.count)}}</div>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>
        </div>
        <mat-optgroup label="Event" *ngIf="search.event_search.hits.length">
          <mat-option *ngFor="let option of search.event_search.hits" [value]="{type:'event',option:option}">
            <div class="search2-query">
              <div class="indexo-left">{{option.name}}</div>
            </div>
          </mat-option>
        </mat-optgroup>
        <mat-optgroup *ngIf="search.source_prefix_search.hits.length">
          <div class="optgroup-search">
            <div class="indexo-left">Source</div>
            <div class="indexo-right">
              <div class="optgroup-column column-1"></div>
              <div class="optgroup-column"># Articles</div>
            </div>
          </div>
          <mat-option *ngFor="let option of search.source_prefix_search.hits.slice(0, 1)" [value]="{type:'source',option:option}">
            <div class="search2-query">
              <div class="indexo-left">
                <span>{{option.name}}</span>
                <span class="match">{{(option.url) ? option.url : ''}}</span>
              </div>
              <div class="indexo-right">
                <div class="optgroup-column column-1"></div>
                <div class="optgroup-column">{{numberFormat(option.sources[0].doc_count)}}</div>
              </div>
            </div>
          </mat-option>
        </mat-optgroup>
      </div>
      <mat-optgroup label="Free text" *ngIf="mySearch.value!=''">
        <mat-option [value]="{type:'freetext',name:mySearch.value}">
          <div class="search2-query">
            <div class="indexo-left">{{mySearch.value}}</div>
          </div>
        </mat-option>
      </mat-optgroup>
    </div>
    <div *ngIf="(data.operator === 'near')">
      <mat-optgroup label="Free text" *ngIf="mySearch.value!=''">
        <mat-option [value]="{type:'word',name:mySearch.value}">
          <div class="search2-query">
            <div class="indexo-left">{{mySearch.value}}</div>
          </div>
        </mat-option>
      </mat-optgroup>
    </div>
  </mat-autocomplete>
</mat-form-field>
