import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { NewsletterService } from 'src/app/service/newsletter.service';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/service/config.service';
import { MatTabNav } from '@angular/material/tabs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {

  $newsletters: Observable<any>;
  allNewsletters: any;
  newsletter: any;
  newsletterParamsReady = false;
  params: any;
  previousValue: any;
  previousUrl: any;
  scrollDistance = 0;
  tabWidth = 191;
  tabsNumber = 0;
  project: string;
  blankNewsletter = {
    uid: '',
    contents: [],
    displayables: [],
    dispObjects: [{
      type: 'entity',
      payload: '',
      view: ''
    }],
    header_image: this.auth.featureFlags.newsletterBanner ? this.auth.featureFlags.newsletterBanner : '',
    logo: this.auth.featureFlags.newsletterLogo ? this.auth.featureFlags.newsletterLogo : 'https://yl-newsletters-logos-v1.s3.amazonaws.com/Logo_YUKKA_News-Analytics_Transparent.png',
    disclaimer: this.auth.featureFlags.newsletterDisclaimer ? this.auth.featureFlags.newsletterDisclaimer : '',
    from_email: this.auth.featureFlags.newsletterEmail ? this.auth.featureFlags.newsletterEmail : '',
    active: false,
    hide_sentiment_bars: false,
    recipients: [],
    recommended_news_phrases: [''],
    recommended_news_num_articles: 50,
    recommended_news_threshold: 0.05
  };

  @ViewChild(MatTabNav) public matTabNav: MatTabNav;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    public config: ConfigService,
    public newsletterService: NewsletterService
  ) {

    this.newsletterService.newsletter = this.newsletter;

    this.project = this.config.appConfig.routing.project;

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = event.url.split('?')[0];
        if (currentUrl !== this.previousUrl) {
          if (this.newsletter && this.allNewsletters) {
            let tabIndex = 0;
            const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
            sortedNewsletters.sort((a, b) => {
              return a.title.localeCompare(b.title);
            });
            sortedNewsletters.map((newslet, index) => {
              if (newslet.uid === this.params.id) {
                tabIndex = index;
              }
            });
            this.scrollDistance = this.tabWidth * tabIndex;
            this.tabsNumber = this.allNewsletters.length;
          }

          if (this.matTabNav) {
            setTimeout(() => {
              this.matTabNav.scrollDistance = this.scrollDistance;
            }, 100);
          }
        }
        this.previousUrl = currentUrl;
      }
    });

    this.route.queryParams.subscribe(params => {

      this.params = params;

      if (routing.reFresh(params, this.previousValue, ['filter'])) {
        if (params.filter) {
          Object.assign(this.auth.newsletterParams, {filter: params.filter});
        } else if (this.auth.newsletterParams.hasOwnProperty('filter')) {
          delete this.auth.newsletterParams['filter'];
        }
      }

      if (routing.reFresh(params, this.previousValue, ['id', 'rdn'])) {

        if (!params.rdn && this.allNewsletters && !(params.id === 'create')) {

          this.allNewsletters.map(data => {
            if (!params.id) {

              const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
              sortedNewsletters.sort((a, b) => {
                return a.title.localeCompare(b.title);
              });

              router.navigate([], {
                queryParams: { id: sortedNewsletters[0].uid, rdn: null },
                queryParamsHandling: 'merge',
                replaceUrl: true
              });
              // router.navigate(['newsletter', result[0].uid], {
              //   replaceUrl: true,
              // });
            } else {

              if (params.id === data.uid) {

                this.newsletter = data;
                this.auth.newsletterParams = {};


                if (this.allNewsletters.length > this.tabsNumber) {
                  let tabIndex = 0;
                  const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
                  sortedNewsletters.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                  });
                  sortedNewsletters.map((newslet, index) => {
                    if (newslet.uid === this.params.id) {
                      tabIndex = index;
                    }
                  });
                  this.scrollDistance = this.tabWidth * tabIndex;
                } else if (this.allNewsletters.length === this.tabsNumber) {
                  let tabIndex = 0;
                  const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
                  sortedNewsletters.sort((a, b) => {
                    return a.title.localeCompare(b.title);
                  });
                  sortedNewsletters.map((newslet, index) => {
                    if (newslet.uid === this.params.id) {
                      tabIndex = index;
                    }
                  });
                  if (tabIndex === 0) {
                    this.scrollDistance = 0;
                  }
                } else if (this.allNewsletters.length < this.tabsNumber) {
                  this.scrollDistance = 0;
                }
                this.tabsNumber = this.allNewsletters.length;

                if (this.matTabNav) {
                  setTimeout(() => {
                    this.matTabNav.scrollDistance = this.scrollDistance;
                  }, 100);
                }


                if (this.newsletter.hasOwnProperty('delivery_settings') && this.newsletter.delivery_settings.hasOwnProperty('delivery_time') && !(this.newsletter.delivery_time === null)) {
                  this.newsletter.delivery_time = this.newsletter.delivery_settings.delivery_time;
                }
                if (this.newsletter.hasOwnProperty('delivery_settings') && this.newsletter.delivery_settings.hasOwnPropertyfrequency && !(this.newsletter.frequency === null)) {
                  this.newsletter.frequency = this.newsletter.delivery_settings.frequency;
                }

                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.all_news_max_number') && !(this.newsletter.all_news_max_number === null)) {
                  this.newsletter.all_news_max_number = this.newsletter.news_settings.all_news_max_number;
                }
                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.recommended_news_max_number') && !(this.newsletter.recommended_news_num_articles === null)) {
                  this.newsletter.recommended_news_num_articles = this.newsletter.news_settings.recommended_news_max_number;
                }
                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.recommended_news_phrases') && !(this.newsletter.recommended_news_phrases === null)) {
                  this.newsletter.recommended_news_phrases = this.newsletter.news_settings.recommended_news_phrases;
                }
                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.news_settings.hasOwnProperty('recommended_news_threshold') && !(this.newsletter.recommended_news_threshold === null)) {
                  this.newsletter.recommended_news_threshold = this.newsletter.news_settings.recommended_news_threshold;
                }
                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.news_settings.hasOwnProperty('stories_max_number') && !(this.newsletter.stories_max_number === null)) {
                  this.newsletter.stories_max_number = this.newsletter.news_settings.stories_max_number;
                }
                if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.top_news_max_number') && !(this.newsletter.top_news_max_number === null)) {
                  this.newsletter.top_news_max_number = this.newsletter.news_settings.top_news_max_number;
                }


                if (this.newsletter.displayables) {
                  this.newsletter.dispObjects = [];
                  this.newsletter.displayables.forEach(item => {
                    const displayableArray = item.split(':');
                    if (displayableArray.length === 4) {
                      this.newsletter.dispObjects.push({
                        type: displayableArray[2],
                        payload: displayableArray[3],
                        view: ''
                      });
                    } else if (displayableArray.length === 5) {
                      this.newsletter.dispObjects.push({
                        type: displayableArray[2],
                        payload: displayableArray[3] + ':' + displayableArray[4],
                        view: ''
                      });
                    }
                  });
                }

                let newsletterTime;

                if (this.newsletter.frequency === 'DAILY') {
                  newsletterTime = '2';
                } else if (this.newsletter.frequency === 'WEEKLY') {
                  newsletterTime = '7';
                } else if (this.newsletter.frequency === 'MONTHLY') {
                  newsletterTime = '30';
                } else if (this.newsletter.frequency === 'QUARTERLY') {
                  newsletterTime = '90';
                }

                if (newsletterTime) {
                  Object.assign(this.auth.newsletterParams, {
                    time: newsletterTime
                  });
                }

                let newsletterType;

                if (this.newsletter.contents.includes('all_news')) {
                  newsletterType = 'Chronological';
                } else if (this.newsletter.contents.includes('top_news')) {
                  newsletterType = 'Relevant';
                } else if (this.newsletter.contents.includes('curated_news')) {
                  newsletterType = 'Bookmarked';
                } else if (this.newsletter.contents.includes('stories')) {
                  newsletterType = 'Stories';
                }

                if (newsletterType) {
                  Object.assign(this.auth.newsletterParams, {
                    newstype: newsletterType
                  });
                }

                this.auth.getFilter().subscribe(filters => {

                  let newsletterFilter = null;

                  if (this.newsletter.cockpit_filter_id) {

                    newsletterFilter = filters.filter(item => {
                      return item.id === this.newsletter.cockpit_filter_id;
                    })[0];

                    if (newsletterFilter && newsletterFilter.time) {
                      Object.assign(this.auth.newsletterParams, {time: newsletterFilter.time});
                    }
                    if (newsletterFilter && newsletterFilter.filter) {
                      Object.assign(this.auth.newsletterParams, {filter: newsletterFilter.filter});
                    }
                    if (newsletterFilter && newsletterFilter.lang) {
                      Object.assign(this.auth.newsletterParams, {lang: newsletterFilter.lang});
                    }
                    if (newsletterFilter && newsletterFilter.feed) {
                      Object.assign(this.auth.newsletterParams, {feed: newsletterFilter.feed});
                    }
                    if (newsletterFilter && newsletterFilter.event_ids) {
                      Object.assign(this.auth.newsletterParams, {event_ids: newsletterFilter.event_ids});
                    }
                    if (newsletterFilter && newsletterFilter.categories) {
                      Object.assign(this.auth.newsletterParams, {categories: newsletterFilter.categories});
                    }
                    if (newsletterFilter && newsletterFilter.continents) {
                      Object.assign(this.auth.newsletterParams, {continents: newsletterFilter.continents});
                    }
                    if (newsletterFilter && newsletterFilter.countries) {
                      Object.assign(this.auth.newsletterParams, {countries: newsletterFilter.countries});
                    }
                    if (newsletterFilter && newsletterFilter.ranks) {
                      Object.assign(this.auth.newsletterParams, {ranks: newsletterFilter.ranks});
                    }

                  }
                  let type;
                  let id;
                  let payload;
                  if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'entity') {

                    type = this.newsletter.dispObjects[0].payload.split(':')[0];
                    id = this.newsletter.dispObjects[0].payload.split(':')[1];
                    payload = this.newsletter.dispObjects[0].payload;

                    Object.assign(this.auth.newsletterParams, {
                      type: type,
                      id: id,
                      payload: payload
                    });

                    this.newsletterParamsReady = true;
                    this.newsletterService.newsletterParamsReady = true;

                    router.navigate([], {
                      queryParams: { update: Math.random() },
                      queryParamsHandling: 'merge',
                      replaceUrl: true
                    });

                  } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'portfolio') {

                    this.auth.portFolio(this.newsletter.dispObjects[0].payload).subscribe(result2 => {

                      type = 'portfolio';
                      id = this.newsletter.dispObjects[0].payload;
                      payload = result2.content;

                      Object.assign(this.auth.newsletterParams, {
                        type: type,
                        id: id,
                        payload: payload
                      });

                      this.newsletterParamsReady = true;
                      this.newsletterService.newsletterParamsReady = true;

                      router.navigate([], {
                        queryParams: { update: Math.random() },
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                      });

                    });

                  } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'search_query_group') {

                    this.auth.getQueryGroup(this.newsletter.dispObjects[0].payload).subscribe(result2 => {

                      type = 'search_query';
                      id = this.newsletter.dispObjects[0].payload;

                      const groups = [];

                      result2.forEach(element => {
                        groups.push(element.query);
                      });

                      payload = {
                        operator: 'or',
                        filters: [],
                        groups: groups
                      };

                      Object.assign(this.auth.newsletterParams, {
                        type: type,
                        id: id,
                        payload: payload
                      });

                      this.newsletterParamsReady = true;
                      this.newsletterService.newsletterParamsReady = true;

                      router.navigate([], {
                        queryParams: { update: Math.random() },
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                      });

                    });

                  } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'search_query') {

                    this.auth.getQuery().subscribe(result2 => {

                      type = 'search_query';
                      id = this.newsletter.dispObjects[0].payload;
                      payload = result2.filter(item => {
                        return item.uid === id;
                      })[0].query;

                      Object.assign(this.auth.newsletterParams, {
                        type: type,
                        id: id,
                        payload: payload
                      });

                      this.newsletterParamsReady = true;
                      this.newsletterService.newsletterParamsReady = true;

                      router.navigate([], {
                        queryParams: { update: Math.random() },
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                      });

                    });

                  }
                });
              }
            }
          });
          if (!this.newsletter) {
            this.newsletter = JSON.parse(JSON.stringify(this.blankNewsletter));
          }

          this.newsletterService.newsletter = this.newsletter;

        } else {

          this.newsletter = undefined;
          this.newsletterParamsReady = false;
          this.newsletterService.newsletterParamsReady = false;
          this.$newsletters = this.auth.allNewsletter();
          this.$newsletters.subscribe(result => {
            this.allNewsletters = result;
            result.map(data => {
              if (!params.id) {

                const sortedNewsletters = JSON.parse(JSON.stringify(result));
                sortedNewsletters.sort((a, b) => {
                  return a.title.localeCompare(b.title);
                });

                router.navigate([], {
                  queryParams: { id: sortedNewsletters[0].uid, rdn: null },
                  queryParamsHandling: 'merge',
                  replaceUrl: true
                });
                // router.navigate(['newsletter', result[0].uid], {
                //   replaceUrl: true,
                // });
              } else {

                if (params.id === data.uid) {

                  this.newsletter = data;
                  this.auth.newsletterParams = {};


                  if (this.allNewsletters.length > this.tabsNumber) {
                    let tabIndex = 0;
                    const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
                    sortedNewsletters.sort((a, b) => {
                      return a.title.localeCompare(b.title);
                    });
                    sortedNewsletters.map((newslet, index) => {
                      if (newslet.uid === this.params.id) {
                        tabIndex = index;
                      }
                    });
                    this.scrollDistance = this.tabWidth * tabIndex;
                  } else if (this.allNewsletters.length === this.tabsNumber) {
                    let tabIndex = 0;
                    const sortedNewsletters = JSON.parse(JSON.stringify(this.allNewsletters));
                    sortedNewsletters.sort((a, b) => {
                      return a.title.localeCompare(b.title);
                    });
                    sortedNewsletters.map((newslet, index) => {
                      if (newslet.uid === this.params.id) {
                        tabIndex = index;
                      }
                    });
                    if (tabIndex === 0) {
                      this.scrollDistance = 0;
                    }
                  } else if (this.allNewsletters.length < this.tabsNumber) {
                    this.scrollDistance = 0;
                  }
                  this.tabsNumber = this.allNewsletters.length;

                  if (this.matTabNav) {
                    setTimeout(() => {
                      this.matTabNav.scrollDistance = this.scrollDistance;
                    }, 100);
                  }


                  if (this.newsletter.hasOwnProperty('delivery_settings') && this.newsletter.delivery_settings.hasOwnProperty('delivery_time') && !(this.newsletter.delivery_time === null)) {
                    this.newsletter.delivery_time = this.newsletter.delivery_settings.delivery_time;
                  }
                  if (this.newsletter.hasOwnProperty('delivery_settings') && this.newsletter.delivery_settings.hasOwnPropertyfrequency && !(this.newsletter.frequency === null)) {
                    this.newsletter.frequency = this.newsletter.delivery_settings.frequency;
                  }

                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.all_news_max_number') && !(this.newsletter.all_news_max_number === null)) {
                    this.newsletter.all_news_max_number = this.newsletter.news_settings.all_news_max_number;
                  }
                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.recommended_news_max_number') && !(this.newsletter.recommended_news_num_articles === null)) {
                    this.newsletter.recommended_news_num_articles = this.newsletter.news_settings.recommended_news_max_number;
                  }
                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.recommended_news_phrases') && !(this.newsletter.recommended_news_phrases === null)) {
                    this.newsletter.recommended_news_phrases = this.newsletter.news_settings.recommended_news_phrases;
                  }
                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.news_settings.hasOwnProperty('recommended_news_threshold') && !(this.newsletter.recommended_news_threshold === null)) {
                    this.newsletter.recommended_news_threshold = this.newsletter.news_settings.recommended_news_threshold;
                  }
                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.news_settings.hasOwnProperty('stories_max_number') && !(this.newsletter.stories_max_number === null)) {
                    this.newsletter.stories_max_number = this.newsletter.news_settings.stories_max_number;
                  }
                  if (this.newsletter.hasOwnProperty('news_settings') && this.newsletter.hasOwnProperty('news_settings.top_news_max_number') && !(this.newsletter.top_news_max_number === null)) {
                    this.newsletter.top_news_max_number = this.newsletter.news_settings.top_news_max_number;
                  }


                  if (this.newsletter.displayables) {
                    this.newsletter.dispObjects = [];
                    this.newsletter.displayables.forEach(item => {
                      const displayableArray = item.split(':');
                      if (displayableArray.length === 4) {
                        this.newsletter.dispObjects.push({
                          type: displayableArray[2],
                          payload: displayableArray[3],
                          view: ''
                        });
                      } else if (displayableArray.length === 5) {
                        this.newsletter.dispObjects.push({
                          type: displayableArray[2],
                          payload: displayableArray[3] + ':' + displayableArray[4],
                          view: ''
                        });
                      }
                    });
                  }

                  let newsletterTime;

                  if (this.newsletter.frequency === 'DAILY') {
                    newsletterTime = '2';
                  } else if (this.newsletter.frequency === 'WEEKLY') {
                    newsletterTime = '7';
                  } else if (this.newsletter.frequency === 'MONTHLY') {
                    newsletterTime = '30';
                  } else if (this.newsletter.frequency === 'QUARTERLY') {
                    newsletterTime = '90';
                  }

                  if (newsletterTime) {
                    Object.assign(this.auth.newsletterParams, {
                      time: newsletterTime
                    });
                  }

                  let newsletterType;

                  if (this.newsletter.contents.includes('all_news')) {
                    newsletterType = 'Chronological';
                  } else if (this.newsletter.contents.includes('top_news')) {
                    newsletterType = 'Relevant';
                  } else if (this.newsletter.contents.includes('curated_news')) {
                    newsletterType = 'Bookmarked';
                  } else if (this.newsletter.contents.includes('stories')) {
                    newsletterType = 'Stories';
                  }

                  if (newsletterType) {
                    Object.assign(this.auth.newsletterParams, {
                      newstype: newsletterType
                    });
                  }

                  this.auth.getFilter().subscribe(filters => {

                    let newsletterFilter = null;

                    if (this.newsletter.cockpit_filter_id) {

                      newsletterFilter = filters.filter(item => {
                        return item.id === this.newsletter.cockpit_filter_id;
                      })[0];

                      if (newsletterFilter && newsletterFilter.time) {
                        Object.assign(this.auth.newsletterParams, {time: newsletterFilter.time});
                      }
                      if (newsletterFilter && newsletterFilter.filter) {
                        Object.assign(this.auth.newsletterParams, {filter: newsletterFilter.filter});
                      }
                      if (newsletterFilter && newsletterFilter.lang) {
                        Object.assign(this.auth.newsletterParams, {lang: newsletterFilter.lang});
                      }
                      if (newsletterFilter && newsletterFilter.feed) {
                        Object.assign(this.auth.newsletterParams, {feed: newsletterFilter.feed});
                      }
                      if (newsletterFilter && newsletterFilter.event_ids) {
                        Object.assign(this.auth.newsletterParams, {event_ids: newsletterFilter.event_ids});
                      }
                      if (newsletterFilter && newsletterFilter.categories) {
                        Object.assign(this.auth.newsletterParams, {categories: newsletterFilter.categories});
                      }
                      if (newsletterFilter && newsletterFilter.continents) {
                        Object.assign(this.auth.newsletterParams, {continents: newsletterFilter.continents});
                      }
                      if (newsletterFilter && newsletterFilter.countries) {
                        Object.assign(this.auth.newsletterParams, {countries: newsletterFilter.countries});
                      }
                      if (newsletterFilter && newsletterFilter.ranks) {
                        Object.assign(this.auth.newsletterParams, {ranks: newsletterFilter.ranks});
                      }

                    }
                    let type;
                    let id;
                    let payload;
                    if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'entity') {

                      type = this.newsletter.dispObjects[0].payload.split(':')[0];
                      id = this.newsletter.dispObjects[0].payload.split(':')[1];
                      payload = this.newsletter.dispObjects[0].payload;

                      Object.assign(this.auth.newsletterParams, {
                        type: type,
                        id: id,
                        payload: payload
                      });

                      this.newsletterParamsReady = true;
                      this.newsletterService.newsletterParamsReady = true;

                      router.navigate([], {
                        queryParams: { update: Math.random() },
                        queryParamsHandling: 'merge',
                        replaceUrl: true
                      });

                    } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'portfolio') {

                      this.auth.portFolio(this.newsletter.dispObjects[0].payload).subscribe(result2 => {

                        type = 'portfolio';
                        id = this.newsletter.dispObjects[0].payload;
                        payload = result2.content;

                        Object.assign(this.auth.newsletterParams, {
                          type: type,
                          id: id,
                          payload: payload
                        });

                        this.newsletterParamsReady = true;
                        this.newsletterService.newsletterParamsReady = true;

                        router.navigate([], {
                          queryParams: { update: Math.random() },
                          queryParamsHandling: 'merge',
                          replaceUrl: true
                        });

                      });

                    } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'search_query_group') {

                      this.auth.getQueryGroup(this.newsletter.dispObjects[0].payload).subscribe(result2 => {

                        type = 'search_query';
                        id = this.newsletter.dispObjects[0].payload;

                        const groups = [];

                        result2.forEach(element => {
                          groups.push(element.query);
                        });

                        payload = {
                          operator: 'or',
                          filters: [],
                          groups: groups
                        };

                        Object.assign(this.auth.newsletterParams, {
                          type: type,
                          id: id,
                          payload: payload
                        });

                        this.newsletterParamsReady = true;
                        this.newsletterService.newsletterParamsReady = true;

                        router.navigate([], {
                          queryParams: { update: Math.random() },
                          queryParamsHandling: 'merge',
                          replaceUrl: true
                        });

                      });

                    } else if (this.newsletter.dispObjects && this.newsletter.dispObjects[0].type === 'search_query') {

                      this.auth.getQuery().subscribe(result2 => {

                        type = 'search_query';
                        id = this.newsletter.dispObjects[0].payload;
                        payload = result2.filter(item => {
                          return item.uid === id;
                        })[0].query;

                        Object.assign(this.auth.newsletterParams, {
                          type: type,
                          id: id,
                          payload: payload
                        });

                        this.newsletterParamsReady = true;
                        this.newsletterService.newsletterParamsReady = true;

                        router.navigate([], {
                          queryParams: { update: Math.random() },
                          queryParamsHandling: 'merge',
                          replaceUrl: true
                        });

                      });

                    }
                  });
                }
              }
            });
            if (!this.newsletter) {
              this.newsletter = JSON.parse(JSON.stringify(this.blankNewsletter));
            }

            this.newsletterService.newsletter = this.newsletter;

          });

        }

      }

      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        setTimeout(() => {
          if (this.matTabNav) {
            this.matTabNav._alignInkBarToSelectedTab();
          }
        }, 500);
      }

      this.previousValue = params;
    });

  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.matTabNav) {
        this.matTabNav._alignInkBarToSelectedTab();
      }
    }, 1000);
  }

  addNewsletter() {
    this.router.navigate([], {
      queryParams: { id: 'create' },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  }

  inTabNewsletter(uid) {
    this.scrollDistance = this.matTabNav.scrollDistance;
    this.router.navigate([], {
      queryParams: {
        id: uid,
        rdn: null
      },
      queryParamsHandling: 'merge',
    });
  }

  inDelete(uid) {
    if (window.confirm('Do you want to delete this newsletter?')) {
      this.auth.removeNewsletter(uid).subscribe(result => {
        if (uid === this.params.id) {
          this.newsletter = JSON.parse(JSON.stringify(this.blankNewsletter));
          this.router.navigate([], {
            queryParams: { id: null, rdn: Math.random() },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
        }
        this.$newsletters = this.auth.allNewsletter();
      });
    }
  }

  checkCurrentNewsletterIndex(item, allItems) {
    const currentNewsletterId = this.params.id;
    let current;
    let previous;
    allItems.forEach((el, index) => {
      if (currentNewsletterId === el.uid) {
        current = el.uid;
        if (index > 0) {
          previous = allItems[index - 1].uid;
        }
      }
    });
    if (current === item) {
      return true;
    } else if (previous && (previous === item)) {
      return true;
    } else {
      return false;
    }
  }

}
