import {Component, Inject, Input, OnChanges, Output, EventEmitter, ViewEncapsulation} from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RoutingService } from 'src/app/service/routing.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-newsreport',
  templateUrl: './news-report.component.html',
  styleUrls: ['./news-report.component.scss']
})
export class NewsReportComponent implements OnChanges {

  message: UntypedFormControl;
  required = Validators.nullValidator;

  @Input('reportData') data: any;
  @Input('reportView') reportView!: any;
  @Output() reportViewChange = new EventEmitter();

  reportType = 'sentiment';
  reportSentence = '';
  reportSentiment = '';
  reportComment = '';
  reportError = '';
  reportEntity = '';
  reportEvent = '';
  reportParticipant = '';
  pin = '';
  submitted = false;

  mytag: any;
  myevent: any;
  myparticipant: any;
  inmatches: any;

  entityControl = {entity: {name: ''}};

  constructor(
    private matDialog: MatDialog,
    public dialogRef: MatDialogRef<NewsReportComponent>,
    public auth: AuthService,
    public snackBar: MatSnackBar,
    public routing: RoutingService,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    // if (
    //   this.data.report === 'Custom report' ||
    //   this.data.report === 'Entity not recognized'
    // ) {
    //   this.message = new FormControl('', [Validators.required]);
    // } else {
    //   this.message = new FormControl('', []);
    // }

  }

  ngOnChanges() {

    this.submitted = false;

    this.mytag = undefined;
    this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};

    this.myevent = {id: undefined, type: undefined, name: undefined};
    this.myparticipant = {alpha_id: undefined, type: undefined, name: undefined};

    const pinReportTab = JSON.parse(localStorage.getItem('pinReportTab'));
    if (pinReportTab) {
      this.pin = pinReportTab;
      this.reportType = pinReportTab;
    }

  }

  /**
   * trigger the target entities to display
   */
  clickTarget(match) {
    this.submitted = false;
    this.entityControl = match;
    if (this.mytag && match && (this.mytag.entity.alpha_id === match.entity.alpha_id)) {
      this.mytag = undefined;
      // this.inmatches = this.data.inmatches;
      this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
    } else {
      this.mytag = match;
      this.inmatches = match.entity;
    }
  }

  onCancel() {
    this.reportView = false;
    this.reportViewChange.emit(this.reportView);
  }

  onSubmit() {

    if (this.isValid()) {

      let match = '';
      let report = '';
      if (this.reportType === 'sentiment') {
        match = this.mytag.entity.compound_key;
        report = 'Sentiment ' + this.reportSentiment;
      } else if (this.reportType === 'entity') {
        if (this.reportError === 'wrong entity') {
          match = this.mytag.entity.compound_key;
          report = 'Wrong entity recognized';
        } else if (this.reportError === 'unrecognized entity') {
          match = this.reportEntity;
          report = 'Entity not recognized';
        }
      } else if (this.reportType === 'event') {
        if (this.reportError === 'wrong event') {
          match = this.myevent.type + ':' + this.myevent.id;
          report = 'Wrong event recognized';
        } else if (this.reportError === 'unrecognized event') {
          match = this.reportEvent;
          report = 'Event not recognized';
        } else if (this.reportError === 'wrong participant') {
          match = this.myparticipant.type + ':' + this.myparticipant.alpha_id;
          report = 'Wrong participant recognized';
        } else if (this.reportError === 'unrecognized participant') {
          match = this.reportParticipant;
          report = 'Participant not recognized';
        }
      } else if (this.reportType === 'other') {
        match = this.data.inmatches.compound_key;
        report = 'Custom report';
      }

      const data = {
        time: Date.now(),
        email: this.data.email,
        message: this.reportComment,
        reportInfo: {
          type: this.reportType,
          error: this.reportError,
          sentence: this.reportSentence,
          comment: this.reportComment,
          sentiment: this.reportSentiment,
          entity: this.mytag ? (this.mytag.entity.name) : this.reportEntity,
          entityType: this.mytag ? (this.mytag.entity.type) : null,
          entityId: this.mytag ? (this.mytag.entity.alpha_id) : null,
          event: this.myevent.name ? this.myevent.name : this.reportEvent,
          eventType: this.myevent.type ? this.myevent.type : null,
          eventId: this.myevent.id ? this.myevent.id : null,
          participant: this.myparticipant.name ? this.myparticipant.name : this.reportParticipant,
          participantType: this.myparticipant.type ? this.myparticipant.type : null,
          participantId: this.myparticipant.alpha_id ? this.myparticipant.alpha_id : null,
          cockpitUrl: window.location.href
        },
        report: report,
        label: 'todo',
        match: match,
        tag: this.data.tag,
        apicall: JSON.stringify(this.data.newsInfo),
        project: this.data.project,
        language: this.data.newsInfo.lang,
        url: window.location.origin + '/widget/reader?news=' + this.data.newsId
      };
      this.auth.storeReport(data).subscribe(result => {
        this.snackBar.open('Report sent!', 'OK', { duration: 5000 });
        this.auth.iSlack({ report: data, id: result }).subscribe();
        this.submitted = true;
      });

      // this.reportSentence = '';
      // this.reportSentiment = '';
      // this.reportComment = '';
      // this.reportError = '';
      // this.reportEntity = '';
      // this.reportEvent = '';
      // this.reportParticipant = '';
      // this.mytag = undefined;
      // this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
      // this.myevent = {id: undefined, type: undefined, name: undefined};
      // this.myparticipant = {alpha_id: undefined, type: undefined, name: undefined};

    } else {
      alert('Error: all of the required fields must be fulfilled.');
    }

  }

  onSelection(type) {
    this.submitted = false;
    if (this.pin) {
      this.pinReport();
    }
    this.reportType = type;
    this.reportSentence = '';
    this.reportSentiment = '';
    this.reportComment = '';
    this.reportError = '';
    this.reportEntity = '';
    this.reportEvent = '';
    this.reportParticipant = '';
    this.mytag = undefined;
    this.inmatches = {alpha_id: undefined, type: undefined, compound_key: undefined};
    this.myevent = {id: undefined, type: undefined, name: undefined};
    this.myparticipant = {alpha_id: undefined, type: undefined, name: undefined};
  }

  pinReport() {
    if (this.pin) {
      this.pin = '';
    } else {
      this.pin = this.reportType;
    }
    localStorage.setItem('pinReportTab', JSON.stringify(this.pin));
  }

  isValid() {
    if (this.reportType === 'sentiment') {
      if (this.mytag === undefined) { return false; }
      if (!this.reportSentence.trim()) { return false; }
      if (!this.reportSentiment) { return false; }
    } else if (this.reportType === 'entity') {
      if (this.reportError === 'wrong entity') {
        if (this.mytag === undefined) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else if (this.reportError === 'unrecognized entity') {
        if (!this.reportEntity.trim()) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else {
        return false;
      }
    } else if (this.reportType === 'event') {
      if (this.reportError === 'wrong event') {
        if (this.myevent.id === undefined) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else if (this.reportError === 'unrecognized event') {
        if (!this.reportEvent.trim()) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else if (this.reportError === 'wrong participant') {
        if (this.myevent.id === undefined) { return false; }
        if (this.myparticipant.alpha_id === undefined) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else if (this.reportError === 'unrecognized participant') {
        if (this.myevent.id === undefined) { return false; }
        if (!this.reportParticipant.trim()) { return false; }
        if (!this.reportSentence.trim()) { return false; }
      } else {
        return false;
      }
    } else if (this.reportType === 'other') {
      if (!this.reportComment.trim()) { return false; }
    } else {
      return false;
    }
    return true;
  }

  pinTooltip() {
    if (!this.pin) {
      return 'Pin the Reporting Tab';
    } else {
      return 'Unpin the Reporting Tab';
    }
  }

  setSentiment(type) {
    this.submitted = false;
    this.reportSentiment = (this.reportSentiment === type) ? '' : type;
  }

  setError(type) {
    this.submitted = false;
    this.reportError = (this.reportError === type) ? '' : type;
  }

  setEvent(event) {
    this.submitted = false;
    if (event.id === this.myevent.id) {
      this.myevent = {id: undefined, type: undefined, name: undefined};
    } else {
      this.myevent = event;
    }
  }

  setParticipant(participant) {
    this.submitted = false;
    if (participant.alpha_id === this.myparticipant.alpha_id) {
      this.myparticipant = {alpha_id: undefined, type: undefined, name: undefined};
    } else {
      this.myparticipant = participant;
    }
  }

  compareFn(option1, option2) {
    return option1.entity.name === option2.entity.name;
  }

}
