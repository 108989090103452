<div class="menu-search" *ngIf="auth.featureFlags.showSearchMenu">
  <!--<div class="history" [class.mobile]="routing.isMobile()" *ngIf="auth.featureFlags.showNavigation && !routing.isIframe()">
    <button mat-button class="btnback" (click)="location.back()" matTooltip="Back" matTooltipPosition="above">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-button class="btnforw" (click)="location.forward()" matTooltip="Next" matTooltipPosition="above">
      <mat-icon>arrow_forward</mat-icon>
    </button>
  </div>-->
  <div class="inpsearch" [class.mobile]="routing.isMobile()">
    <mat-icon class="iconsearch srcicon">search</mat-icon>
    <mat-form-field *ngIf="auth.featureFlags.showSearchBar" floatLabel="never">
      <!-- <mat-label>{{ 'MENU_ABOVE.SEARCH' | translate }}...</mat-label> -->
      <mat-label>{{label}}</mat-label>
      <input matInput #inputAutoComplete type="text" [formControl]="mysearch" [matAutocomplete]="menuSearch" (focus)="onFocus()" name="topSearchInput" autocomplete="off">
      <div class="iresult" *ngIf="!loading && mystart">{{(items$|async)?.entity_prefix_search.hits.length}} results
      </div>
      <mat-autocomplete #menuSearch="matAutocomplete" (optionSelected)="goSelect($event.option.value)" [autoActiveFirstOption]="true" class="filterSearch search-panel" panelWidth="auto">
        <!--<div *ngIf="emptyInput && !routing.isMobile() && !routing.isIframe()">
          <mat-option value="search"><mat-icon>zoom_in</mat-icon>Advanced Search</mat-option>
          <mat-option value="request"><mat-icon>library_add</mat-icon>Request New Entity</mat-option>
        </div>-->
        <div *ngIf="(!emptyInput || routing.isMobile() || routing.isIframe()) && items$|async as search">
          <div *ngFor="let items of search.entity_prefix_search?.hits | groupBy:'entity.type' | orderByType | pairs; let i = index;">
            <mat-optgroup *ngIf="(items[0] !== 'product') && (items[0] !== 'product_model') && (items[0] !== 'topic')">
              <div class="optgroup-search">
                <div class="indexo-left">{{items[0] | nicetxt}}</div>
                <div class="indexo-right">
                  <div class="optgroup-column column-1">{{((items[0] === 'company') || (items[0] === 'organization')) ? 'Country' : ''}}</div>
                  <div class="optgroup-column"># Articles</div>
                </div>
              </div>
              <mat-option *ngFor="let option of items[1] | slice:0:(checkShowMoreEntities(i) ? 1000 : 5)" [value]="option">
                <div class="search-indexo">
                  <div class="indexo-left">
                    <span>{{option.entity.name}}</span>
                    <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                    <!--<span class="match" *ngIf="iMatch(option.entity)">{{option.entity.matched_form}}</span>-->
                  </div>
                  <div class="indexo-right">
                    <!--<span>{{option.sentiment|percent}}</span>
                    <div class="trendicon">
                      <span class="brak">(</span>
                      <span>{{option.sentiment_delta*100|round|trend}}</span>
                      <span>{{option.sentiment_delta*100|round|negative}}</span>
                      <span class="brak">)</span>
                    </div>-->
                    <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : (((items[0] === 'company') || (items[0] === 'organization')) ? '\u2014' : '')}}</div>
                    <div class="optgroup-column">{{(option.count || (option.count === 0)) ? numberFormat(option.count) : ''}}</div>
                  </div>
                </div>
              </mat-option>
              <mat-option class="expand-search-results" [disabled]="true" *ngIf="items[1] && (items[1].length > 5)">
                <mat-icon *ngIf="!checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">arrow_drop_down</mat-icon>
                <span *ngIf="!checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">Show more {{(items[0] | nicetxt:true).toLowerCase()}}</span>
                <mat-icon *ngIf="checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">arrow_drop_up</mat-icon>
                <span *ngIf="checkShowMoreEntities(i)" (click)="$event.stopPropagation(); expandEntitiesGroup(i);">Show less {{(items[0] | nicetxt:true).toLowerCase()}}</span>
              </mat-option>
            </mat-optgroup>
          </div>
          <!--<mat-optgroup label="Event" *ngIf="search.event_search?.hits.length">
            <mat-option *ngFor="let option of search.event_search?.hits" [value]="{type:'event',option:option}">
              <div class="search-indexo">
                <div class="indexo-left">
                  <span>{{option.name}}</span>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>-->

          <mat-optgroup *ngIf="!loadingPNE && itemsPNE.length">
            <div class="optgroup-search">
              <div class="indexo-left">Other Potential Entity</div>
              <div class="indexo-right"></div>
            </div>
            <mat-option *ngFor="let option of itemsPNE | slice:0:checkShowMorePNEs()" [value]="{type:'pne',pne:option}">
              <div class="search-indexo">
                <div class="indexo-left">
                  <span>{{option.surface}}</span>
                  <span class="match">{{(option.type) ? option.type : ''}}</span>
                </div>
                <div class="indexo-right">
                  <div class="optgroup-column column-1"></div>
                  <div class="optgroup-column">{{(option.count || (option.count === 0)) ? numberFormat(option.count) : ''}}</div>
                </div>
              </div>
            </mat-option>
            <mat-option class="expand-search-results" [disabled]="true" *ngIf="itemsPNE && (itemsPNE.length > 5)">
              <mat-icon *ngIf="!showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">arrow_drop_down</mat-icon>
              <span *ngIf="!showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">Show more potential entities</span>
              <mat-icon *ngIf="showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">arrow_drop_up</mat-icon>
              <span *ngIf="showMorePNEs" (click)="$event.stopPropagation(); expandPNEsGroup();">Show less potential entities</span>
            </mat-option>
          </mat-optgroup>
          <mat-optgroup *ngIf="loadingPNE">
            <div class="optgroup-search">
              <div class="indexo-left">Other Potential Entity</div>
              <div class="indexo-right" style="justify-content: end;">
                <mat-spinner [diameter]="15"></mat-spinner>
              </div>
            </div>
          </mat-optgroup>

          <!--<mat-optgroup *ngIf="search.source_prefix_search?.hits.length">
            <div class="optgroup-search">
              <div class="indexo-left">Source</div>
              <div class="indexo-right">
                <div class="optgroup-column column-1"></div>
                <div class="optgroup-column"># Articles</div>
              </div>
            </div>
            <mat-option *ngFor="let option of search.source_prefix_search?.hits" [value]="{type:'source',option:option}">
              <div class="search-indexo">
                <div class="indexo-left">
                  <span>{{option.name}}</span>
                  <span class="match">{{(option.url) ? option.url : ''}}</span>
                </div>
                <div class="indexo-right">
                  <div class="optgroup-column column-1"></div>
                  <div class="optgroup-column">{{numberFormat(option.sources[0].doc_count)}}</div>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>-->
          <!--<mat-optgroup label="Query" *ngIf="mysearch.value!='' && search.parse_boolean_query">
            <mat-option [value]="{type:'query',query:search.parse_boolean_query}">
              <div class="search-indexo">
                <div class="indexo-left">
                  <span>{{mysearch.value}}</span>
                </div>
              </div>
            </mat-option>
          </mat-optgroup>-->
          <!--<mat-optgroup label="Request this entity" *ngIf="search.entity_prefix_search?.hits.length==0" [class.style]="'red'">
            <mat-option [value]="{type:'ask4', value:mysearch.value}">
              <span class="search-indexo">{{mysearch.value}}</span>
            </mat-option>
          </mat-optgroup>-->
          <mat-optgroup label="Can't find what you're looking for?" *ngIf="!routing.isMobile() && !routing.isIframe()">
            <!--<mat-option *ngIf="mysearch.value!='' && search.parse_boolean_query" [value]="{type:'query',query:search.parse_boolean_query}"><mat-icon>search</mat-icon>Search {{mysearch.value}}</mat-option>-->
            <mat-option *ngIf="mysearch.value==''" value="search" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search</mat-option>
            <mat-option *ngIf="mysearch.value!='' && !search.parse_boolean_query" [value]="{type:'searchValue',value:mysearch.value}" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search {{mysearch.value}}</mat-option>
            <mat-option *ngIf="mysearch.value!='' && search.parse_boolean_query" [value]="{type:'searchQuery',query:search.parse_boolean_query}" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search {{mysearch.value}}</mat-option>
            <mat-option *ngIf="mysearch.value=='' || search.entity_prefix_search?.hits.length!=0" value="request" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request New Entity</mat-option>
            <mat-option *ngIf="mysearch.value!='' && search.entity_prefix_search?.hits.length==0" [value]="{type:'ask4', value:mysearch.value}" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request Entity {{mysearch.value}}</mat-option>
          </mat-optgroup>
        </div>
        <mat-optgroup label="Can't find what you're looking for?" *ngIf="emptyInput && !routing.isMobile() && !routing.isIframe()">
          <mat-option value="search" matTooltip="Use different search criteria to combine companies, topics, events." matTooltipPosition="right"><mat-icon>zoom_in</mat-icon>Advanced Search</mat-option>
          <mat-option value="request" matTooltip="Send us a request to add a company to our database." matTooltipPosition="right"><mat-icon>library_add</mat-icon>Request New Entity</mat-option>
        </mat-optgroup>
        <mat-optgroup label="Previous Entity Searches" *ngIf="emptyInput">
          <mat-option *ngFor="let option of previousSearches" [value]="option">
            <div class="search-indexo">
              <div class="indexo-left">
                <span>{{(option.entity) ? option.entity.name : ''}}</span>
              </div>
              <div class="indexo-right"></div>
            </div>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-spinner [diameter]="15" *ngIf="loading"></mat-spinner>
    </mat-form-field>
    <!--<mat-icon [matMenuTriggerFor]="menuAdd" *ngIf="auth.featureFlags.showSearchQuery" class="iconsearch" [class.hide]="component==='multichart' || routing.isIframe()" matTooltip="Add query / entity" matTooltipPosition="above">playlist_add</mat-icon>
    <mat-menu #menuAdd="matMenu">
      <button mat-menu-item (click)="queryDialog()">Add search query</button>
      <button mat-menu-item (click)="requestEntityDialog()">Request new entity</button>
    </mat-menu>
    <mat-icon class="srcicon hide" [class.show]="routing.isIframe()">search</mat-icon>-->
  </div>
  <div *ngIf="auth.featureFlags.showFilter && !(routing.isInvest() || routing.isHot())" class="filters" [class.mobile]="routing.isMobile()">
    <app-menu-filters></app-menu-filters>
  </div>
</div>
