<div class="treemap-template">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="contindex">
    <div class="indexname" *ngFor="let item of treemapdata | orderBy: myOrder | diff: myArray"
      [style.color]="item.sentiment | color">
      <div class="indexcont">
        <div class="tito box-map" (click)="goMap(item.entity)">
          <div class="back" [style.background-color]="item.sentiment | color"></div>
          <div class="tino" [innerHTML]="item.entity.name | nicetxt | isbeta">
          </div>
          <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'||!params.sort">
            <div class="percent">
              <span class="brak">(</span>{{item.sentiment | percent}}<span class="brak">)</span>
            </div>
            <span class="numb">
              <span class="brak">(</span>
              <span>{{item.sentiment_delta*100|round|trend}}</span>
              <span>{{item.sentiment_delta*100|round|negative}}</span>
              <span class="brak">)</span>
            </span>
          </div>
        </div>
        <div class="subo">
          <div class="text box-map"
            *ngFor="let subitem of item.children | orderBy: myOrder | initial: item.children.length-3"
            [style.color]="subitem.sentiment | color" (click)="goMap(subitem.entity)">
            <div class="back" [style.background-color]="subitem.sentiment | color"></div>
            <div class="tino">{{subitem.entity.name | nicetxt}}</div>
            <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'">
              <div class="percent">
                <span class="brak">(</span>
                {{subitem.sentiment | percent}}
                <span class="brak">)</span>
              </div>
              <span class="numb">
                <span class="brak">(</span>
                <span>{{subitem.sentiment_delta*100|round|trend}}</span>
                <span>{{subitem.sentiment_delta*100|round|negative}}</span>
                <span class="brak">)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>