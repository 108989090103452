<div class="chartmap-info" *ngIf="iready else loadingComponent">
  <mat-tab-group [(selectedIndex)]="selectedTabIndex">
    <mat-tab *ngFor="let tab of tabNames" [label]="tab">
      <form>
        <div class="info-header discover" *ngIf="tab==='Discover'">
          <mat-form-field class="info-header-content">
            <mat-label>Entity type</mat-label>
            <mat-select class="info-filters" [(ngModel)]="selectedDiscoverGroup" name="discoverGroup">
              <mat-option *ngFor="let group of discoverGroups" [value]="group">
                {{group}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="selectedDiscoverGroup === 'Companies'" class="info-header-content">
            <mat-label>Countries</mat-label>
            <mat-select class="info-filters" [(ngModel)]="selectedCountry" name="country">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let country of countries | orderBy: 'label'" [value]="country.value">
                {{country.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="selectedDiscoverGroup === 'Companies'" class="info-header-content">
            <mat-label>Sectors</mat-label>
            <mat-select class="info-filters" [(ngModel)]="selectedSector" name="sector">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let sector of sectors | orderBy: 'label'" [value]="sector.value">
                {{sector.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="info-header participants" *ngIf="tab==='Participants'">
          <mat-form-field class="info-header-content">
            <mat-label>Countries</mat-label>
            <mat-select class="info-filters" [(ngModel)]="selectedCountry2" name="country">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let country of countries2 | orderBy: 'label'" [value]="country.value">
                {{country.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="info-header-content">
            <mat-label>Sectors</mat-label>
            <mat-select class="info-filters" [(ngModel)]="selectedSector2" name="sector">
              <mat-option>All</mat-option>
              <mat-option *ngFor="let sector of sectors2 | orderBy: 'label'" [value]="sector.value">
                {{sector.label}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <!--<mat-divider></mat-divider>-->
        <div class="info-content" *ngIf="tab === 'Discover'">
          <mat-list>
            <mat-list-item *ngFor="let item of filteredInfoLists(selectedDiscoverGroup) | orderBy: '-volume'; let i = index" [class.active]="(item.tag && activeTag==item.tag) || (item.compound_key && activeParticipant==item.compound_key) || (item.id && item.type && (activeEvent.type==item.type) && (activeEvent.id==item.id))">
              <div mat-line class="row" (click)="goItem(item, selectedDiscoverGroup)">
                <div class="left">{{i+1}}. {{item.name}}</div>
                <div class="right" [matTooltip]="((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? 'Volume' : 'Volume ratio'">{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? '' : 'x'}}{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? item.volume : item.volume.toFixed(1)}}</div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="info-content" *ngIf="tab === 'Participants'">
          <mat-list>
            <mat-list-item *ngFor="let item of filteredInfoLists(tab) | orderBy: '-volume'; let i = index" [class.active]="(item.tag && activeTag==item.tag) || (item.compound_key && activeParticipant==item.compound_key) || (item.id && item.type && (activeEvent.type==item.type) && (activeEvent.id==item.id))">
              <div mat-line class="row" (click)="goItem(item, tab)">
                <div class="left">{{i+1}}. {{item.name}}</div>
                <div class="right" [matTooltip]="((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? 'Volume' : 'Volume ratio'">{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? '' : 'x'}}{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? item.volume : item.volume.toFixed(1)}}</div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="info-content" *ngIf="tab === 'Portfolio'">
          <mat-list>
            <mat-list-item>
              <div mat-line class="row title">
                <div class="left">Event Participants</div>
                <div class="right"></div>
              </div>
            </mat-list-item>
            <mat-list-item *ngFor="let item of filteredInfoLists('PortfolioParticipants') | orderBy: '-volume'; let i = index" [class.active]="(item.tag && activeTag==item.tag) || (item.compound_key && activeParticipant==item.compound_key) || (item.id && item.type && (activeEvent.type==item.type) && (activeEvent.id==item.id))">
              <div mat-line class="row" (click)="goItem(item, tab)">
                <div class="left">{{i+1}}. {{item.name}}</div>
                <div class="right" [matTooltip]="((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? 'Volume' : 'Volume ratio'">{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? '' : 'x'}}{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? item.volume : item.volume.toFixed(1)}}</div>
              </div>
            </mat-list-item>
          </mat-list>
          <mat-list>
            <mat-list-item>
              <div mat-line class="row title">
                <div class="left">Other Portfolio Companies</div>
                <div class="right"></div>
              </div>
            </mat-list-item>
            <mat-list-item *ngFor="let item of filteredInfoLists('PortfolioCompanies') | orderBy: '-volume'; let i = index" [class.active]="(item.tag && activeTag==item.tag) || (item.compound_key && activeParticipant==item.compound_key) || (item.id && item.type && (activeEvent.type==item.type) && (activeEvent.id==item.id))">
              <div mat-line class="row" (click)="goItem(item, tab)">
                <div class="left">{{i+1}}. {{item.name}}</div>
                <div class="right" [matTooltip]="((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? 'Volume' : 'Volume ratio'">{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? '' : 'x'}}{{((tab === 'Discover') && (selectedDiscoverGroup === 'Events')) ? item.volume : item.volume.toFixed(1)}}</div>
              </div>
            </mat-list-item>
          </mat-list>
        </div>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #loadingComponent>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
</ng-template>
