import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../service/routing.service';
import { YukkApi } from '../../../service/yukkapi.service';
import { AuthService } from '../../../service/auth.service';
import { MatDialog } from '@angular/material/dialog';
import {MobileGuard} from '../../../guard/mobile.guard';
import {AuthGuard} from '../../../guard/auth.guard';
import {NewsGuard} from '../../../guard/news.guard';
import {TrendGuard} from '../../../guard/trend.guard';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-hack-main',
  templateUrl: './hack-main.component.html',
  styleUrls: ['./hack-main.component.scss']
})
export class HackMainComponent implements OnInit {

  // staticNewsfeedAllURL = 'assets/static/all_news.json';
  // staticNewsfeedTopURL = 'assets/static/news_with_og_tags.json';
  staticNewsfeedAllURL = 'assets/static/all_news_full_news_75.json';
  staticNewsfeedTopURL = 'assets/static/top_news_full_news_events_with_og_tags_75.json';
  staticNewsfeedAllData: any;
  staticNewsfeedTopData: any;

  hierarchy: boolean;
  entity: boolean;
  folios: any;
  params: any;
  user: any;
  listsignal: any;
  sentiment: any;
  promptEvent: any;

  public clock: any;

  portfolio = ['Alphabet Inc', 'J.P. Morgan Asset Management', 'McKinsey & Co', 'Tesco PLC', 'Tesla Inc', 'Southern California Edison Company'];
  selected = 'Hackathon Portfolio';

  scoreEvents: any;
  mosaicScore: any;
  nodata: boolean;
  loading: boolean;
  iready: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    private yukkApi: YukkApi,
    private auth: AuthService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {

    this.user = this.auth.authToken();

    setInterval(() => {
      this.clock = moment().format('ddd, MMM Do YYYY, HH:mm:ss');
    }, 1000);

    this.router.navigate([], {
      queryParams: {
        theme: 'light',
        newstype: 'Chronological',
        cutnoise: 'true',
        fadeout: 'false',
        time: 100,
        lang: 'en'
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params));

      this.nodata = false;
      this.loading = true;
      this.iready = false;

      const paramsChange = Object.assign({}, this.params, {
        // scorePortfolio: true,
        requestScore: 'general_risk',
        scoreForPortfolio: true,
        scoreScanner: true
      });

      this.yukkApi.scoresTimeSeries('score', paramsChange, '').subscribe(result => {
        this.mosaicScore = ((result !== undefined) && (result['score_ts'][result['score_ts'].length - 1].score !== null)) ? result['score_ts'][result['score_ts'].length - 1].score.toFixed(1) : undefined;
      });
      this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, paramsChange, {
        with_top_events: true
      }), 'chart').subscribe(result => {
        this.scoreEvents = result.top_events ? result.top_events : [];
        let nonEmpty = false;
        this.scoreEvents.forEach(item => {
          if (item.contrib_amount > 0) {
            nonEmpty = true;
          }
        });
        this.nodata = false;
        if (nonEmpty) {
          this.iready = true;
        } else {
          this.iready = false;
          this.loading = false;
        }
      }, error => {
        this.nodata = true;
        this.loading = false;
      });

    });

    this.http.get(this.staticNewsfeedAllURL).subscribe(res => {
      console.log(res);
      this.staticNewsfeedAllData = res;
    });

    this.http.get(this.staticNewsfeedTopURL).subscribe(res => {
      console.log(res);
      this.staticNewsfeedTopData = res;
    });

  }

  ngOnInit() {
  }

  iBaroScore(array, value) {
    const scores = array.map(event => event.contrib_amount);
    const maxscore = Math.max.apply(Math, scores);
    return ((value * 100) / maxscore);
  }

  changeNews(cutNoise) {
    if (cutNoise) {
      this.router.navigate([], {
        queryParams: {
          newstype: 'Chronological',
          cutnoise: 'true'
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          newstype: 'Chronological',
          cutnoise: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

}
