import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { helpData } from './help-data';
import { HelpViewerComponent } from './help-viewer/help-viewer.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent {

  board: any;
  view: any;
  currentHelpData: any;
  currentScoreType: any;
  safeSrc: SafeResourceUrl;
  selectedTab: number;
  feedbackMessage: string;
  feedbackTitle: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {component: string},
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {

    this.selectedTab = 0;
    this.feedbackMessage = '';
    this.feedbackTitle = '';

    this.currentScoreType = this.auth.getScoreName().full;

    if (data && (data.component === 'filter')) {
      this.currentHelpData = helpData(this.currentScoreType).filter;
    } else if (data && (data.component === 'article')) {
      this.currentHelpData = helpData(this.currentScoreType).article;
    } else if (this.routing.isScanner()) {
      this.currentHelpData = helpData(this.currentScoreType).scanner;
    } else if (this.routing.isFolio() && this.routing.isMain()) {
      this.currentHelpData = helpData(this.currentScoreType).portfolio.edit;
    } else if (this.routing.isFolio() && this.routing.isNewsaudit()) {
      this.currentHelpData = helpData(this.currentScoreType).portfolio.audit;
    } else if (this.routing.isQuery() && this.routing.isMain()) {
      this.currentHelpData = helpData(this.currentScoreType).collection.edit;
    } else if (this.routing.isQuery() && this.routing.isNewsaudit()) {
      this.currentHelpData = helpData(this.currentScoreType).collection.audit;
    } else if (this.routing.isScanner()) {
      this.currentHelpData = helpData(this.currentScoreType).scanner;
    } else if (this.routing.isChart()) {
      this.currentHelpData = helpData(this.currentScoreType).chart;
    } else if (this.routing.isChart2()) {
      this.currentHelpData = helpData(this.currentScoreType).benchmark;
    } else if (this.routing.isRadar()) {
      this.currentHelpData = helpData(this.currentScoreType).radar;
    } else if (this.routing.isNetwork()) {
      this.currentHelpData = helpData(this.currentScoreType).network;
    } else if (this.routing.isParticipants()) {
      this.currentHelpData = helpData(this.currentScoreType).outlier;
    } else if (this.routing.isHot()) {
      this.currentHelpData = helpData(this.currentScoreType).trending;
    } else if (this.routing.isNewsletter()) {
      this.currentHelpData = helpData(this.currentScoreType).newsletter;
    }

    if (this.currentHelpData && this.currentHelpData.video) {
      this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.currentHelpData.video);
    }

  }

  openManual() {
    this.dialog.open(HelpViewerComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%'
    });
  }

  onSelect(event) {
    this.selectedTab = event.index;
  }

  sendFeedback() {
    if (this.feedbackMessage && this.feedbackMessage.trim()) {
      const title = this.feedbackTitle + ' - ' + this.auth.userdata?.email;
      const message = this.feedbackMessage;
      this.auth.sendUserFeedback(title, message).subscribe(res => {
        if (res['status'] > 400) {
          this.snackBar.open('Feedback could not be sent.', 'OK', { duration: 5000, panelClass: ['red-snackbar'] });
        } else {
          this.snackBar.open('Feedback has been sent.', 'OK', { duration: 5000 });
          this.feedbackMessage = '';
          this.feedbackTitle = '';
        }
      }, error => {
        this.snackBar.open('Feedback could not be sent.', 'OK', { duration: 5000, panelClass: ['red-snackbar'] });
      });
    }
  }

}
