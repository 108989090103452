import { Component, OnInit, OnChanges, SimpleChanges, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';
import { MatDialog } from '@angular/material/dialog';
import { PortfolioNameComponent } from '../news-portfolio/portfolio-name/portfolio-name.component';
import { QueryNameComponent } from '../news-query/query-name/query-name.component';
import { FormControl } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, startWith, tap } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-title',
  templateUrl: './news-title.component.html',
  styleUrls: ['./news-title.component.scss']
})
export class NewsTitleComponent implements OnChanges {

  // @ViewChild('sectorsDialog') sectorsDialog: ElementRef;

  @Input('titolo') titolo: any;
  params: any;
  previousValue: any;
  folios: any;
  folio: any;
  query: any;
  id: string;
  label: string;
  period: number;
  showWarningFlag = false;
  showCautionFlag = false;
  flagScore: number;
  industryBenchmarkScore = null;
  industryBenchmarkDescription = '';
  scoreEvents = null;
  scoreEventsCount = null;
  scoreEventsTimeframe = null;
  flagWarningEvents = null;
  flagWarningEventsCount = null;
  flagWarningEventsTimeframe = null;
  flagCautionEvents = null;
  flagCautionEventsCount = null;
  flagCautionEventsTimeframe = null;

  mouseOverCautionFlag = false;
  mouseOverWarningFlag = false;
  mouseOverIndustryBench = false;
  mouseOverScore = false;
  isScoreFocused = false;

  entities = [];
  queries = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public auth: AuthService,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public dialog: MatDialog,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.period = params.period ? params.period : 7;

      if (routing.reFresh(params, this.previousValue, ['id', 'groupId', 'portfolioId'])) {

        if (routing.isFolio()) {
          if (this.auth.folios) {
            const portfolioId = params.portfolioId ? params.portfolioId : params.id;
            this.folio = this.auth.folios.filter(el => {
              return el.uid === portfolioId;
            })[0];
            const entities = this.folio.content;
            this.yukkApi.getEntitiesInfo(entities).subscribe(result => {
              this.entities = Object.values(result).filter(element => {
                return element !== null;
              });
              this.entities.forEach(item => {
                item.name = (item.name !== 'PNE') ? item.name : (item.alpha_id.split('.')[1] + ' (' + item.alpha_id.split('.')[0] + ')');
              });
            });
          } else {
            this.entities = [];
          }
          // this.folios = this.auth.folios;
          // this.folio = this.auth.folio.name;
          // this.id = this.auth.folio.id;
        }

        if (routing.isQuery()) {
          if (this.auth.querys) {
            const collectionId = params.groupId;
            this.query = this.auth.querys.filter(el => {
              return el.uid === collectionId;
            })[0];
            this.queries = this.query.content;
          } else {
            this.queries = [];
          }
          // this.folios = this.auth.querys;
          // this.folio = this.auth.query.name;
          // this.id = this.auth.query.id;
        }

      }

      if (routing.reFresh(params, this.previousValue, ['updateFlag'])) {
        this.checkWarningFlag();
        this.checkCautionFlag();
      }

      this.previousValue = params;

    });

  }

  ngOnChanges(changes: SimpleChanges) {

    this.scoreEventsCount = null;
    this.scoreEventsTimeframe = null;
    this.flagWarningEventsCount = null;
    this.flagWarningEventsTimeframe = null;
    this.flagCautionEventsCount = null;
    this.flagCautionEventsTimeframe = null;
    // this.isScoreFocused = false;

    this.checkWarningFlag();
    this.checkCautionFlag();
    this.getIndustryBenchmark();
    this.getScoreEvents();

    if (!this.routing.isWidget()) {
      this.router.navigate([], {
        queryParams: {
          reflow: Math.random()
        },
        queryParamsHandling: 'merge'
      });
    }

    if (this.titolo && this.params.type && (this.params.type === 'pne')) {
      this.titolo.entity.name = this.titolo.entity.alpha_id.split('.')[1] + ' (' + this.titolo.entity.alpha_id.split('.')[0] + ')';
    } else if (this.auth && this.auth.folio && this.auth.folio.marketPortfolio && this.titolo && this.params.type && (['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) {
      this.titolo.entity.type = this.params.type;
      this.titolo.entity.name = this.auth.folio.marketPortfolioName;
    }

  }

  getScoreEvents() {
    if ((this.auth.scorelabSettings.defaultScore === 'sentiment') || ['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore)) {
      this.yukkApi.relatedEvents(this.params, 'sub_events').subscribe(result => {
        const resCopy = JSON.parse(JSON.stringify(result));
        this.setScoreEventsInfo(resCopy);
      });
    } else if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))))) && (this.routing.isFolio() || (this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) || (this.params.isin))) {
      if (this.auth.scorelabSettings.defaultScore === 'credit_risk') {
        this.yukkApi.scoresTimeSeries('score', this.params, 'chart').subscribe(result => {
          const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;
          this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
            with_top_events: true,
            with_all_events: true,
            time: 100,
            top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
          }), 'chart').subscribe(result2 => {
            const resCopy = JSON.parse(JSON.stringify(result2));
            this.setScoreEventsInfo(resCopy);
          });
        });
      } else {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          time: 100
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setScoreEventsInfo(resCopy);
        });
      }
    }
  }

  getFlagEvents(type) {
    if ((this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') || ['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultFlag.score_type)) {
      this.yukkApi.relatedEvents(Object.assign({}, this.params, {
        time: ((type === 'warning') || ((type === 'caution') && !this.checkLongTimeframe())) ? (this.params.time ? this.params.time : null) : 365
      }), 'sub_events').subscribe(result => {
        const resCopy = JSON.parse(JSON.stringify(result));
        this.setFlagEventsInfo(resCopy, type);
      });
    } else {
      let time = null;
      if (type === 'warning') {
        time = 100;
      } else if (type === 'caution') {
        time = 465;
      }
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type
        }), 'chart').subscribe(result => {
          const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;
          this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
            with_top_events: true,
            with_all_events: true,
            requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
            time: time,
            top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
          }), 'chart').subscribe(result2 => {
            const resCopy = JSON.parse(JSON.stringify(result2));
            this.setFlagEventsInfo(resCopy, type);
          });
        });
      } else {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          with_top_events: true,
          with_all_events: true,
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
          time: time
        }), 'chart').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setFlagEventsInfo(resCopy, type);
        });
      }
    }
  }

  setScoreEventsInfo(scoreEventsObj) {
    this.scoreEvents = JSON.parse(JSON.stringify(scoreEventsObj));
    if ((this.auth.scorelabSettings.defaultScore === 'sentiment') || ['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore)) {
      let count = 0;
      scoreEventsObj.forEach(event => {
        count += event.count;
      });
      this.scoreEventsCount = count;
      this.scoreEventsTimeframe = this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7;
    } else {
      let count = 0;
      scoreEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      this.scoreEventsCount = count;
      this.scoreEventsTimeframe = 100;
    }
  }

  setFlagEventsInfo(flagEventsObj, flagType) {
    if (flagType === 'warning') {
      this.flagWarningEvents = JSON.parse(JSON.stringify(flagEventsObj));
    } else if (flagType === 'caution') {
      this.flagCautionEvents = JSON.parse(JSON.stringify(flagEventsObj));
    }
    if ((this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') || ['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultFlag.score_type)) {
      let count = 0;
      flagEventsObj.forEach(event => {
        count += event.count;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
        this.flagWarningEventsTimeframe = this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
        this.flagCautionEventsTimeframe = this.checkLongTimeframe() ? 365 : (this.params.time ? this.yukkApi.myFromTo(this.params.time).period : 7);
      }
    } else {
      let count = 0;
      flagEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
      }
      if (flagType === 'warning') {
        this.flagWarningEventsTimeframe = 100;
      } else if (flagType === 'caution') {
        this.flagCautionEventsTimeframe = 465;
      }
    }
  }

  getIndustryBenchmark() {

    if ((this.auth.scorelabSettings.defaultScore === 'esg') && (this.params && (this.params.type === 'company'))) {
      this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
        requestScore: this.auth.scorelabSettings.defaultScore,
        industry_bench: true
      }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
        let industry = 'Industry';
        if (this.titolo?.entity?.description?.Industry) {
          industry = this.titolo.entity.description.Industry.name;
        }
        this.industryBenchmarkScore = res.score_ts_last_score.score;
        if (res.score_ts_last_score.score === 0.0) {
          this.industryBenchmarkDescription = 'Laggard';
        } else if (res.score_ts_last_score.score === 1.0) {
          this.industryBenchmarkDescription = 'Below average';
        } else if (res.score_ts_last_score.score === 2.0) {
          this.industryBenchmarkDescription = 'Above average';
        } else if (res.score_ts_last_score.score === 3.0) {
          this.industryBenchmarkDescription = 'Leader';
        }
        this.industryBenchmarkDescription += ' in ' + industry;
      });
    } else {
      this.industryBenchmarkScore = null;
      this.industryBenchmarkDescription = '';
    }

  }

  checkWarningFlag() {

    if (this.titolo && this.titolo.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(this.titolo.entity.type)) {
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        const currentScore = this.titolo.sentiment.sentiment * 100;
        if (currentScore) {
          this.flagScore = currentScore;
          if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
            if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
              this.showWarningFlag = true;
              this.auth.showWarningFlag = true;
              this.auth.showWarningFlagLoader();
              this.getFlagEvents('warning');
            } else {
              this.showWarningFlag = false;
              this.auth.showWarningFlag = false;
              this.auth.hideWarningFlagLoader();
            }
          } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
            if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
              this.showWarningFlag = true;
              this.auth.showWarningFlag = true;
              this.auth.showWarningFlagLoader();
              this.getFlagEvents('warning');
            } else {
              this.showWarningFlag = false;
              this.auth.showWarningFlag = false;
              this.auth.hideWarningFlagLoader();
            }
          }
        } else {
          this.showWarningFlag = false;
          this.auth.showWarningFlag = false;
          this.auth.hideWarningFlagLoader();
        }
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type
        }), 'chart').pipe(catchError(error => of({score_ts_last_score: {score: null}}))).subscribe(res => {
          const currentScore = res.score_ts_last_score.score;
          if (currentScore) {
            this.flagScore = currentScore;
            if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
              if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                this.showWarningFlag = true;
                this.auth.showWarningFlag = true;
                this.auth.showWarningFlagLoader();
                this.getFlagEvents('warning');
              } else {
                this.showWarningFlag = false;
                this.auth.showWarningFlag = false;
                this.auth.hideWarningFlagLoader();
              }
            } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
              if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                this.showWarningFlag = true;
                this.auth.showWarningFlag = true;
                this.auth.showWarningFlagLoader();
                this.getFlagEvents('warning');
              } else {
                this.showWarningFlag = false;
                this.auth.showWarningFlag = false;
                this.auth.hideWarningFlagLoader();
              }
            }
          } else {
            this.showWarningFlag = false;
            this.auth.showWarningFlag = false;
            this.auth.hideWarningFlagLoader();
          }
        });
      } else {
        this.showWarningFlag = false;
        this.auth.showWarningFlag = false;
        this.auth.hideWarningFlagLoader();
      }
    } else {
      this.showWarningFlag = false;
      this.auth.showWarningFlag = false;
      this.auth.hideWarningFlagLoader();
    }

  }

  checkCautionFlag() {

    if (this.titolo && this.titolo.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(this.titolo.entity.type)) {
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        this.yukkApi.scoresTimeSeries('sentiment', Object.assign({}, this.params, {
          time: this.checkLongTimeframe() ? 365 : (this.params.time ? this.params.time : null)
        }), '').pipe(catchError(error => of({quotient_ts: []}))).subscribe(res => {
          let thresholdReached = false;
          res.quotient_ts.forEach(item => {
            const currentScore = item.quotient * 100;
            if (currentScore) {
              if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
                if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
                if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              }
            }
          });
          if (thresholdReached) {
            this.showCautionFlag = true;
            this.auth.showCautionFlag = true;
            this.auth.showCautionFlagLoader();
            this.getFlagEvents('caution');
          } else {
            this.showCautionFlag = false;
            this.auth.showCautionFlag = false;
            this.auth.hideCautionFlagLoader();
          }
        });
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.params, {
          requestScore: this.auth.scorelabSettings.defaultFlag.score_type,
          time: 365
        }), 'chart').pipe(catchError(error => of({score_ts: []}))).subscribe(res => {
          let thresholdReached = false;
          res.score_ts.forEach(item => {
            const currentScore = item.score;
            if (currentScore) {
              if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
                if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
                if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
                  thresholdReached = true;
                }
              }
            }
          });
          if (thresholdReached) {
            this.showCautionFlag = true;
            this.auth.showCautionFlag = true;
            this.auth.showCautionFlagLoader();
            this.getFlagEvents('caution');
          } else {
            this.showCautionFlag = false;
            this.auth.showCautionFlag = false;
            this.auth.hideCautionFlagLoader();
          }
        });
      } else {
        this.showCautionFlag = false;
        this.auth.showCautionFlag = false;
        this.auth.hideCautionFlagLoader();
      }
    } else {
      this.showCautionFlag = false;
      this.auth.showCautionFlag = false;
      this.auth.hideCautionFlagLoader();
    }
  }

  getFlagTooltip(type) {
    const threshold = this.auth.scorelabSettings.defaultFlag.threshold;
    const scoreName = this.auth.getScoresInfo(this.auth.scorelabSettings.defaultFlag.score_type)['full'];
    let score;
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      score = this.flagScore + ' of the ' + scoreName;
    } else {
      score = this.flagScore?.toFixed(2);
    }
    let tooltip = '';
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      if (type === 'warning') {
        tooltip = 'Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
      } else if (type === 'caution') {
        tooltip = 'One of the ' + scoreName + ' values in the past year crossed the set threshold of ' + threshold + '.';
      }
    } else {
      if (type === 'warning') {
        tooltip = 'One or more of the events associated with ' + scoreName + ' occurred in the past 100 days. Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
      } else if (type === 'caution') {
        tooltip = 'One or more of the events associated with ' + scoreName + ' occurred in the past 465 days. One of the values in the past year crossed the set threshold of ' + threshold + '.';
      }
    }
    return {
      tooltip: tooltip,
      score_type: this.auth.scorelabSettings.defaultFlag.score_type,
      score_name: this.auth.getScoresInfo(this.auth.scorelabSettings.defaultFlag.score_type)['name'],
      score_threshold: (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') ? 'High' : 'Low'
    };
  }

  // inFolio(value) {
  //   this.router.navigate([], {
  //     queryParams: {
  //       id: this.folios.filter(folio => folio.name === value)[0].uid
  //     },
  //     queryParamsHandling: 'merge'
  //   });
  // }

  displayFn(element) {
    return element && element.name ? element.name : '';
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(item => item.name.toLowerCase().includes(filterValue));
  }

  inAverage() {
    if (this.period > 200) { this.period = 200; }
    if (this.period < 1) { this.period = 1; }
    this.router.navigate([], {
      queryParams: {
        period: this.period
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  select(item) {
    if (item === 'overall') {
      if (this.routing.isFolio()) {
        this.titolo.entity.type = 'Portfolio';
        this.titolo.entity.name = this.folio.name;
        this.router.navigate([], {
          queryParams: {
            id: this.params.portfolioId ? this.params.portfolioId : this.params.id,
            type: null,
            newstype: null,
            portfolioId: null,
            score: null,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      } else if (this.routing.isQuery()) {
        this.titolo.entity.type = 'Collection';
        this.titolo.entity.name = this.query.name;
        this.router.navigate([], {
          queryParams: {
            id: this.params.groupId,
            newstype: null,
            groupId: this.params.groupId,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      }
    } else {
      if (this.routing.isFolio()) {
        this.titolo.entity.type = item.type;
        this.titolo.entity.name = item.name;
        this.router.navigate([], {
          queryParams: {
            id: item.alpha_id,
            type: item.type,
            newstype: null,
            portfolioId: this.params.portfolioId ? this.params.portfolioId : this.params.id,
            score: null,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      } else if (this.routing.isQuery()) {
        this.titolo.entity.type = 'Query';
        this.titolo.entity.name = item.name;
        this.router.navigate([], {
          queryParams: {
            id: item.uid,
            newstype: null,
            groupId: this.params.groupId,
            reflow: Math.random()
          },
          queryParamsHandling: 'merge'
        });
      }
    }
  }

  onScoreFocus(type, score, timeframe, element) {
    if (!this.isScoreFocused || (this.params && this.params.score && (score !== this.params.score))) {
      const focusedColor = window.getComputedStyle(element).getPropertyValue('border-bottom-color');
      this.auth.scoreFocusedColor = focusedColor;
      this.auth.scoreFocusedTimeframe = timeframe;
      if (type === 'score') {
        this.auth.scoreFocusedTopEvents = this.scoreEvents;
      } else if (type === 'warning') {
        this.auth.scoreFocusedTopEvents = this.flagWarningEvents;
      } else if (type === 'caution') {
        this.auth.scoreFocusedTopEvents = this.flagCautionEvents;
      }
      this.isScoreFocused = true;
      this.router.navigate([], {
        queryParams: {
          score: score,
          reflow: Math.random()
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.auth.scoreFocusedColor = 'transparent';
      this.auth.scoreFocusedTimeframe = null;
      this.auth.scoreFocusedTopEvents = null;
      this.isScoreFocused = false;
      this.router.navigate([], {
        queryParams: {
          score: null,
          reflow: Math.random()
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  appendItem(option) {
    if (this.routing.isMarket()) {
      this.dialog.open(PortfolioNameComponent, {
        data: {
          action: option,
          entity: this.titolo.entity
        }
      });
    }
    if (this.routing.isSearch()) {
      this.dialog.open(QueryNameComponent, {
        data: {
          action: option,
          query: this.titolo.entity
        }
      });
    }
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  checkTimeframe() {
    if (this.params.time) {
      const days = this.yukkApi.myFromTo(this.params.time).period;
      if (days < 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe() {
    if (this.params.time) {
      const days = this.yukkApi.myFromTo(this.params.time).period;
      if (days < 365) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

}
