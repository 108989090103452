import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, interval } from 'rxjs';
import { RoutingService } from 'src/app/service/routing.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import { QueryAlertComponent } from 'src/app/cockpit/main-news/news-query/query-alert/query-alert.component';
import { ScorelabSetupComponent } from 'src/app/project/settings/scorelab-setup/scorelab-setup.component';
import { AlertsSetupComponent } from 'src/app/project/settings/alerts-setup/alerts-setup.component';
import { FlagsSetupComponent } from 'src/app/project/settings/flags-setup/flags-setup.component';

@Component({
  selector: 'app-menu-aside',
  templateUrl: './menu-aside.component.html',
  styleUrls: ['./menu-aside.component.scss']
})
export class MenuAsideComponent implements OnInit, OnDestroy {

  // notifySubscription: any;
  // notificationsCount: number;

  asidetxt: boolean;
  params: any;
  user: any = {
    id: '',
    email: '',
    account: 'customer',
    subscription: 'all',
    expire: ''
  };
  project: any;
  setup: any;
  subscription: string;
  account: boolean;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public routing: RoutingService,
    public location: Location,
    public auth: AuthService,
    private router: Router,
    public config: ConfigService
  ) {
    this.project = this.config.appConfig.routing.project;
    this.setup = this.config.appConfig.setup;
    this.asidetxt = localStorage.getItem('aside') === 'true';
    this.user = this.auth.authToken();
    if (this.user) { this.subscription = this.user.subscription; }
    this.route.queryParams.subscribe(params => {
      this.params = params;
    });
  }

  ngOnInit() {
    // this.notificationsCount = 0;
    // this.notifySubscription = interval(15000).subscribe(value => {
    //   this.auth.getQueryAlerts().subscribe(result => {
    //     if (result && (result.length !== 0)) {
    //       this.auth.getNotifications(99).subscribe(result2 => {
    //         this.notificationsCount = result2.filter(item => {
    //           return item.seen === false;
    //         }).length;
    //       });
    //     }
    //   });
    // });
  }

  ngOnDestroy() {
    // this.notifySubscription.unsubscribe();
  }

  logOut() {
    this.auth.authLogout();
  }

  myAsidetxt() {
    this.asidetxt = !this.asidetxt;
    localStorage.setItem('aside', this.asidetxt.toString());
  }

  /**
   * open dialog tutorial
   */
  inInfo() {
    const path = location.pathname.split('/')[3];
    let tool: string;
    let tutorial_steps: string;
    if (path === 'market') {
      tool = 'market';
      tutorial_steps = 'market' + ',newsfeed';
      let itol = location.pathname.split('/')[4];
      if (itol === 'chart') {
        // itol = 'entity';
      } else if (itol === 'audit') {
        tool = 'newsview';
        tutorial_steps = tool + ',newsviewfeeds,newsviewinsights';
        itol = null;
      }
      if (itol) {
        tool = itol;
        tutorial_steps = itol + ',newsfeed';
      }
    }
    if (path === 'portfolio') {
      tool = 'portfolio';
      tutorial_steps = 'portfolio' + ',newsfeed';
      let itol = location.pathname.split('/')[4];
      if (itol === 'chart2') {
        itol = 'multichart';
      } else if (itol === 'audit') {
        tool = 'newsview';
        tutorial_steps = tool + ',newsviewfeeds,newsviewinsights';
        itol = null;
      }
      if (itol) {
        tool = itol;
        tutorial_steps = itol + ',newsfeed';
      }
    }
    if (path === 'query') {
      tool = 'collection';
      tutorial_steps = 'collection' + ',newsfeed';
      let itol = location.pathname.split('/')[4];
      if (itol === 'chart2') {
        itol = 'multichart';
      } else if (itol === 'audit') {
        tool = 'newsview';
        tutorial_steps = tool + ',newsviewfeeds,newsviewinsights';
        itol = null;
      }
      if (itol) {
        tool = itol;
        tutorial_steps = itol + ',newsfeed';
      }
    }
    if (path === 'trending') {
      tool = 'trending';
      tutorial_steps = tool;
    }
    if (path === 'secsignal') {
      tool = 'secsignal1';
      tutorial_steps = tool + ',secsignal2';
    }
    if (path === 'indicator') {
      tool = 'indicator1';
      tutorial_steps = tool + ',indicator2';
    }
    this.router.navigate([], {
      queryParams: {
        tutorial: tool,
        tutorial_steps: tutorial_steps
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   * open mat-dialog for notifications
   */
  inNotifications () {
    console.log('notify');
    this.dialog.open(QueryAlertComponent);
  }

  /**
   * open mat-dialog for settings
   */
  openSettings () {
    this.dialog.open(ScorelabSetupComponent, {
      autoFocus: false
    });
  }

  /**
   * open mat-dialog for alerts
   */
  openAlerts () {
    this.dialog.open(AlertsSetupComponent, {
      autoFocus: false
    });
  }

  /**
   * open mat-dialog for flags
   */
  openFlags () {
    this.dialog.open(FlagsSetupComponent, {
      autoFocus: false
    });
  }

  // iNews(value) {
  //   this.router.navigate(
  //     ['news', value],
  //     {
  //       queryParams: this.iQparams(),
  //       queryParamsHandling: 'merge',
  //       relativeTo: this.route
  //     }
  //   )
  // }

  // iQparams() {
  //   return {
  //     news: null,
  //     event: null
  //   }
  // }

}
