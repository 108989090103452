<div class="alerts-main">
    <div class="alerts-list">
        <div class="alerts-list-header">
            <div class="alerts-list-title">Alerts</div>
            <div class="alerts-list-info">
                Setup alerts to be notified in real-time whenever major trends happen.
            </div>
        </div>
        <div class="alerts-list-container">
            <div class="list-container-contents">
                <div class="custom-alerts">
                    <div class="option" [class.active]="alert.is_active" *ngFor="let alert of alerts">
                        <mat-icon *ngIf="!alert.is_active" (click)="activateAlert(alert)" matTooltip="Activate Alert">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="alert.is_active" (click)="deactivateAlert(alert)" matTooltip="Deactivate Alert">check_circle_outlined</mat-icon>
                        <div class="alert" [class.current]="currentAlert.uid === alert.uid" (click)="selectAlert(alert)">{{alert.name}}</div>
                    </div>
                </div>
            </div>
            <div class="list-container-actions">
                <div class="option" (click)="createAlert()">
                    <mat-icon>add_circle_outline</mat-icon>
                    <div class="alert" [class.current]="currentAlert && (currentAlert.uid === 'create')">Create Alert</div>
                </div>
            </div>
        </div>
    </div>
    <div class="alerts-settings">
        <div class="alerts-settings-header">
            <div class="alerts-settings-title">
                <div class="title-contents">
                    <!--<div class="title-stat">Score Name</div>-->
                    <div class="title-dyn" [class.notitle]="notitle">
                        <input matInput placeholder="Create alert's name *" required [(ngModel)]="currentAlert.name">
                    </div>
                </div>
                <div class="title-actions">
                    <mat-icon matTooltip="Close" matTooltipPosition="below" (click)="iClose()">close</mat-icon>
                </div>
            </div>
        </div>
        <div class="alerts-settings-container">
            <div class="settings-container-contents">

                <mat-form-field appearance="outline" class="field-type">
                    <mat-label>Schedule</mat-label>
                    <mat-select #selectorAlertSchedule [(ngModel)]="currentAlert.schedule" required>
                        <mat-option value="1d">Check alert's triggers daily</mat-option>
                        <mat-option value="1w">Check alert's triggers weekly</mat-option>
                        <mat-option value="30d">Check alert's triggers monthly</mat-option>
                        <mat-option value="once">Send alert only once and deactivate</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-type">
                    <mat-label>Channel</mat-label>
                    <mat-select #selectorAlertChannel [(ngModel)]="currentAlert.channel" required (selectionChange)="onChannelChange()">
                        <mat-option value="digest">Digest - Email</mat-option>
                        <mat-option value="email">Instant Alert - Email</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-type">
                    <mat-label>Alert Type</mat-label>
                    <mat-select #selectorAlertType [(ngModel)]="currentAlert.alert_type" (selectionChange)="optSelect2()" required>
                        <mat-option value="entity">Entity</mat-option>
                        <mat-option value="portfolio">Portfolio</mat-option>
                        <!--<mat-option value="search_query">Query</mat-option>
                        <mat-option value="search_query_group">Collection</mat-option>-->
                    </mat-select>
                </mat-form-field>

                <p>Choose the object to set-up an alert for</p>

                <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'portfolio'">
                    <mat-label>Name</mat-label>
                    <mat-select  #selectorAlertObject [(ngModel)]="currentAlert.object_id" (selectionChange)="optSelect3($event, 'portfolio')" required>
                        <mat-option *ngFor="let option of folio$|async" [value]="option.uid">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'search_query'">
                    <mat-label>Name</mat-label>
                    <input #inputAlertObject matInput type="text" [formControl]="queryFormControl" [matAutocomplete]="autoQuery" required>
                    <mat-autocomplete #autoQuery="matAutocomplete" [displayWith]="displayFn">
                        <mat-option *ngFor="let query of filteredQuery$ | async | orderBy: 'name'" [value]="query">
                            {{query.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'search_query_group'">
                    <mat-label>Name</mat-label>
                    <mat-select #selectorAlertObject [(ngModel)]="currentAlert.object_id" (selectionChange)="optSelect3($event, 'collection')" required>
                        <mat-option *ngFor="let option of collection$|async" [value]="option.uid">{{option.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" class="field-search" *ngIf="currentAlert.alert_type === 'entity'">
                    <mat-label>Search</mat-label>
                    <input #inputAlertObject matInput type="text" [matAutocomplete]="menuSearch" [formControl]="searchFormControl" required>
                    <!--<div class="iresult" *ngIf="!loading && (item$|async)?.entity_prefix_search.hits.length>1">
                      {{(item$|async)?.entity_prefix_search.hits.length}} results
                    </div>-->
                    <mat-autocomplete #menuSearch="matAutocomplete" (optionSelected)="optSelect($event.option.value)" [autoActiveFirstOption]="true" class="searchLetter search-panel" panelWidth="auto">
                        <div *ngIf="items$|async as search">
                            <div *ngFor="let items of search.hits | groupBy:'entity.type' | orderByType | pairs ">
                                <mat-optgroup>
                                    <div class="optgroup-search">
                                        <div class="indexo-left">{{items[0] | nicetxt}}</div>
                                        <div class="indexo-right">
                                            <div class="optgroup-column column-1">{{((items[0] === 'company') || (items[0] === 'organization')) ? 'Country' : ''}}</div>
                                            <div class="optgroup-column"># Articles</div>
                                        </div>
                                    </div>
                                    <mat-option *ngFor="let option of items[1]" [value]="option">
                                        <div class="search-indexo">
                                            <div class="indexo-left">
                                                <span>{{option.entity.name}}</span>
                                                <span class="match">{{(option.entity.matched_form) ? option.entity.matched_form : ''}}</span>
                                                <!--<span class="match" *ngIf="iMatch(option.entity)">{{option.entity.matched_form}}</span>-->
                                            </div>
                                            <div class="indexo-right">
                                                <!--<span>{{option.sentiment|percent}}</span>
                                                <div class="trendicon">
                                                  <span class="brak">(</span>
                                                  <span>{{option.sentiment_delta*100|round|trend}}</span>
                                                  <span>{{option.sentiment_delta*100|round|negative}}</span>
                                                  <span class="brak">)</span>
                                                </div>-->
                                                <div class="optgroup-column column-1">{{(option.entity && option.entity.country && option.entity.country.alpha_id) ? option.entity.country.alpha_id.toUpperCase() : (((items[0] === 'company') || (items[0] === 'organization')) ? '\u2014' : '')}}</div>
                                                <div class="optgroup-column">{{numberFormat(option.count)}}</div>
                                            </div>
                                        </div>
                                    </mat-option>
                                </mat-optgroup>
                            </div>
                        </div>
                    </mat-autocomplete>
                    <mat-spinner [diameter]="15" *ngIf="loading"></mat-spinner>
                </mat-form-field>

                <p *ngIf="currentAlert.alert_type !== 'portfolio'">Set Alert Triggers</p>
                <p *ngIf="currentAlert.alert_type === 'portfolio'">Set Alert Trigger</p>

                <div class="alerts-group">
                    <div class="alerts-group-cont">
                        <div class="alerts-group-main" *ngIf="currentAlert.alerts.length">
                            <div class="alerts-group-item" *ngFor="let subAlert of currentAlert.alerts; index as index; last as last;">
                                <div class="subalert-main">
                                    <mat-form-field class="field-subalert type">
                                        <mat-label>Choose Score</mat-label>
                                        <mat-select #selectorTriggerScore [(ngModel)]="subAlert.score_type" (selectionChange)="onScoreChange($event, index)" required>
                                            <mat-option *ngFor="let score of scoreTypes" [value]="score.value">{{score.label}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="field-subalert setting">
                                        <mat-label></mat-label>
                                        <mat-select #selectorTriggerThresholdSetting [(ngModel)]="subAlert.threshold_setting" required>
                                            <!--<mat-option value="ABOVE_EQUAL">&#8805;</mat-option>
                                            <mat-option value="BELOW_EQUAL">&#8804;</mat-option>-->
                                            <mat-option value="CROSS_ABOVE">&#8805;</mat-option>
                                            <mat-option value="CROSS_BELOW">&#8804;</mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                    <mat-form-field class="field-subalert value" *ngIf="(subAlert.score_type !== 'sentiment') && ['bbd', 'bbw', 'bbm', 'bbq'].includes(subAlert.score_type)">
                                        <mat-label>Value...</mat-label>
                                        <input #inputTriggerThreshold type="number"
                                               maxlength="2"
                                               min="-1"
                                               max="1"
                                               required
                                               matInput
                                               [(ngModel)]="subAlert.threshold"
                                               (change)= "subAlert.threshold < -1 ? subAlert.threshold = -1 : subAlert.threshold; subAlert.threshold > 1 ? subAlert.threshold = 1 : subAlert.threshold;"
                                               name="threshold">
                                        <mat-error>Please enter a value between -1 and 1</mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="field-subalert value" *ngIf="(subAlert.score_type !== 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(subAlert.score_type)">
                                        <mat-label>Value...</mat-label>
                                        <input #inputTriggerThreshold type="number"
                                               maxlength="2"
                                               min="0"
                                               max="10"
                                               required
                                               matInput
                                               [(ngModel)]="subAlert.threshold"
                                               (change)= "subAlert.threshold < 0 ? subAlert.threshold = 0 : subAlert.threshold; subAlert.threshold > 10 ? subAlert.threshold = 0 : subAlert.threshold;"
                                               name="threshold">
                                        <mat-error>Please enter a value between 0 and 10</mat-error>
                                    </mat-form-field>

                                    <mat-form-field class="field-subalert value" *ngIf="(subAlert.score_type === 'sentiment')">
                                        <mat-label>Value...</mat-label>
                                        <input #inputTriggerThreshold type="number"
                                               maxlength="3"
                                               min="0"
                                               max="100"
                                               required
                                               matInput
                                               [(ngModel)]="subAlert.threshold"
                                               (change)= "subAlert.threshold < 0 ? subAlert.threshold = 0 : subAlert.threshold; subAlert.threshold > 100 ? subAlert.threshold = 0 : subAlert.threshold;"
                                               name="threshold">
                                        <mat-error>Please enter a value between 0 and 100</mat-error>
                                    </mat-form-field>

                                    <div class="btndel" (click)="delSubAlert(index)" *ngIf="(currentAlert.alerts.length > 1)">
                                        <mat-icon>remove_circle</mat-icon>
                                    </div>

                                </div>

                                <div class="subalert-operator" *ngIf="!last">{{currentAlert.operator}}</div>

                            </div>
                        </div>

                        <div class="btnflex group" *ngIf="(currentAlert.alert_type !== 'portfolio') && currentAlert.alerts.length">
                            <button mat-button (click)="addSubAlert()">
                                <mat-icon>library_add</mat-icon>
                                <span>TRIGGER</span>
                            </button>
                        </div>

                    </div>
                    <div class="alerts-group-operator" *ngIf="(currentAlert.alerts.length > 1)">
                        <mat-form-field appearance="outline">
                            <mat-select [(ngModel)]="currentAlert.operator">
                                <mat-option value="OR">OR</mat-option>
                                <mat-option value="AND" [disabled]="currentAlert.channel === 'digest'">AND</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!--<div class="settings-container-actions" *ngIf="currentScoreType !== 'yukka'"></div>-->
        </div>
        <div class="alerts-settings-footer">
            <button class="save" mat-button (click)="saveAlert(currentAlert.is_active)">
                <mat-icon>save</mat-icon>
                <span>SAVE</span>
            </button>
            <button class="delete" mat-button *ngIf="currentAlert.uid && (currentAlert.uid !== 'create')" (click)="deleteAlert()">
                <mat-icon>delete</mat-icon>
                <span>DELETE</span>
            </button>
        </div>
    </div>
</div>
