import { Component, Pipe, PipeTransform } from '@angular/core';
// import { DomSanitizer } from '@angular/platform-browser';

/**
 * This remove all markup html from the text
 */

@Pipe({
    name: 'htmlTrik'
})
export class SafeHtmlPipe implements PipeTransform {
    // constructor(private sanitized: DomSanitizer) {}
    constructor() {}
    transform(value) {
        return value.replace(/</g, ' ').replace(/>/g, ' ');
    }
}
