<div class="viewnews">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div mat-dialog-title class="newsmenu" *ngIf="!apicall || routing.isReader()">
    <div>
      <button *ngIf="!routing.isReader()" mat-raised-button class="navigo" (click)="iNavigo('previus')" [disabled]="iDisable('previus')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <button *ngIf="!routing.isReader()" mat-raised-button class="navigo" (click)="iNavigo('next')" [disabled]="iDisable('next')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
    <div>
      <button mat-icon-button matTooltip="Audit" matTooltipPosition="below" *ngIf="auth.featureFlags.flaggingEnabled && routing.isNewsaudit() && (params.newstype !== 'Audited') && (params.newstype !== 'Dismissed')" (click)="toggleAuditView()">
        <mat-icon [class.starred]="auditView">flag</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Dismiss" matTooltipPosition="below" *ngIf="auth.featureFlags.dismissingEnabled && routing.isNewsaudit() && (params.newstype !== 'Audited') && (params.newstype !== 'Dismissed')" (click)="toggleDismissView()">
        <mat-icon [class.starred]="dismissView">remove_circle</mat-icon>
      </button>
      <button *ngIf="!routing.isReader()" mat-icon-button matTooltip="{{!iBookmark(newsinfo.id) ? 'Bookmark' : 'Unbookmark'}}" matTooltipPosition="below" (click)="addStar(newsinfo.id)">
        <mat-icon [class.starred]="iBookmark(newsinfo.id)">bookmark</mat-icon>
      </button>
      <button *ngIf="auth.featureFlags.annotationsEnabled && !routing.isReader() && !params.query" mat-icon-button matTooltip="Annotation" matTooltipPosition="below" (click)="addNote()">
        <mat-icon>add_comment</mat-icon>
      </button>
      <button *ngIf="!newsinfo.reduce_visibility" mat-icon-button matTooltip="Report" matTooltipPosition="below" (click)="goReport()">
        <mat-icon>report</mat-icon>
      </button>
      <button mat-icon-button (click)="openHelp()" matTooltip="Help" matTooltipPosition="below" *ngIf="!newsinfo.reduce_visibility">
        <mat-icon>help</mat-icon>
      </button>
      <button *ngIf="!routing.isReader()" mat-icon-button matTooltip="Close" matTooltipPosition="below" (click)="iClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content class="infomain" *ngIf="!loading && newsinfo && ( !routing.isNewsaudit() || ( routing.isNewsaudit() && ! ( auditView || dismissView ) ) )">

    <div class="main-blok" *ngIf="!newsinfo.reduce_visibility">
      <div class="info">
        <div class="main-tito hide">Article</div>
        <div class="cont title" [innerHTML]="newsinfo.title | htmlTrik | highlight:'title':mytag"></div>
      </div>
      <div class="subtit">
        <div class="cont">
          <mat-icon>public</mat-icon>
          <span>
            <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer"
               *ngIf="newsinfo.read_more_link">{{newsinfo.provider}}</a>
            <div *ngIf="!newsinfo.read_more_link">{{newsinfo.provider}}</div>
          </span>
        </div>
        <div class="cont">
          <mat-icon>access_time</mat-icon>
          <span>{{newsinfo.publish_time|date:'dd.MM.yyyy HH:mm':'GMT'}} GMT,
            {{newsinfo.publish_time|amTimeAgo}}</span>
        </div>
        <div class="cont">
          <!--<mat-icon>{{newsinfo.news_type|newstype}}</mat-icon>-->
          <mat-icon>dvr</mat-icon>
          <span>{{newsinfo.document_category.name|nicetxt}}</span>
        </div>
      </div>
    </div>

    <div class="main-blok main-match" *ngIf="matches.length && (!apicall || routing.isReader())">

      <div class="info target">
        <div class="main-tito hide">Targets</div>
        <div class="matches">
          <div class="onmatch">
            <div *ngFor="let typeStructure of matches | matchgroup:inmatches | pairs" class="inmatch">
              <div class="btnmatch">
                <div class="label" *ngIf="typeStructure[1].length==1">
                  <span>{{typeStructure[0] | nicetxt:false}} : </span>
                </div>
                <div class="label" *ngIf="typeStructure[1].length>1" [matMenuTriggerFor]="matchpanel">
                  <span>{{typeStructure[1].length}} </span>
                  <span>{{typeStructure[0] | nicetxt:true}} : </span>
                </div>
                <div class="tito" (click)="clickTarget(typeStructure[1][0])" [class.btnact]="typeStructure[1][0].active"
                     [style.color]="typeStructure[1][0].sentiment | color">
                  <span>{{(typeStructure[1][0].entity.name) ? typeStructure[1][0].entity.name : typeStructure[1][0].entity.alpha_id}}</span>
                </div>
                <mat-menu #matchpanel="matMenu" class="matchpanel">
                  <div *ngFor="let match of typeStructure[1]" class="matchpanel-btn"
                       [style.color]="match.sentiment | color" (click)="clickTarget(match)">
                    <span>{{(match.entity.name) ? match.entity.name : match.entity.alpha_id}}</span>
                  </div>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info relate" *ngIf="isRelated(matches|matchgraph:inrelated|pairs)">
        <div class="main-tito">
          <span>RELATED</span>
          <div class="line"></div>
        </div>
        <div class="matches">
          <div class="onmatch">
            <div *ngFor="let typeStructure of matches | matchgraph:inrelated | pairs" class="inmatch">
              <div class="btnmatch" *ngIf="typeStructure[1].visible.length">
                <div class="label" [matMenuTriggerFor]="matchpanel" *ngIf="typeStructure[1].visible.length>1">
                  <span>{{typeStructure[1].visible.length}}</span>
                  <span> {{typeStructure[0] | nicetxt:true}} : </span>
                </div>
                <div class="label" *ngIf="typeStructure[1].visible.length==1">
                  <span> {{typeStructure[0] | nicetxt:false}} : </span>
                </div>
                <div class="tito" [class.opacity]="!typeStructure[1].toShowIsVisible"
                     (click)="clickRelate(typeStructure[1].toShow)"
                     [class.btnact]="inrelated.type==typeStructure[1].toShow.entity.type && inrelated.alpha_id==typeStructure[1].toShow.entity.alpha_id"
                     [style.color]="typeStructure[1].toShow?typeStructure[1].toShow.sentiment:null|color">
                  <span>{{(typeStructure[1].toShow.entity.name) ? typeStructure[1].toShow.entity.name : typeStructure[1].toShow.entity.alpha_id}}</span>
                </div>
              </div>
              <mat-menu #matchpanel="matMenu" class="matchpanel">
                <div *ngFor="let match of typeStructure[1].visible" class="matchpanel-btn"
                     [style.color]="match.sentiment | color" (click)="clickRelate(match)">
                  <span>{{(match.entity.name) ? match.entity.name : match.entity.alpha_id}}</span>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </div>

      <div class="info relate" *ngIf="newsinfo.events.length>0">
        <div class="main-tito">
          <span>EVENTS</span>
          <div class="line"></div>
        </div>
        <div class="matches">
          <div class="onmatch">
            <div class="inmatch" *ngFor="let event of newsinfo.events;index as index">
              <div class="btnmatch">
                <div *ngIf="event.span" class="tito" [class.btnact]="(eventindex==index) && (eventindex==oldEventIndex)" (click)="iEvent(index)">{{event.name}}</div>
                <div *ngIf="!event.span" class="tito inactive" matTooltip="Event not present in the article's excerpt">{{event.name}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="info relate" *ngIf="params.highlight">
        <div class="main-tito">
          <span>HIGHLIGHTS</span>
          <div class="line"></div>
        </div>
        <div class="matches">
          <div class="onmatch">
            <div class="inmatch">
              <div class="btnmatch">
                <div class="tito" [class.btnact]="showHighlights" (click)="iHighlight()">Show query hit spans</div>
              </div>
              <div class="btnmatch">
                <div class="tito" [class.btnact]="true" (click)="getHighlightLink()">Get highlights link</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="mytag?.participants">
        <ng-container *ngIf="mytag.participants.length>0">
          <div class="info relate">
            <div class="main-tito">
              <span>PARTICIPANT</span>
              <div class="line"></div>
            </div>
            <div class="matches">
              <div class="onmatch">
                <div *ngFor="let item of mytag.participants" class="inmatch">
                  <div class="btnmatch">
                    <div class="tito">{{item.name}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

    </div>

    <div class="main-blok" *ngIf="!newsinfo.reduce_visibility">
      <div class="main-tito hide">Content</div>
      <div class="info" *ngIf="newsinfo.content">
        <div class="cont content" [innerHTML]="newsinfo.content|htmlTrik|highlight:'content':mytag|paragraph"></div>
        <div class="copyright" *ngIf="newsinfo.provider=='businesswire.com'">(c) {{newsinfo.publish_time|date:'yyyy'}}
          Business Wire, Inc. All Rights
          Reserved.</div>
        <button mat-button class="readmore" *ngIf="newsinfo.read_more_link">
          <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer">Read more...</a>
          <!--<mat-icon>link</mat-icon>-->
          <mat-icon matTooltip="Copy link to the original article" matTooltipPosition="below" (click)="getNewsOriginalLink(newsinfo.read_more_link)">link</mat-icon>
        </button>
      </div>
    </div>

    <div class="main-blok" *ngIf="newsinfo.reduce_visibility">
      <div class="main-tito">Article</div>
      <blockquote>
        <p><strong>Due to copyright and/or license restrictions, we are unable to show the original text.</strong></p>
        <p>&mdash;<i>We apologize for any inconvenience.</i></p>
      </blockquote>
      <button mat-button class="readmore" *ngIf="newsinfo.read_more_link">
        <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer">Read more...</a>
        <!--<mat-icon>link</mat-icon>-->
        <mat-icon matTooltip="Copy link to the original article" matTooltipPosition="below" (click)="getNewsOriginalLink(newsinfo.read_more_link)">link</mat-icon>
      </button>
    </div>

    <div *ngIf="newsinfo && (routing.isDev() || routing.isReport())">
      <br>
      <div class="main-blok">
        <div class="info">
          <div class="main-tito">Matches</div>
          <div class="blok">
            <div class="item match" *ngFor="let match of matches; let i=index" (click)="mytag=match; btntag=undefined"
                 [class.active]="mytag==match" [style.color]="match.sentiment | color">{{(match.entity.name) ? match.entity.name : match.entity.alpha_id}}</div>
          </div>
        </div>
      </div>
      <div class="main-blok tags" *ngIf="newsinfo.events.length>0">
        <div class="info">
          <div class="main-tito">Events</div>
          <div class="blok">
            <div class="item tag" *ngFor="let event of newsinfo.events;index as index"
                 [class.btnact]="(eventindex==index) && (eventindex==oldEventIndex)" (click)="iEvent(index)">{{event.name}}</div>
          </div>
        </div>
      </div>
      <div class="main-blok tags">
        <div class="info">
          <div class="main-tito">Tags</div>
          <div class="blok">
            <div class="item tag" *ngFor="let item of newsinfo.tags; let i=index"
                 (click)="mytag=item; btntag=i; tag=null" [class.active]="btntag==i || tag==item.tag">{{item.word}}</div>
          </div>
        </div>
      </div>
      <div class="main-blok" *ngIf="user.roles.includes('ADMIN')">
        <button mat-button class="readmore" (click)="apiref=!apiref">
          <span>API Reference</span>
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <pre *ngIf="apiref">{{newsinfo|json}}</pre>
      </div>
    </div>

  </div>

  <div mat-dialog-content class="infomain" *ngIf="!loading && newsinfo && ( routing.isNewsaudit() && ( auditView || dismissView ) )">

    <div class="main-blok">
      <div class="info">
        <div class="cont title">{{(auditView)?"Start Audit":"Dismiss News"}}</div>
      </div>
    </div>

    <div class="main-blok" *ngIf="!newsinfo.reduce_visibility">
      <div class="info">
        <div class="main-tito">Article</div>
        <div class="blok">
          {{newsinfo.title}}
        </div>
      </div>
      <div class="subtit">
        <div class="cont">
          <mat-icon>public</mat-icon>
          <span>
            <a href="{{newsinfo.read_more_link}}" target="_blank" rel="noreferrer"
               *ngIf="newsinfo.read_more_link">{{newsinfo.provider}}</a>
            <div *ngIf="!newsinfo.read_more_link">{{newsinfo.provider}}</div>
          </span>
        </div>
        <div class="cont">
          <mat-icon>access_time</mat-icon>
          <span>{{newsinfo.publish_time|date:'dd.MM.yyyy HH:mm':'GMT'}} GMT,
            {{newsinfo.publish_time|amTimeAgo}}</span>
        </div>
        <div class="cont">
          <!--<mat-icon>{{newsinfo.news_type|newstype}}</mat-icon>-->
          <mat-icon>dvr</mat-icon>
          <span>{{newsinfo.document_category.name|nicetxt}}</span>
        </div>
      </div>
    </div>

    <div class="main-blok">
      <div class="info">
        <div class="main-tito">Choose entities for news {{(auditView)?"audit":"dismiss"}}</div>
        <div class="blok">
          <div class="item tag" *ngFor="let entity of auditEntities; let i=index;" [class.active]="entity.selected" (click)="chooseEntity(i)">
            {{(entity.name) ? entity.name : entity.alpha_id}}
          </div>
        </div>
      </div>
      <mat-form-field class="message" appearance="outline">
        <mat-label>Comment</mat-label>
        <textarea matInput [formControl]="newsAuditComment" cdkTextareaAutosize></textarea>
      </mat-form-field>
      <mat-form-field class="message" appearance="outline" *ngIf="(auditView)">
        <mat-label>Notify email</mat-label>
        <input matInput
               autocomplete="email"
               type="email"
               [formControl]="newsAuditNotify">
      </mat-form-field>
      <div mat-dialog-actions>
        <button mat-raised-button color="secondary" mat-dialog-close>CANCEL</button>
        <button mat-raised-button color="primary" mat-dialog-close (click)="newsSubmit()" *ngIf="validateAuditEntities">SUBMIT</button>
      </div>
    </div>

  </div>

</div>

