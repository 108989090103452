<div class="main-sentiment">
  <div class="sentinav asidenav trend" [class.hidden]="false" [class.inblur]="params.tutorial"
    [class.noblur]="params.tutorial=='indicator1'" *ngIf="!routing.isWidget()">
    <div class="sentinav-top" [class.hidden]="false">
      <div class="sentimap" *ngIf="mapindex">
        <mat-selection-list disableRipple="true" [formControl]="controlSignals" (selectionChange)="goUrl($event)">
          <h3 matSubheader>Signals</h3>
          <mat-list-option value="early_warning">
            <mat-icon matListIcon svgIcon="chart-tree"></mat-icon>
            <!--<p>Early Warning (SXXP)</p>-->
            <p>{{mapindex[0].name}}</p>
          </mat-list-option>
          <!--<mat-list-option value="early_warning_fx_gbp_eur">
            <mat-icon matListIcon svgIcon="cash-multiple"></mat-icon>
            <p>Early Warning FX (GBP/EUR)</p>
          </mat-list-option>-->
          <mat-list-option value="early_warning_fx_eur_usd_new">
            <mat-icon matListIcon svgIcon="cash-multiple"></mat-icon>
            <!--<p>Early Warning FX (EUR/USD)</p>-->
            <p>{{mapindex[1].name}}</p>
          </mat-list-option>
          <mat-list-option value="early_warning_fx_gbp_eur_new">
            <mat-icon matListIcon svgIcon="cash-multiple"></mat-icon>
            <!--<p>Early Warning FX (GBP/EUR)</p>-->
            <p>{{mapindex[2].name}}</p>
          </mat-list-option>
        </mat-selection-list>
        <!--<mat-form-field>
          <mat-select placeholder="Overlay" (selectionChange)="inType($event.value)" [(value)]="strategy">
            <mat-option *ngFor="let item of arraytype" [value]="item.value">{{item.name}}</mat-option>
          </mat-select>
        </mat-form-field>-->
      </div>
      <div class="flexone" *ngIf="datamain">
        <div class="sentinfo">
          <div class="cont">
            <div class="tito green">INVESTLAB:</div>
            <div class="subo green">{{datamain.sentipercent}}%</div>
          </div>
          <div class="cont">
            <div class="tito silver">INDEX:</div>
            <div class="subo silver">{{datamain.indexpercent}}%</div>
          </div>
        </div>
        <div class="tabledata" *ngIf="false">
          <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Key Figures </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
            <ng-container matColumnDef="weight">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="trendlab"> TrendLab </th>
              <td mat-cell *matCellDef="let element" class="trendlab"> {{element.weight}} </td>
            </ng-container>
            <ng-container matColumnDef="symbol">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Index </th>
              <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <!--<div class="alertme">
          <mat-slide-toggle [(ngModel)]="alert" (change)="setIndicator()">
            <span>Alert Me of Changes</span>
          </mat-slide-toggle>
        </div>-->
      </div>
    </div>
    <ng-container *ngIf="params.id === 'early_warning'">
      <div class="sentinav-bot" [style.height.px]="charth" *ngIf="datamain">
        <div class="txtcont">
          <div class="current">Current Investment Ratio</div>
          <div class="percent">{{datamain.current_investment}}%</div>
          <div class="subtito">Since: {{datamain.invested_since| date:'dd-MM-yyyy'}}</div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="params.id !== 'early_warning'">
      <div class="sentinav-bot" [style.height.px]="charth" *ngIf="datamain">
        <div class="txtcont">
          <div class="current">Current Signal</div>
          <div class="percent2">{{datamain.current_signal}}</div>
          <div class="subtito"></div>
        </div>
      </div>
      <div class="sentinav-bot" [style.height.px]="charth" [style.margin-top.px]="chartdist" *ngIf="datamain">
        <div class="txtcont">
          <div class="current">Current Confidence</div>
          <div class="percent2">{{datamain.current_confidence}}</div>
          <div class="subtito"></div>
        </div>
      </div>
    </ng-container>
    <div class="sentinav-fot" *ngIf="datamain">
      <div class="txtinfo">Signals updated daily</div>
      <!--<div class="txtinfo">Signals 2-weekly update</div>
      <div class="txtinfo">Warning daily update</div>
      <div class="txtinfo">{{date1 | date:'dd-MM-yyyy'}} / {{date2 | date:'dd-MM-yyyy'}}</div>-->
    </div>
  </div>
  <div class="mainchart" [class.inblur]="params.tutorial" [class.noblur]="params.tutorial=='indicator2'">
    <div class="loading" *ngIf="loading">
      <mat-spinner *ngIf="!error" [diameter]="50"></mat-spinner>
      <div class="error" *ngIf="error">NO DATA AVAILABLE</div>
    </div>
    <div class="chart-stock">
      <div class="chart" [chart]="stockChart"></div>
    </div>
  </div>
</div>
