import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
// import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../service/auth.service';

/**
* This component display all the annotation created in the cockpit.
*/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-annotation',
  templateUrl: './annotation.component.html',
  styleUrls: ['./annotation.component.scss']
})
export class AdminAnnotationComponent implements OnInit {

  /**
  * the list of columns to display
  */
  displayedColumns: string[] = ['action', 'time', 'public', 'entity', 'title', 'comment'];

  /**
  * this is the angular table component
  */
  dataSource = new MatTableDataSource();
  // @ViewChild(MatSort) sort: MatSort

  /**
  * constructor
  */
  constructor(
    /**
    * auth service
    */
    public auth: AuthService,
  ) { }

  /**
  * ngOnInit
  */
  ngOnInit() {
    this.inNotes();
  }

  /**
  * start the request
  */
  inNotes() {
    this.auth.getNoteAll().subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      // this.dataSource.sort = this.sort
    });
  }

  /**
  * delete the annotation
  */
  inDelete(element) {
    if (window.confirm('Delete this annotation?')) {
      this.auth.delNote(element).subscribe(risp => {
        this.inNotes();
      });
    }
  }

}
