import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-newsletter-send',
  templateUrl: './newsletter-send.component.html',
  styleUrls: ['./newsletter-send.component.scss']
})
export class NewsletterSendComponent {

  summary: any;
  sendToAll: any;
  newsletter: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    public auth: AuthService,
    private router: Router,
  ) {

    this.sendToAll = true;
    this.newsletter = this.data.newsletter;

  }

  onSubmit() {

    const newsletter = JSON.parse(JSON.stringify(this.newsletter));

    this.auth.editNewsletter(newsletter).subscribe(result => {
      if (result && result.uid) {
        const data = {
          uid: result.uid,
          send_to_all: this.sendToAll,
        };
        if (this.summary) {
          data['summary'] = this.summary;
        }
        this.auth.generateNewsletter(data).subscribe(result2 => {
          this.snackBar.open('Newsletter scheduled for sending, it may take a while to deliver.', 'OK', { duration: 5000 });
          this.dialog.closeAll();
        });
      }
      this.router.navigate(['/cockpit/newsletter'], {
        queryParams: { id: result.uid, rdn: Math.random() },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });
      // if (newsletter.contents.includes('curated_news') && !newsletter.contents.includes('top_news') && !newsletter.contents.includes('all_news')) {
      //   this.router.navigate(['/cockpit/newsletter/articles'], {
      //     queryParams: { id: result.uid, rdn: Math.random() },
      //     queryParamsHandling: 'merge',
      //     replaceUrl: true
      //   });
      // } else {
      //   this.router.navigate(['/cockpit/newsletter'], {
      //     queryParams: { id: result.uid, rdn: Math.random() },
      //     queryParamsHandling: 'merge',
      //     replaceUrl: true
      //   });
      // }
    }, error => {
      this.dialog.closeAll();
      alert('Error: unable to save newsletter. ' + error);
    });

  }

}
