import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
* This dialog display all the report's data inside a textarea.
*/

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-report-export',
  templateUrl: './report-export.component.html',
  styleUrls: ['./report-export.component.scss']
})

export class ReportExportComponent {

  export: any = [];

  /**
  * constructor
  */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

}
