import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

/**
* this guard is used in the search route, it uses localStorage to retrieve the data
*/

@Injectable({
  providedIn: 'root'
})
export class SearchGuard implements CanActivate {
  constructor(
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const stored = JSON.parse(localStorage.getItem('search'));
    if (stored) {
      this.auth.query = stored;
      if (!state.root.queryParams.id) {
        this.router.navigate([state.url.split('?')[0]], {
          queryParams: {
            id: Math.random()
          },
          replaceUrl: true,
        });
      } else {
        return true;
      }
    } else {
      this.router.navigate(['/'], {
        replaceUrl: true
      });
    }
  }
}
