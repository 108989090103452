import {Component, OnInit, Input, ViewEncapsulation, OnChanges} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { NewsletterService } from 'src/app/service/newsletter.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-newsletter-articles',
  templateUrl: './newsletter-articles.component.html',
  styleUrls: ['./newsletter-articles.component.scss']
})
export class NewsletterArticlesComponent implements OnChanges {

  newsletter: any;
  newsletterParamsReady: any;
  selectedDisplayable: any;
  params: any;
  previousValue: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
    public newsletterService: NewsletterService
  ) {

    this.selectedDisplayable = 0;

    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.routing.reFresh(params, this.previousValue, ['updateArticles'])) {
        if (this.newsletterService.newsletter) {
          this.onSelectionChange();
        }
      }
      if (this.routing.reFresh(params, this.previousValue, ['id'])) {
        if (this.newsletterService.newsletter) {
          this.selectedDisplayable = 0;
        }
      }
      this.previousValue = params;
    });

  }

  ngOnChanges() {
    this.newsletter = this.newsletterService.newsletter;
    this.newsletterParamsReady = this.newsletterService.newsletterParamsReady;
    // if (this.newsletter) {
    //   this.selectedDisplayable = 0;
    // }
  }

  onSelectionChange() {

    let type;
    let id;
    let payload;
    if (this.newsletterService.newsletter['dispObjects'] && this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].type === 'entity') {

      type = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload.split(':')[0];
      id = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload.split(':')[1];
      payload = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload;

      Object.assign(this.auth.newsletterParams, {
        type: type,
        id: id,
        payload: payload
      });

      this.newsletterService.newsletterParamsReady = true;

      this.router.navigate([], {
        queryParams: { updateNewsfeed: Math.random() },
        queryParamsHandling: 'merge',
        replaceUrl: true
      });

    } else if (this.newsletterService.newsletter['dispObjects'] && this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].type === 'portfolio') {

      this.auth.portFolio(this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload).subscribe(result2 => {

        type = 'portfolio';
        id = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload;
        payload = result2.content;

        Object.assign(this.auth.newsletterParams, {
          type: type,
          id: id,
          payload: payload
        });

        this.newsletterService.newsletterParamsReady = true;

        this.router.navigate([], {
          queryParams: { updateNewsfeed: Math.random() },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });

      });

    } else if (this.newsletterService.newsletter['dispObjects'] && this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].type === 'search_query_group') {

      this.auth.getQueryGroup(this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload).subscribe(result2 => {

        type = 'search_query';
        id = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload;

        const groups = [];

        result2.forEach(element => {
          groups.push(element.query);
        });

        payload = {
          operator: 'or',
          filters: [],
          groups: groups
        };

        Object.assign(this.auth.newsletterParams, {
          type: type,
          id: id,
          payload: payload
        });

        this.newsletterService.newsletterParamsReady = true;

        this.router.navigate([], {
          queryParams: { updateNewsfeed: Math.random() },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });

      });

    } else if (this.newsletterService.newsletter['dispObjects'] && this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].type === 'search_query') {

      this.auth.getQuery().subscribe(result2 => {

        type = 'search_query';
        id = this.newsletterService.newsletter['dispObjects'][this.selectedDisplayable].payload;
        payload = result2.filter(item => {
          return item.uid === id;
        })[0].query;

        Object.assign(this.auth.newsletterParams, {
          type: type,
          id: id,
          payload: payload
        });

        this.newsletterService.newsletterParamsReady = true;

        this.router.navigate([], {
          queryParams: { updateNewsfeed: Math.random() },
          queryParamsHandling: 'merge',
          replaceUrl: true
        });

      });

    }

  }

}
