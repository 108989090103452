<div *ngIf="auth.featureFlags.showMenuLHS" class="menu-aside" [class.asidetxt]="!asidetxt" [class.inblur]="params.tutorial"
  [class.noblur]="params.tutorial=='menuaside'">
  <div class="contop">
    <!--<div class="home" *ngIf="auth.featureFlags.showHomepage && (subscription=='ALL' || subscription==='NEWS') && routing.isDev()">
      <div class="tito">Home</div>
      <div *ngIf="auth.featureFlags.showHomepage && routing.isDev()" class="mainmenu" matTooltip="Homepage" matTooltipPosition="right" routerLink="home" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,riskevent:null,tag:null,type:null,id:null,portfolioId:null,sort:null}" queryParamsHandling="merge">
        <mat-icon>home</mat-icon>
        <span class="txt">Homepage</span>
      </div>
      <div class="space"></div>
    </div>-->
    <div class="news" *ngIf="auth.featureFlags.showNewsLab && (subscription=='ALL' || subscription==='NEWS')">
      <div class="tito">News</div>
      <div *ngIf="auth.featureFlags.showPortfolio" class="mainmenu" matTooltip="Portfolio" matTooltipPosition="right" routerLink="news/portfolio"
        routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
        <mat-icon>collections_bookmark</mat-icon>
        <span class="txt">Portfolio</span>
      </div>
      <div *ngIf="auth.featureFlags.showCollection" class="mainmenu" matTooltip="Collection" matTooltipPosition="right" routerLink="news/query"
        routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
        <mat-icon>view_module</mat-icon>
        <span class="txt">Collection</span>
      </div>
      <div *ngIf="auth.featureFlags.showMarket && routing.isDev()" class="mainmenu" matTooltip="Market" matTooltipPosition="right" routerLink="news/market"
           routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
        <mat-icon>public</mat-icon>
        <span class="txt">Market</span>
      </div>
    </div>
    <div class="trend" *ngIf="auth.featureFlags.showInvestLab && (subscription=='ALL'||subscription=='TREND')">
      <div class="space" *ngIf="subscription=='ALL'"></div>
      <div class="tito">Invest</div>
      <!--<div *ngIf="auth.featureFlags.showSignals" class="mainmenu" matTooltip="Signals" matTooltipPosition="right" routerLink="trend/secsignal"
        routerLinkActive="active">
        <mat-icon>timeline</mat-icon>
        <span class="txt">Signals</span>
      </div>-->
      <div *ngIf="auth.featureFlags.showSignals" class="mainmenu" matTooltip="Signals" matTooltipPosition="right" routerLink="invest/signal" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null,newstype:null}" queryParamsHandling="merge">
        <mat-icon svgIcon="chart-areaspline"></mat-icon>
        <span class="txt">Signals</span>
      </div>
      <div *ngIf="auth.featureFlags.showTrending" class="mainmenu" matTooltip="Trending" matTooltipPosition="right" routerLink="invest/trending"
           routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null,newstype:null}" queryParamsHandling="merge">
        <mat-icon>whatshot</mat-icon>
        <span class="txt">Trending</span>
      </div>
      <!-- <div class="mainmenu" *ngIf="routing.isDev()" matTooltip="Multichart" matTooltipPosition="right"
        routerLink="trend/multichart" routerLinkActive="active">
        <mat-icon>multiline_chart</mat-icon>
        <span class="txt">Multichart</span>
      </div> -->
      <div class="space"></div>
    </div>
  </div>
  <div class="conbot" *ngIf="auth.featureFlags.showUserLab">
    <div class="space"></div>
    <div class="tito">User</div>
    <!-- <div class="mainmenu" *ngIf="routing.isDev()" matTooltip="Release" matTooltipPosition="right" [routerLink]=""
      [queryParams]="{sidenav:params.sidenav=='whatsnew'?null:'whatsnew'}" queryParamsHandling="merge">
      <mat-icon matBadge="3" matBadgeColor="warn">add_alert</mat-icon>
      <span class="txt">Release</span>
    </div> -->
    <div *ngIf="auth.featureFlags.showMenuAccount" class="mainmenu" matTooltip="Account" matTooltipPosition="right" [routerLink]="[]"
      [queryParams]="{sidenav:params.sidenav=='account'?null:'account', reflow:params.reflow=='true'?null:'true'}" queryParamsHandling="merge">
      <mat-icon>person</mat-icon>
      <span class="txt">Account</span>
    </div>
    <div *ngIf="auth.featureFlags.showSettings" class="mainmenu" matTooltip="Settings" matTooltipPosition="right" (click)="openSettings()">
      <mat-icon>settings</mat-icon>
      <span class="txt">Settings</span>
    </div>
    <div *ngIf="auth.featureFlags.showFlagsSetup" class="mainmenu" matTooltip="Flags" matTooltipPosition="right" (click)="openFlags()">
      <mat-icon>flag</mat-icon>
      <span class="txt">Flags</span>
    </div>
    <div *ngIf="auth.featureFlags.showNewsletter" class="mainmenu" matTooltip="Newsletter" matTooltipPosition="right" routerLink="newsletter" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
      <mat-icon>email</mat-icon>
      <span class="txt">Newsletter</span>
    </div>
    <div *ngIf="auth.featureFlags.showAlertsSetup" class="mainmenu" matTooltip="Alerts" matTooltipPosition="right" (click)="openAlerts()">
      <mat-icon>notifications</mat-icon>
      <span class="txt">Alerts</span>
    </div>
    <!--<div *ngIf="auth.featureFlags.showAlerts" class="mainmenu" matTooltip="Alerts" matTooltipPosition="right" routerLink="alerts" routerLinkActive="active" [queryParams]="{id:null,eventid:null,eventype:null,scoreevent:null,flagevent:null,tag:null}" queryParamsHandling="merge">
      <mat-icon [matBadge]="notificationsCount" [matBadgeHidden]="notificationsCount === 0" matBadgePosition="before above">notifications</mat-icon>
      <span class="txt">Alerts</span>
    </div>-->
    <div *ngIf="routing.isDev()" class="mainmenu" matTooltip="Tutorial" matTooltipPosition="right" (click)="inInfo()">
      <mat-icon>help</mat-icon>
      <span class="txt">Tutorial</span>
    </div>
    <div *ngIf="auth.featureFlags.showExpand" class="btnmenu"  [routerLink]="[]"
         [queryParams]="{reflow:params.reflow=='true'?null:'true'}" queryParamsHandling="merge" (click)="myAsidetxt()">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </div>
  </div>
</div>
