import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';
import { RoutingService } from '../service/routing.service';
import * as moment from 'moment';

/**
 * get custom default filter
 */
@Injectable()
export class FiltersGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    public config: ConfigService,
    private yukkApi: YukkApi,
    public routing: RoutingService,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    if (!this.auth.defaultFilter) {
      return this.auth.getFilter()
        .pipe(
          map(result => {
            result.forEach(item => {
              if (item.name === '_default_') {
                this.auth.defaultFilter = item;
                const dateFrom = this.yukkApi.myFromTo(item.time).date1;
                const dateTo = this.yukkApi.myFromTo(item.time).date2;
                const startDay = this.config.appConfig.setup.startDate;
                const maxDays = this.auth.internalCockpitSettings.maxTimeframe;
                const days = moment(dateTo).diff(moment(dateFrom), 'days');
                if ((dateFrom < startDay) || (dateTo < startDay)) {
                  alert('Please select another default filter, timeframe must be above ' + startDay + '.');
                }
                if ((!this.routing.isDev()) && (days > maxDays)) {
                  alert('Please select another default filter, the date range cannot exceed ' + maxDays + ' days.');
                }
                this.router.navigate([state.url.split('?')[0]], {
                  queryParams: {
                    id: state.root.queryParams.id,
                    groupId: state.root.queryParams.groupId,
                    portfolioId: state.root.queryParams.portfolioId,
                    type: state.root.queryParams.type,
                    news: state.root.queryParams.news,
                    highlight: state.root.queryParams.highlight,
                    time: state.root.queryParams.time ? state.root.queryParams.time : item.time,
                    filter: state.root.queryParams.filter ? state.root.queryParams.filter : item.filter,
                    lang: state.root.queryParams.lang ? state.root.queryParams.lang : item.lang,
                    feed: state.root.queryParams.feed ? state.root.queryParams.feed : (item.feed ? item.feed : 'non_professional,online,print,professional'),
                    ranks: state.root.queryParams.ranks ? state.root.queryParams.ranks : item.ranks,
                    // categories: state.root.queryParams.categories ? state.root.queryParams.categories : (item.categories ? item.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea'),
                    categories: state.root.queryParams.categories ? state.root.queryParams.categories : (item.categories ? item.categories : ''),
                    continents: state.root.queryParams.continents ? state.root.queryParams.continents : item.continents,
                    countries: state.root.queryParams.countries ? state.root.queryParams.countries : item.countries,
                    event_ids: state.root.queryParams.event_ids ? state.root.queryParams.event_ids : item.event_ids,
                    tutorial: state.root.queryParams.tutorial,
                    sort: state.root.queryParams.sort
                  },
                  // replaceUrl: true
                });
              }
            });
            return true;
          }),
          catchError(() => {
            return of(true);
          })
        );
    } else {
      return true;
    }

  }
}
