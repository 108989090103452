<div class="menu-account" *ngIf="user" [class.inblur]="params.tutorial" [class.noblur]="params.tutorial=='menuaside'">

  <ng-container [ngSwitch]="params.sidenav">

    <div class="navcont" *ngSwitchDefault>

      <!--<mat-accordion>

        <mat-expansion-panel hideToggle [expanded]="true">

          <mat-expansion-panel-header>
            <span>News</span>
            <mat-icon>message</mat-icon>
          </mat-expansion-panel-header>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/news/market" routerLinkActive="active">
            <span>Market</span>
            <mat-icon>grid_on</mat-icon>
          </button>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/news/portfolio" routerLinkActive="active">
            <span>Portfolio</span>
            <mat-icon>public</mat-icon>
          </button>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/news/query" routerLinkActive="active">
            <span>Collection</span>
            <mat-icon>view_module</mat-icon>
          </button>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/trending" routerLinkActive="active">
            <span>Trending</span>
            <mat-icon>whatshot</mat-icon>
          </button>

        </mat-expansion-panel>

        <mat-expansion-panel hideToggle>

          <mat-expansion-panel-header>
            <span>Trend</span>
            <mat-icon>whatshot</mat-icon>
          </mat-expansion-panel-header>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/trend/secsignal" routerLinkActive="active">
            <span>Signal</span>
            <mat-icon>timeline</mat-icon>
          </button>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/trend/indicator" routerLinkActive="active">
            <span>Indicator</span>
            <mat-icon>graphic_eq</mat-icon>
          </button>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/trend/multichart" routerLinkActive="active">
            <span>Multichart</span>
            <mat-icon>multiline_chart</mat-icon>
          </button>

        </mat-expansion-panel>

        <mat-expansion-panel hideToggle *ngIf="routing.isDev()">

          <mat-expansion-panel-header>
            <span>Other</span>
            <mat-icon>message</mat-icon>
          </mat-expansion-panel-header>

          <button class="btnful" mat-stroked-button routerLink="/cockpit/stories" routerLinkActive="active">
            <span>Stories</span>
            <mat-icon>question_answer</mat-icon>
          </button>

        </mat-expansion-panel>

      </mat-accordion>-->


      <div class="account-container">
        <div *ngIf="auth.featureFlags.showEmail" class="account">
          <div class="email">
            <span [matTooltip]="user.email" matTooltipPosition="above">{{user.email}}</span>
            <mat-icon *ngIf="routing.isDev()" class="dev-token" matTooltip="Copy user's token to clipboard" matTooltipPosition="above" (click)="getToken()">account_circle</mat-icon>
            <mat-icon *ngIf="!routing.isDev()">account_circle</mat-icon>
          </div>
        </div>
        <!--<mat-accordion>
          <mat-expansion-panel hideToggle *ngIf="routing.isDev()">
            <mat-expansion-panel-header>
              <span>{{ 'MENU_ACCOUNT.LANGUAGE' | translate }}</span>
              <mat-icon>language</mat-icon>
            </mat-expansion-panel-header>
            &lt;!&ndash; <div class="btnflx">
          <button mat-stroked-button [routerLink]="" [queryParams]="{i18n:'de'}" queryParamsHandling="merge"
            routerLinkActive="active">German</button>
          <button mat-stroked-button [routerLink]="" [queryParams]="{i18n:'en'}" queryParamsHandling="merge"
            routerLinkActive="active">English</button>
        </div> &ndash;&gt;
            <mat-button-toggle-group appearance="legacy" value="en">
              <mat-button-toggle value="en">English</mat-button-toggle>
              <mat-button-toggle value="de">German</mat-button-toggle>
            </mat-button-toggle-group>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <span>{{ 'MENU_ACCOUNT.SUBSCRIPTION' | translate }}</span>
              <mat-icon>extension</mat-icon>
            </mat-expansion-panel-header>
            <div class="btnflx">
              <button mat-stroked-button>
                <a href="https://shop.yukkalab.de/" target="_blank">
                  <span *ngIf="user.subscription=='NEWS'">News</span>
                  <span *ngIf="user.subscription=='TREND'">Trend</span>
                  <span *ngIf="user.subscription=='ALL'">News + Trend</span>
                </a>
              </button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel hideToggle>
            <mat-expansion-panel-header>
              <span>Roles</span>
              <mat-icon>face</mat-icon>
            </mat-expansion-panel-header>
            <mat-radio-button *ngFor="let role of user.roles" checked>{{role}}</mat-radio-button>
          </mat-expansion-panel>
        </mat-accordion>-->
        <div class="navbtn">
          <!--<button mat-raised-button (click)="inNewsletter()">
            <span>Newsletter</span>
            <mat-icon>email</mat-icon>
          </button>-->
          <!--<button *ngIf="auth.featureFlags.showFeedback" mat-raised-button (click)="onFeedback()">
            <span>{{ 'MENU_ACCOUNT.FEEDBACK' | translate }}</span>
            <mat-icon>feedback</mat-icon>
          </button>-->
          <button *ngIf="routing.isDev()" mat-raised-button [routerLink]="[]" [queryParams]="{sidenav:null}" queryParamsHandling="merge"
            (click)="inTutorial()">
            <span>{{ 'MENU_ACCOUNT.TUTORIAL' | translate }}</span>
            <mat-icon>help</mat-icon>
          </button>
          <button mat-raised-button routerLink="/stats" *ngIf="auth.featureFlags.showStats && user.roles.includes('ADMIN')">
            <span>{{ 'MENU_ACCOUNT.STATS' | translate }}</span>
            <mat-icon>table_chart</mat-icon>
          </button>
          <button mat-raised-button routerLink="/admin" *ngIf="auth.featureFlags.showAdmin && (user.roles.includes('ADMIN') || user.roles.includes('REVIEW'))">
            <span>{{ 'MENU_ACCOUNT.REPORTS' | translate }}</span>
            <mat-icon>assignment_late</mat-icon>
          </button>
          <button mat-raised-button (click)="onUserAdministration()" *ngIf="auth.featureFlags.showAdmin && user.roles.includes('ADMIN')">
            <span>{{ 'MENU_ACCOUNT.ADMIN' | translate }}</span>
            <mat-icon>recent_actors</mat-icon>
          </button>
          <button *ngIf="auth.featureFlags.showPassword" mat-raised-button routerLink="/password/change">
            <span>Password</span>
            <mat-icon>account_box</mat-icon>
          </button>
          <button *ngIf="auth.featureFlags.showLogout" class="logout" mat-raised-button routerLink="/logout">
            <span>{{ 'MENU_ACCOUNT.LOGOUT' | translate }}</span>
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>

  </ng-container>

  <!-- <button mat-button class="btnclose" [routerLink]="" [queryParams]="{sidenav:null}" queryParamsHandling="merge">
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button> -->
</div>
