<div class="newsletter-page">

  <div class="page-main" *ngIf="newsletterService.newsletter" [style.backgroundColor]="'white'" [style.color]="'black'">


    <div class="main-content">


      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
        <tbody>
        <tr>
          <td>

            <div style="Margin:0px auto;">

              <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
                <tbody>
                <tr>
                  <td style="direction:ltr;font-size:0px;padding:0px 0;text-align:center;vertical-align:top;">

                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">

                      </table>

                    </div>

                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">

                        <tbody>
                        <tr>
                          <td align="center" style="font-size:0px;padding:0;word-break:break-word;">

                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                              <tr>
                                <td>

                                  <img *ngIf="newsletterService.newsletter.header_image" height="auto"
                                       [src]="newsletterService.newsletter.header_image|trustUrl" />

                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </td>
                        </tr>

                        </tbody>
                      </table>

                    </div>


                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">

                        <tbody>
                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                              <tr>
                                <td>

                                  <img class="img_logo" *ngIf="newsletterService.newsletter.logo" height="auto"
                                       [src]="newsletterService.newsletter.logo|trustUrl" />

                                </td>
                              </tr>
                              </tbody>
                            </table>

                          </td>
                        </tr>

                        </tbody>
                      </table>

                    </div>

                  </td>
                </tr>
                </tbody>
              </table>

            </div>
          </td>
        </tr>
        </tbody>
      </table>


      <div class="content-section" style="Margin:0px auto;">

        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
          <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">

              <div class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                  <tbody>
                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                      <div
                              style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:30px;line-height:1;text-align:center;">
                        {{newsletterService.newsletter.title}}
                      </div>

                    </td>
                  </tr>

                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                      <div
                              style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:center;">
                        {{timeRange}}
                      </div>

                    </td>
                  </tr>

                  </tbody>
                </table>

              </div>

            </td>
          </tr>
          </tbody>
        </table>

      </div>


      <ng-container *ngFor="let displayable of newsletterService.newsletter.dispObjects; index as index;">


        <div class="content-section" style="Margin:0px auto;">

          <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
            <tbody>
            <tr>
              <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">

                <div class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:25px;font-weight:700;line-height:1;text-align:center;">

                          <ng-container [ngSwitch]="displayable.type">
                            <div *ngSwitchCase="'entity'">{{displayable.view}} Entity - Overview</div>
                            <div *ngSwitchCase="'portfolio'">{{displayable.view}} Portfolio - Overview</div>
                            <div *ngSwitchCase="'search_query'">{{displayable.view}} Query - Overview</div>
                            <div *ngSwitchCase="'search_query_group'">{{displayable.view}} Collection - Overview</div>
                          </ng-container>

                        </div>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>


                <div *ngIf="iInclude('chart')" class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;display: flex;align-items: center;">
                          <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-icons-v1.s3.eu-central-1.amazonaws.com/baseline_insert_chart_black_24dp.png"> Performance
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td style="font-size:0px;word-break:break-word;">



                        <div style="height:20px;">
                          &nbsp;
                        </div>


                      </td>
                    </tr>

                    <tr>
                      <td align="right" class="chart-sentiment-display"
                          style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:right;color:#525252;">
                          Current {{auth.getScoresInfo(getScoreType())['name']}}: 69%
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                               style="border-collapse:collapse;border-spacing:0px;">
                          <tbody>
                          <tr>
                            <td>

                              <a href="/assets/images/YukkaLabSentiment.png"
                                 target="_blank">

                                <img height="auto"
                                     src="/assets/images/YukkaLabSentiment.png"
                                     style="border:0;display:block;outline:none;text-decoration:none;height:auto;">

                              </a>

                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>



                <div *ngIf="iInclude('curated_news')" class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;display: flex;align-items: center;">
                          <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-icons-v1.s3.eu-central-1.amazonaws.com/baseline_bookmark_black_24dp.png"> Curated News
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;color:#525252;">
                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanznachrichten.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 06:49:13</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">seekingalpha.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 14:08:37</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">vogel.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 05:16:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">Xinhua News Agency (China)</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 14:06:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">wiwo.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 09:43:00</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>


                <div *ngIf="iInclude('top_news')" class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;display: flex;align-items: center;">
                          <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-icons-v1.s3.eu-central-1.amazonaws.com/baseline_local_activity_black_24dp.png"> Relevant News Bulletin
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;color:#525252;">
                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanznachrichten.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 06:49:13</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">seekingalpha.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 14:08:37</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">vogel.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 05:16:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">Xinhua News Agency (China)</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 14:06:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">wiwo.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 09:43:00</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>


                <div *ngIf="iInclude('all_news')" class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;display: flex;align-items: center;">
                          <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-icons-v1.s3.eu-central-1.amazonaws.com/baseline_description_black_24dp.png"> Recent News
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;color:#525252;">
                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">edmunds.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:56:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a>
                            </div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanzen.net</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:47:26</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ARIVA.DE</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:43:11</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ARIVA.DE</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:43:06</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">autonews.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:37:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">GlobeNewswire</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:30:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ad-hoc-news.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:13:54</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">pr-board.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:10:25</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">pr-board.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:10:20</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">thehindubusinessline.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:10:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">dpa German</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:07:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">trading-house.net</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:05:47</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">PR Newswire</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 12:00:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a>
                            </div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ARIVA.DE</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:36:16</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ARIVA.DE</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:36:06</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">ARIVA.DE</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:36:01</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">DIE WeLT online</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:29:25</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">forbes.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:28:00</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a>
                            </div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanzen.ch</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:27:34</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanzen.ch</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 11:27:26</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>


                <div *ngIf="iInclude('stories')" class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

                    <tbody>
                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;display: flex;align-items: center;">
                          <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-v1.s3.eu-central-1.amazonaws.com/icons/baseline_local_activity_black_24dp.png"> Stories
                        </div>

                      </td>
                    </tr>

                    <tr>
                      <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <div
                                style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;color:#525252;">
                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link" title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">finanznachrichten.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 06:49:13</span>
                            </div>
                            <div class="news-link-row" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: 16px; margin-top: 8px; margin-left: 18px;">
                              <span class="news-link" style="text-decoration: none; font-size: 14px; font-weight: bold; color: #464646;">+100 Articles</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #dd001a' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">seekingalpha.com</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>11/06/2019 14:08:37</span>
                            </div>
                            <div class="news-link-row" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: 16px; margin-top: 8px; margin-left: 18px;">
                              <span class="news-link" style="text-decoration: none; font-size: 14px; font-weight: bold; color: #464646;">+90 Articles</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">vogel.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>17/06/2019 05:16:00</span>
                            </div>
                            <div class="news-link-row" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: 16px; margin-top: 8px; margin-left: 18px;">
                              <span class="news-link" style="text-decoration: none; font-size: 14px; font-weight: bold; color: #464646;">+80 Articles</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">Xinhua News Agency (China)</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 14:06:00</span>
                            </div>
                            <div class="news-link-row" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: 16px; margin-top: 8px; margin-left: 18px;">
                              <span class="news-link" style="text-decoration: none; font-size: 14px; font-weight: bold; color: #464646;">+70 Articles</span>
                            </div>
                          </div>

                          <div class="news-row" [style.border-left]="!newsletterService.newsletter.hide_sentiment_bars ? '5px solid #3dac00' : ''">
                            <div class="news-link-row"><a
                                    target="_blank" class="news-link"
                                    title="Lorem Ipsum Dorum">Lorem Ipsum Dorum</a></div>
                            <div *ngIf="iInclude('snippet')" class="news-row-teaser">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </div>
                            <div class="news-metadata-row">
                              <i class="material-icons news-metadata-icon">public</i>
                              <span style="margin-right:6px;">wiwo.de</span>
                              <i class="material-icons news-metadata-icon">access_time</i>
                              <span>13/06/2019 09:43:00</span>
                            </div>
                            <div class="news-link-row" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: 16px; margin-top: 8px; margin-left: 18px;">
                              <span class="news-link" style="text-decoration: none; font-size: 14px; font-weight: bold; color: #464646;">+60 Articles</span>
                            </div>
                          </div>
                        </div>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>


                <div class="mj-column-per-100 outlook-group-fix"
                     style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">

                  <table border="0" cellpadding="0" cellspacing="0" role="presentation" width="100%" style="vertical-align:top;">

                    <tbody>

                    <tr>
                      <td align="center" vertical-align="middle"
                          style="font-size:0px;padding:10px 25px;word-break:break-word;">

                        <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                               style="border-collapse:separate;line-height:100%;width:auto!important;">
                          <tbody>
                          <tr>
                            <td align="center" bgcolor="#999999" role="presentation" valign="middle" style="border:none;border-radius:3px;cursor:auto;padding:0px 25px 18px 25px;background:#999999;">
                              <a
                                      style="background:#999999;color:#ffffff;font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:15px;font-weight:normal;line-height:120%;margin:0;text-decoration:none;text-transform:none;display: flex;align-items: center;"
                                      target="_blank">
                                <img style="font-size: 36px;margin-right: 15px;border:0;height:36px;width:36px!important;" src="https://yl-newsletters-icons-v1.s3.eu-central-1.amazonaws.com/baseline_description_white_24dp.png"> View in the Cockpit
                              </a>
                            </td>
                          </tr>
                          </tbody>
                        </table>

                      </td>
                    </tr>

                    </tbody>
                  </table>

                </div>

              </td>
            </tr>
            </tbody>
          </table>

        </div>


      </ng-container>



    </div>




    <div class="main-signature">

      <div *ngIf="newsletterService.newsletter.signature_footer" class="signature"
           style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

        <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">

          <tbody>
          <tr>
            <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">

              <div
                      style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;color:#525252;">
                <div *ngIf="newsletterService.newsletter.signatureHTML" [innerHTML]="newsletterService.newsletter.signatureHTML | sanitizeHtml"></div>
              </div>

            </td>
          </tr>

          </tbody>
        </table>

      </div>

    </div>




    <div class="main-disclaimer">

      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style=" color:silver">
        <tbody>
        <tr>
          <td>

            <div style="Margin:0px auto;">

              <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
                <tbody>
                <tr>
                  <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">

                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">

                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">

                        <tbody>
                        <tr>
                          <td style="font-size:0px;word-break:break-word;">

                            <div style="height:20px;">
                              &nbsp;
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align="justify" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                            <div *ngIf="newsletterService.newsletter.disclaimer"
                                 style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:justify;">
                              {{newsletterService.newsletter.disclaimer}}
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td style="font-size:0px;word-break:break-word;">

                            <div style="height:20px;">
                              &nbsp;
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align="justify" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                            <div *ngIf="!newsletterService.newsletter.disclaimer"
                                 style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:justify;">
                              <strong>IMPORTANT INFORMATION:</strong> This publication is for your information only
                              and does not constitute any offer or a solicitation of an offer and/or the purchase or
                              sale of investment products. The information and opinions contained in this
                              publication are from trusted sources. Nevertheless, YUKKA Lab AG reject any
                              contractual or implied liability for incorrect or incomplete information. All
                              information and opinions are subject to change without notice.
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td style="font-size:0px;word-break:break-word;">

                            <div style="height:10px;">
                              &nbsp;
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                            <div *ngIf="!newsletterService.newsletter.disclaimer"
                                 style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:center;">
                              To unsubscribe from this newsletter, <a
                                    target="_blank">please click here</a>.
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">

                            <div *ngIf="!newsletterService.newsletter.disclaimer"
                                 style="font-family:HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:center;">
                              Copyright © YUKKA Lab AG 2014-2020
                            </div>

                          </td>
                        </tr>

                        <tr>
                          <td style="font-size:0px;word-break:break-word;">

                            <div style="height:10px;">
                              &nbsp;
                            </div>

                          </td>
                        </tr>

                        </tbody>
                      </table>

                    </div>

                  </td>
                </tr>
                </tbody>
              </table>

            </div>

          </td>
        </tr>
        </tbody>
      </table>

    </div>



    <div *ngIf="false">


      <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
        <tbody>
        <tr>
          <td>
            <div style="Margin:0px auto;">
              <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
                <tbody>
                <tr>
                  <td style="direction:ltr;font-size:0px;padding:0px 0;text-align:center;vertical-align:top;">
                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">
                        <tr>
                          <td align="center" style="font-size:0px;padding:0;word-break:break-word;">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                              <tr>
                                <td>
                                  <img *ngIf="newsletterService.newsletter.header_image" height="auto"
                                       [src]="newsletterService.newsletter.header_image|trustUrl"
                                       style="border:0;display:block;outline:none;text-decoration:none;height:auto;" />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div class="mj-column-per-100 outlook-group-fix"
                         style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="vertical-align:top;">
                        <tr>
                          <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                            <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                                   style="border-collapse:collapse;border-spacing:0px;">
                              <tbody>
                              <tr>
                                <td>
                                  <img *ngIf="newsletterService.newsletter.logo" height="auto" [src]="newsletterService.newsletter.logo|trustUrl"
                                       style="border:0;display:block;outline:none;text-decoration:none;height:auto;" />
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <div class="content-section" style="Margin:0px auto;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
          <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">
              <div class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:30px;line-height:1;text-align:center;">
                        {{newsletterService.newsletter.title}}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:center;">
                        May 21 2019 - May 28 2019
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="content-section" style="Margin:0px auto;">
        <table align="center" border="0" cellpadding="0" cellspacing="0" role="presentation">
          <tbody>
          <tr>
            <td style="direction:ltr;font-size:0px;padding:20px 0;text-align:center;vertical-align:top;">
              <div class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:25px;font-weight:700;line-height:1;text-align:center;">

                        {{ displayable.type=='portfolio' ? 'Portfolio - Overview' : displayable.view+' - Overview'}}

                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;">
                        <i class="material-icons inline-icon">insert_chart</i> Performance
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="font-size:0px;word-break:break-word;">
                      <div style="height:20px;">
                        &nbsp;
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="right" class="chart-sentiment-display"
                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:16px;line-height:1;text-align:right;">
                        Sentiment: 65% (+1%)
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="center" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="border-collapse:collapse;border-spacing:0px;">
                        <tbody>
                        <tr>
                          <td>
                            <a href="https://minio.yukkalab.de/newsletter-20190528/081fe5d9-b25c-453d-aad1-ec736f9e9752.png"
                               target="_blank">

                              <img height="auto"
                                   src="https://minio.yukkalab.de/newsletter-20190528/081fe5d9-b25c-453d-aad1-ec736f9e9752.png"
                                   style="border:0;display:block;outline:none;text-decoration:none;height:auto;" />
                            </a>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>

              <div *ngIf="iInclude('top_news')" class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;">
                        <i class="material-icons inline-icon">bookmark</i> Top News Bulletin
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;">
                        <ul>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=482f48d04732c2444c9adb9e9da204f12b0333a7"
                                  target="_blank">Nach Sponsoren-Duell zwischen Audi und BMW um FC Bayern: Mercedes-Bank
                            bleibt Hauptsponsor des VfB Stuttgart.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=917bf722324b9e9282fa20a4695d832e85d0f5c5"
                                  target="_blank">BMW shareholders worry about Tesla\'s competitive advantage.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=e078a183a3174721ef160a785d2db4f4a800841d"
                                  target="_blank">Kooperation mit Sixt: Audi weitet Mobilitätsangebot deutlich aus.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=68de4d52ce69bc03a91da7e47f481d9922c9ab0d"
                                  target="_blank">Vic: Porsche cops $100K fine for Vic oil spill.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=75ef5ee4819aed8718078e0bd9d64d191703220b"
                                  target="_blank">Abschiedsvideo: Wenn Daimler-Chef Dieter Zetsche auf BMW umsteigt.</a>
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div *ngIf="iInclude('all_news')" class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;font-weight:600;line-height:1;text-align:left;">
                        <i class="material-icons inline-icon">description</i> All News
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td align="left" style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <div
                              style="font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;line-height:1;text-align:left;">
                        <ul>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=84ab3918a6cba222131085b242c2ec949ab0f5bb"
                                  target="_blank">Kriminalität - Stuttgart: Porsche-Durchsuchungen: Verdacht auf Bestechung
                            und Untreue.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=f4443f3ed3f09fa1be801030711cc32056e677ab"
                                  target="_blank">Kriminalität: Durchsuchungen bei\xa0Porsche.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=8027b6eaa7d33bd7f58f81e91c7b720520908e42"
                                  target="_blank">Durchsuchungen bei\xa0Porsche.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=4deeba584d8e562fab22b70304d9cb68e8ebff85"
                                  target="_blank">Durchsuchungen bei\xa0Porsche:\xa0Verdacht auf Bestechung und Untreue.</a>
                          </li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=8597f835b825b648761157a9e1196c47b6d5ce1d"
                                  target="_blank">Razzia bei Porsche im Raum Stuttgart, Verdacht auf Bestechung und
                            Untreue.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=0b3ea62d5bbec41b0f39246ca950b0005ff43195"
                                  target="_blank">Durchsuchungen bei\xa0Porsche:\xa0Verdacht auf Bestechung und Untreue.</a>
                          </li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=8645e5943a162d0414b8ccb3bb2710cccb32aa69"
                                  target="_blank">Greenville\'s old convention center faces uncertain future with talk of
                            downtown location.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=655507b6fe23cb31826a471f0a6bb34509a999b0"
                                  target="_blank">Neuer BMW 1er vorgestellt.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=5eefaa87a4a5439421fa7b097904906076ddadef"
                                  target="_blank">Fossil unveils BMW watch collections in India, price starts at 10,995.</a>
                          </li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=b0a85e73b1cc30769b6fff580a9fb1445b5b41f8"
                                  target="_blank">Neuer BMW 1er vorgestellt.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=86213b53a43be51eac91d7a964e79ed2f0ec99ee"
                                  target="_blank">Global $1.3 Bn Automotive Ventilated Seat Fan Markets, Analysis 2012-2017
                            &
                            Forecasts 2018-2023.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=fcf047ef4cea0f381cffcc6b7aa958e384a40f9e"
                                  target="_blank">(Tabelle).\nDAX: Kurse im XETRA-Handel am 28.05.2019 um 13:05 Uhr.</a>
                          </li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=252f008847aedfed32e598c6d4caf037d31d2b6b"
                                  target="_blank">New Cars That Owners Don\'t Even Keep for a Year.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=ab5f8b3c6e2cf2d3557c51487f07ec4bcc2ca84b"
                                  target="_blank">Translation: “Philharmonic Festival for All” celebrates the 125th
                            anniversary of the Munich Philharmonic. The BMW Group congratulates as a longtime
                            partner.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=6ea3ab1a03c700b7cbb27373328ade7e8ee6fb9e"
                                  target="_blank">German parts maker Kostal expands in Bulgaria again.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=d346ee8a3ed9fec8acbec82c2329fd6fe449ee7b"
                                  target="_blank">DGAP-News: SPORTTOTAL AG: sporttotal.tv erweitert Sportangebot mit Futsal
                            (deutsch).</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=4b2993c98afcb99c76b79dfde8985f2942f6154d"
                                  target="_blank">GM Merger With Ford Looks More Likely.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=773646ea3a636f560b0529940f3c9d92c718e460"
                                  target="_blank">„Philharmonisches Fest für alle“ feiert 125-jähriges Bestehen der Münchner
                            Philharmoniker. Die BMW Group als langjähriger Partner gratuliert.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=f52a0cf2524cd0988331064244429f85983bc3ac"
                                  target="_blank">Visteon Corp (VC) Shares Bought by Connor Clark & Lunn Investment
                            Management
                            Ltd.</a></li>
                          <li><a
                                  href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&news=27760b6b9df8b9ede8c1a31ee22f53e79d9b4edc"
                                  target="_blank">Distinctive, high-quality and homogeneous: the Curved Display in the BMW
                            iNEXT.</a></li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>

              <div *ngIf="iInclude('all_news')" class="mj-column-per-100 outlook-group-fix"
                   style="font-size:13px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;">
                <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;">
                  <tr>
                    <td align="center" vertical-align="middle"
                        style="font-size:0px;padding:10px 25px;word-break:break-word;">
                      <table border="0" cellpadding="0" cellspacing="0" role="presentation"
                             style="border-collapse:separate;line-height:100%;">
                        <tr>
                          <td align="center" bgcolor="#F45E43" role="presentation"
                              style="border:none;border-radius:3px;cursor:auto;padding:0px 25px 18px 25px;background:#F45E43;"
                              valign="middle">
                            <a href="https://app.yukkalab.com/cockpit/news/chartmap?type=portfolio&id=59ef9932-9931-4a0e-8f62-22ad12918fff&entities=company:audi,company:bmw,company:porsche_automobil&portfolio=automobile&time=2019-05-21--2019-05-28"
                               style="background:#F45E43;color:#ffffff;font-family:'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', MaterialIcons, sans-serif;font-size:13px;font-weight:normal;line-height:120%;Margin:0;text-decoration:none;text-transform:none;"
                               target="_blank">
                              <i class="material-icons inline-icon with-small-margin">description</i> View All News
                            </a>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>


    </div>


  </div>

</div>
