import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
/**
* redirect to the mobile version if the width is under 700px
*/
@Injectable()
export class MobileGuard implements CanActivate {
  constructor(
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const project = state.url.split('?')[0].split('/');
    if (window.innerWidth < 700 && project[1] !== 'mobile') {
      this.router.navigateByUrl('/mobile');
    } else {
      return true;
    }
  }
}
