<div class="network-main" [class.widget]="routing.isWidget()">
  <app-news-title *ngIf="!routing.isWidget()" [titolo]="titolo"></app-news-title>
  <div class="network-graph">
    <div class="loading" *ngIf="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="nodata" *ngIf="error">Something went wrong. Please try to refresh the page</div>
    <div class="nodata" *ngIf="nodata">NOT ENOUGH DATA</div>
    <div class="menutool">
      <div class="cont">
        <button mat-icon-button (click)="inzoom(1.2)" matTooltip="Zoom In" matTooltipPosition="right">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="inzoom(0.8)" matTooltip="Zoom Out" matTooltipPosition="right">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button (click)="refresh()" matTooltip="Refresh" matTooltipPosition="right">
          <mat-icon>refresh</mat-icon>
        </button>
      </div>
    </div>
    <div id="graph"></div>
  </div>
</div>
