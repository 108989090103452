import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import * as d3 from 'd3';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from '../../../service/routing.service';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../../service/config.service';
import { ColorPipe } from '../../../pipe/color.pipe';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

/**
 * The network use D3 collision animation: each node have a circle, a text and links connected with another nodes
 * If you look for D3 Force Directed Graph on the web you can find a lot of examples : {@link https://bl.ocks.org/heybignick/3faf257bbbbc7743bb72310d03b86ee8/|bl.ocks.org}
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit, OnDestroy {
  loading: boolean;
  nodata: boolean;
  result: any;
  tag: any = undefined;
  targets: any;
  groupid: string;
  nodes: any;
  links: any;
  zoom: any;
  svg: any;
  simulation: any;
  custom: any = {
    size: 40,
    link: {
      color: 'white'
    }
  };
  params: any;
  previousValue: any;
  titolo: any;
  theme: string;
  error: boolean;

  /**
   * load the modules and service and setup the theme
   */
  constructor(
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    private colorpipe: ColorPipe,
    private router: Router,
    public auth: AuthService,
    public config: ConfigService,
  ) {
    this.theme = this.routing.theme;
  }

  /**
   * subscribe to the query parameters and make the request data
   */
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (!localStorage.getItem('theme') ||
        localStorage.getItem('theme') === null ||
        localStorage.getItem('theme') === 'dark') {
        this.custom.link.color = 'white';
      }
      if (params.theme === 'light' || (localStorage.getItem('theme') === 'light')) {
        this.custom.link.color = 'black';
      }
      if (params.tag) {
        this.tag = decodeURI(params.tag.split('|')[0]);
      } else {
        this.tag = undefined;
      }
      this.params = params;
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(params, this.previousValue, ['type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'newstype'])) {
        this.error = false;
        this.nodata = false;
        this.loading = true;
        this.yukkApi.isentiment(this.params, true).subscribe(result => {
          if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && this.params.type && ((this.params.type === 'company') || (this.params.type === 'pne'))) {
            this.yukkApi.scoresTimeSeries('score', this.params, 'chart').subscribe(result2 => {
              this.titolo = Object.assign({}, result, {score: Number(result2['score_ts'][result2['score_ts'].length - 1]['score'].toFixed(2))});
            });
          } else {
            this.titolo = result;
          }
        });
        const obj = {
          params: this.params,
          custom: {
            size: undefined
          }
        };
        this.routing.isPath('mobile') ? obj.custom.size = 20 : obj.custom.size = 40;
        this.yukkApi.network(obj).subscribe(
          result => {
            this.result = result;
            this.iNitial();
          }, error => {
            this.loading = false;
            this.error = true;
          }
        );
      } else if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        setTimeout(() => {
          this.refresh();
        }, 500);
      } else {
        this.mynetwork();
      }
      this.previousValue = params;
    });
  }

  /**
   * when the component is destroy we have to sto the d3 simulation
   */
  ngOnDestroy() {
    if (this.simulation) {
      this.simulation.stop();
    }
  }

  /**
   * initialize the d3 network
   */
  iNitial() {
    const __this = this;
    this.result.network.nodes.length ? this.nodata = false : this.nodata = true;
    this.loading = false;
    __this.nodes = this.result.network.nodes;
    __this.links = this.result.network.edges;
    const filter = this.params.filter;
    const mincount = Math.min.apply(Math, __this.nodes.map(nodeao => nodeao.score));
    const maxcount = Math.max.apply(Math, __this.nodes.map(nodeae => nodeae.score));
    const circleradio = d3.scaleLinear().domain([mincount, maxcount]).range([5, 80]);
    const fontsize = d3.scaleLinear().domain([mincount, maxcount]).range([3, 25]);
    const minweight = Math.min.apply(Math, __this.links.map((edge) => edge.weight));
    const maxweight = Math.max.apply(Math, __this.links.map((edge) => edge.weight));
    const edgeweight = d3.scaleLinear().domain([minweight, maxweight]).range([1, 2]);

    __this.isTag();

    const links_data = __this.links.map(link1 => {
      return {
        source: link1.source,
        target: link1.target,
        weight: edgeweight(link1.weight)
      };
    });

    const nodes_data = __this.nodes.map(node1 => {
      return {
        tag: node1.tag,
        name: node1.name,
        group: node1.group,
        radius: circleradio(node1.score),
        fontsize: fontsize(node1.score),
        sentiment: node1.sentiment,
        color: this.colorpipe.transform(node1.sentiment)
      };
    });

    const width = document.querySelector('#graph').clientWidth;
    const height = document.querySelector('#graph').clientHeight;

    d3.select('#graph svg').remove();
    __this.svg = d3.select('#graph')
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('cursor', 'move');

    const g = __this.svg.append('g');

    const links = g.append('g').attr('class', 'cont_link');

    const link = links.selectAll('node')
      .data(links_data)
      .enter()
      .append('path')
      .attr('class', 'links')
      .attr('fill', 'transparent')
      .attr('stroke-width', d => d.weight)
      .attr('stroke', this.custom.link.color)
      .attr('stroke-opacity', '0.15');

    const nodes = g.append('g').attr('class', 'nodes');

    const node = nodes.selectAll('node')
      .data(nodes_data)
      .classed('fixed', d => d.score > 10)
      .enter()
      .append('g')
      .on('mouseover', mouseOver())
      .on('mouseout', mouseOut);

    const circle = node.append('circle')
      .attr('class', 'circle')
      .attr('r', d => d.radius)
      .attr('fill', d => d.color)
      .style('cursor', 'pointer');

    const text = node.append('text')
      .attr('class', 'text')
      .attr('text-anchor', 'middle')
      .style('cursor', 'pointer')
      .attr('font-size', d => {
        if (d.name) {
          const maxcount1 = Math.max.apply(Math, d.name.split(' ').map(word => word.length));
          if (maxcount1 * d.fontsize > d.radius * 3) { return d.fontsize * 0.5 + 'px'; }
          return d.fontsize * .8 + 'px';
        }
      })
      .attr('cx', function (d) { return d.x; })
      .attr('cy', function (d) { return d.y; })
      .html(d => {
        if (d.name) {
          const arr = d.name.trim().replace(/[^A-Za-z0-9 ]/g, '').split(' ');
          if (arr.length > 3) {
            return '<tspan x="0" y="' + -d.fontsize + '">' + arr.splice(0, Math.ceil(arr.length / 4)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + -d.fontsize / 3.5 + '">' + arr.splice(0, Math.ceil(arr.length / 3)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + d.fontsize / 3.5 + '">' + arr.splice(0, Math.ceil(arr.length / 2)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + d.fontsize + '">' + arr.join(' ') + '</tspan>';
          } else if (arr.length > 2) {
            return '<tspan x="0" y="' + -d.fontsize / 3 + '">' + arr.splice(0, Math.ceil(arr.length / 3)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + d.fontsize / 3 + '">' + arr.splice(0, Math.ceil(arr.length / 2)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + d.fontsize / 1 + '">' + arr.join(' ') + '</tspan>';
          } else if (arr.length > 1) {
            return '<tspan x="0" y="' + -d.fontsize / 3 + '">' + arr.splice(0, Math.ceil(arr.length / 2)).join(' ') + '</tspan>' +
              '<tspan x="0" y="' + d.fontsize / 2 + '">' + arr.join(' ') + '</tspan>';
          } else {
            return '<tspan y="' + d.fontsize / 4 + '">' + d.name + '</tspan>';
          }
        }
      });

    const simulation = d3.forceSimulation(nodes_data)
      .force('collide', d3.forceCollide(d => d.radius + (d.radius / 3) + 5).iterations(30))
      // .force('charge', d3.forceManyBody())
      .force('link', d3.forceLink(links_data))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('y', d3.forceY(0))
      .force('x', d3.forceX(0))
      .on('tick', tickActions);

    function tickActions() {
      node
        .attr('transform', d => 'translate(' + d.x + ',' + d.y + ')')
        .on('click', click_tag);

      link.attr('d', function (d) {
        const dx = d.target.x - d.source.x,
          dy = d.target.y - d.source.y,
          dr = Math.sqrt(dx * dx + dy * dy);
        return 'M' + d.source.x + ',' + d.source.y + 'A' + dr + ',' + dr + ' 0 0,1 ' + d.target.x + ',' + d.target.y;
      });

      if (!__this.routing.isMobile()) {
        const drag_handler = d3.drag()
          .on('start', drag_start)
          .on('drag', drag_drag)
          .on('end', drag_end);
        drag_handler(node);
      }

      function drag_start(d) {
        if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
        d.fx = d.x;
        d.fy = d.y;
        d3.event.sourceEvent.stopPropagation();
      }
      function drag_drag(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }
      function drag_end(d) {
        if (!d3.event.active) { simulation.alphaTarget(0); }
        d.fx = null;
        d.fy = null;
      }
      function click_tag(d) {
        __this.goTag(d);
      }
      __this.zoom = d3.zoom()
        // .scaleExtent([0.1, 8])
        .on('zoom', function () { g.attr('transform', d3.event.transform); });
      __this.svg.call(__this.zoom);
    }

    const linkedByIndex = {};
    links_data.forEach(function (d) {
      linkedByIndex[d.source.index + ',' + d.target.index] = 1;
    });
    function isConnected(a, b) {
      return linkedByIndex[a.index + ',' + b.index] || linkedByIndex[b.index + ',' + a.index] || a.index === b.index;
    }

    function mouseOver() {
      if (!__this.routing.isMobile()) {
        return function (d) {
          // node.style("stroke-opacity", function (o) {
          //   let thisOpacity = isConnected(d, o) ? 1 : 1
          //   return thisOpacity
          // })
          node.style('fill-opacity', function (o) {
            const thisOpacity = isConnected(d, o) ? 1 : .5;
            return thisOpacity;
          });
          link.style('stroke-opacity', function (o) {
            return o.source === d || o.target === d ? .5 : .05;
          });
        };
      }
    }

    function mouseOut() {
      // node.style("stroke-opacity", 1)
      node.style('fill-opacity', 1);
      link.style('stroke-opacity', .15);
      // link.style("stroke", "#ddd")
    }

    this.mynetwork();

  }

  /**
   * chekc the tag active
   */
  isTag() {
    this.targets = [];
    this.groupid = undefined;
    if (this.tag) {
      this.nodes.forEach(node => {
        if (node.tag === this.tag) {
          this.groupid = node.group;
        }
      });
      this.links.forEach(link => {
        if (link.source === this.groupid || link.target === this.groupid) {
          this.targets.push(link.target);
          this.targets.push(link.source);
        }
      });
    }
  }

  /**
   * filter the circles
   */
  filterCircle(d) {
    if (this.tag) {
      if (this.tag === d.tag) {
        return true;
      }
      if (this.targets.indexOf(d.group) !== -1) {
        return true;
      }
      return false;
    }
    if (this.params.filter === 'pos') {
      return d.sentiment > .60;
    }
    if (this.params.filter === 'neu') {
      return d.sentiment >= .40 && d.sentiment <= .60;
    }
    if (this.params.filter === 'neg') {
      return d.sentiment < .60;
    }
    return true;
  }

  /**
   * filter the links
   */
  filterLink(d) {
    if (this.tag) {
      if ((this.targets.indexOf(d.target.group) !== -1 &&
        d.source.group === this.groupid) || (this.targets.indexOf(d.source.group) !== -1 &&
          d.target.group === this.groupid)) { return true; }
      return false;
    }
    if (this.params.filter === 'pos') {
      return d.source.sentiment > .60 && d.target.sentiment > .60;
    }
    if (this.params.filter === 'neu') {
      return d.source.sentiment >= .40 && d.source.sentiment <= .60 && d.target.sentiment >= .40 && d.target.sentiment <= .60;
    }
    if (this.params.filter === 'neg') {
      return d.source.sentiment < .40 && d.target.sentiment < .40;
    }
    return true;
  }

  /**
   * filter the texts
   */
  filterText(d) {
    if (this.tag) { return false; }
    return true;
  }

  /**
   * update the d3 network
   */
  mynetwork() {
    this.isTag();
    d3.selectAll('.circle')
      .attr('opacity', d => this.filterCircle(d) ? 1 : .2);
    d3.selectAll('.links')
      .attr('opacity', d => this.filterLink(d) ? 1 : .1);
    d3.selectAll('text')
      .attr('fill', d => this.filterText(d) ? 'black' : 'white');
  }

  /**
   * refresh the network
   */
  refresh() {
    this.iNitial();
  }

  /**
   * zoom inside the network
   */
  inzoom(value) {
    this.zoom.scaleBy(this.svg, value);
  }

  /**
   * click a node
   */
  goTag(d) {
    let mytag = d.tag + '|' + d.name;
    if (this.params.tag === mytag) { mytag = null; }
    this.router.navigate([], {
      queryParams: { tag: mytag, news: null },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

}
