import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
import { tap, map, take } from 'rxjs/operators';
/**
* this guard check if you subscribe to the trend area
*/
@Injectable()
export class TrendGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.auth.authToken();
    if (user.subscription === 'ALL' || user.subscription === 'TREND') {
      return true;
    } else {
      this.router.navigate(['/cockpit/news/board']);
    }
  }
}
