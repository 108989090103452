import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { YukkApi } from '../../../../service/yukkapi.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of, forkJoin } from 'rxjs';
import { debounceTime, map, mergeMap, shareReplay, catchError } from 'rxjs/operators';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../../service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-portfolio-index',
  templateUrl: './portfolio-index.component.html',
  styleUrls: ['./portfolio-index.component.scss']
})
export class PortfolioIndexComponent {

  step = 0;
  myIndex: UntypedFormControl = new UntypedFormControl();
  aggregatedPortfolios = [];
  items$: Observable<string[]>;
  indexmap: string;
  params: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private yukkApi: YukkApi,
    public auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

    if (this.auth.folio.is_aggregated) {
      if (this.data.action === 'new') {
        this.auth.getAggregatedPortfolios().subscribe(res => {
          this.aggregatedPortfolios = res.hits.filter(el => el.uid === this.auth.folio.uid)[0].content;
        });
      }
    }

    this.myIndex.valueChanges
      .pipe(debounceTime(1000))
      .subscribe(value => {
        if ( value && ( value.length > 1 ) ) {
          const reqEntity = this.yukkApi.search(value, this.params).pipe(map((res) => res), catchError(e => of({hits: []})));
          const reqPNE = this.yukkApi.search_pne(value, this.params).pipe(map((res) => res), catchError(e => of([])));
          this.items$ = forkJoin([reqEntity, reqPNE]).pipe(mergeMap(res => {
            const result = res[0].hits.concat(this.getAllPNEs(res[1]));
            return of(result);
          }), shareReplay());
        }
      });

    if (this.data.action === 'edit') {
      this.myIndex.setValue(this.data.entity.name);
    }

  }

  goSelect(value) {
    this.myIndex.setValue(value.entity.name);
    this.indexmap = value.entity.type + ':' + value.entity.alpha_id;
  }

  saveIndex(event) {
    if (this.indexmap) {
      if (this.data.action === 'new') {
        this.auth.addEntity([this.indexmap]).subscribe(_ => {
          this.router.navigate([], {
            queryParams: {
              update: Math.random()
            }, queryParamsHandling: 'merge'
          });
          this.dialog.closeAll();
        });
      }
      if (this.data.action === 'edit') {
        const value = this.data.entity.type + ':' + this.data.entity.alpha_id;
        this.auth.deleteEntity(value).subscribe(_ => {
          this.auth.addEntity([this.indexmap]).subscribe(_ => {
            this.router.navigate([], {
              queryParams: {
                update: Math.random()
              }, queryParamsHandling: 'merge'
            });
            this.dialog.closeAll();
          });
        });
      }
    }
    if (this.auth.folio.is_aggregated) {
      if (this.data.action === 'new') {
        this.auth.aggregatePortfolios(this.aggregatedPortfolios, this.auth.folio.uid).subscribe(() => {
          this.router.navigate([], {
            queryParams: {
              update: Math.random()
            }, queryParamsHandling: 'merge'
          });
          this.dialog.closeAll();
        });
      }
    }
  }

  deleteIndex() {
    const value = this.data.entity.type + ':' + this.data.entity.alpha_id;
    this.auth.deleteEntity(value).subscribe(_ => {
      this.router.navigate([], {
        queryParams: {
          update: Math.random()
        }, queryParamsHandling: 'merge'
      });
      this.dialog.closeAll();
    });
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0);
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num) {
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  /**
   * get list of all pnes
   */
  getAllPNEs(items) {
    const pneList = [];
    items.forEach(item => {
      item.compound_keys.forEach(el => {
        pneList.push({
          count: '',
          entity: {
            surface: item.surface,
            name: item.surface + ' (' + el.split(':')[1].split('.')[0] + ')',
            compound_key: el,
            type: 'pne',
            id: el.split(':')[1].split('.')[1],
            alpha_id: el.split(':')[1]
          }
        });
      });
    });
    return pneList;
  }

}
