import {Component, OnInit, Input, ViewEncapsulation, OnChanges} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { NewsletterService } from 'src/app/service/newsletter.service';
import * as moment from 'moment';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-newsletter-page',
  templateUrl: './newsletter-page.component.html',
  styleUrls: ['./newsletter-page.component.scss']
})
export class NewsletterPageComponent implements OnChanges {

  newsletter: any;
  newsletterParamsReady: any;
  timeStart: any;
  timeEnd: any;
  timeRange: any;

  constructor(
    private router: Router,
    public auth: AuthService,
    public newsletterService: NewsletterService
  ) {
    this.timeEnd = moment().format('MMMM Do, YYYY');
    this.timeStart = moment().subtract(7, 'days').format('MMMM Do, YYYY');
    this.timeRange = this.timeStart + ' - ' + this.timeEnd;
  }

  ngOnChanges() {
    this.newsletter = this.newsletterService.newsletter;
    this.newsletterParamsReady = this.newsletterService.newsletterParamsReady;
  }

  iInclude(value) {
    if (this.newsletterService.newsletter['contents']) {
      if (value === 'chart') {
        return this.newsletterService.newsletter['contents'].join().includes(value);
      } else {
        return this.newsletterService.newsletter['contents'].includes(value);
      }
    }
    return false;
  }
  
  getScoreType() {
    const chart = this.newsletterService.newsletter['contents'].filter(el => el.includes('chart'))[0].split('_');
    chart.pop();
    return chart.join('_');
  }

}
