import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { yukka, dev } from 'src/app/cockpit/main-news/news-board/hierarchy';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/service/config.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';

/**
* redirect to specific hierarchies if no one is selected
*/

@Injectable({
  providedIn: 'root'
})
export class MarketGuard implements CanActivate {
  constructor(
    private router: Router,
    public config: ConfigService,
    public auth: AuthService,
    private yukkapi: YukkApi,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    if ((state.url.split('?')[0] === '/cockpit/news/market/main') && (this.auth.featureFlags.defaultMarketView !== 'main')) {
      this.router.navigate(['/cockpit/news/market/' + this.auth.featureFlags.defaultMarketView], {
        replaceUrl: true
      });
    }
    if (!state.root.queryParams.id) {
      let hierarchies = yukka;
      if (this.config.appConfig.environment === 'dev') { hierarchies = dev; }
      this.router.navigate([state.url.split('?')[0]], {
        queryParams: {
          type: hierarchies[0].type,
          id: hierarchies[0].id,
          time: state.root.queryParams.time,
          filter: state.root.queryParams.filter,
          lang: state.root.queryParams.lang ? state.root.queryParams.lang : 'en,de',
          feed: state.root.queryParams.feed ? state.root.queryParams.feed : 'non_professional,online,print,professional',
          ranks: state.root.queryParams.ranks,
          // categories: state.root.queryParams.categories ? state.root.queryParams.categories : 'gent,gedu,ciprof,glife,cmarkr,gpersf,gsci,gspo,gwea',
          categories: state.root.queryParams.categories ? state.root.queryParams.categories : '',
          continents: state.root.queryParams.continents,
          countries: state.root.queryParams.countries,
          event_ids: state.root.queryParams.event_ids,
          tutorial: state.root.queryParams.tutorial,
          sort: 'trend',
        },
        // replaceUrl: true
      });
    }
    const marketPortfolios = ['industry', 'supersector', 'sector', 'subsector'];
    if (state.root.queryParams.id && state.root.queryParams.type && (marketPortfolios.includes(state.root.queryParams.type))) {
      return this.yukkapi.getTopRelatedEntities(state.root.queryParams.type, state.root.queryParams.id, 1000).pipe(map(res => {
        const marketPortfolio = res.approximation.map(el => {
          return (el.type + ':' + el.alpha_id);
        });
        this.auth.folio = {
          content: marketPortfolio,
          contentInfo: res.approximation,
          marketPortfolio: true,
          marketPortfolioName: res.entity.name
        };
        return true;
      }), catchError(() => {
        this.auth.folio = {
          content: [],
          contentInfo: [],
          marketPortfolio: true,
          marketPortfolioName: ''
        };
        return of(true);
      }));
    } else {
      this.auth.folio = undefined;
      return true;
    }
  }
}
