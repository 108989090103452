<div class="treemap-options">

  <div class="menuabove-options">

    <!--<div *ngIf="routing.isScorelab()" class="filter info-label">BETA</div>-->

    <div class="filter" matTooltip="Help" matTooltipPosition="below" (click)="openHelp()">
      <mat-icon>help</mat-icon>
    </div>

    <div class="filter theme" [matMenuTriggerFor]="menutheme" matTooltip="Theme" matTooltipPosition="below" *ngIf="auth.featureFlags.showTheme">
      <mat-icon>invert_colors</mat-icon>
      <span class="txt">{{itheme}}</span>
    </div>
    <mat-menu #menutheme="matMenu">
      <button class="btnfilter" mat-menu-item [routerLink]="[]" [queryParams]="{ theme: 'dark'}"
              queryParamsHandling="merge" [class.active]="itheme=='dark'">
        <mat-icon [style.color]="'black'">radio_button_checked</mat-icon>
        <span class="txt">Dark</span>
      </button>
      <button class="btnfilter" mat-menu-item [routerLink]="[]" [queryParams]="{ theme: 'light'}"
              queryParamsHandling="merge" [class.active]="itheme=='light'">
        <mat-icon [style.color]="'light'">radio_button_checked</mat-icon>
        <span class="txt">Light</span>
      </button>
    </mat-menu>

    <div class="filter fullscreen" [class.active]="fullscreen" (click)="goFullscreen()" matTooltip="Fullscreen" matTooltipPosition="below" *ngIf="auth.featureFlags.showFullscreen && !routing.isIframe()">
      <mat-icon>fullscreen</mat-icon>
    </div>

    <button mat-icon-button class="menumob" [matMenuTriggerFor]="menumain"
            *ngIf="routing.isIframe() || routing.isMobile()">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #menumain="matMenu">
      <button *ngIf="!routing.isHackathon()" mat-menu-item [matMenuTriggerFor]="timeframe">Time</button>
      <button *ngIf="!routing.isHackathon()" mat-menu-item [matMenuTriggerFor]="sentiment">Sentiment</button>
      <button *ngIf="!routing.isHackathon()" mat-menu-item [matMenuTriggerFor]="language">Lang</button>
      <button *ngIf="!routing.isHackathon()" mat-menu-item [matMenuTriggerFor]="newsfeed">News</button>
      <button *ngIf="!routing.isHackathon()" mat-menu-item [matMenuTriggerFor]="sortby">Sort</button>
      <button mat-menu-item [matMenuTriggerFor]="theme">Theme</button>
      <button *ngIf="!routing.isHackathon()" mat-menu-item (click)="goHackathon()">Hackathon</button>
    </mat-menu>
    <mat-menu #timeframe="matMenu">
      <button mat-menu-item (click)="goTime(1)">Day</button>
      <button mat-menu-item (click)="goTime(7)">Week</button>
      <button mat-menu-item (click)="goTime(30)">Month</button>
      <button mat-menu-item (click)="goTime(90)">Quarter</button>
      <button mat-menu-item (click)="goCustom()">Custom</button>
    </mat-menu>
    <mat-menu #sentiment="matMenu">
      <button mat-menu-item (click)="goSentiment('all')">All</button>
      <button mat-menu-item (click)="goSentiment('pos')">Positive</button>
      <button mat-menu-item (click)="goSentiment('neu')">Neutral</button>
      <button mat-menu-item (click)="goSentiment('neg')">Negative</button>
    </mat-menu>
    <mat-menu #language="matMenu">
      <button mat-menu-item (click)="inLang('multi')">Multi</button>
      <button mat-menu-item (click)="inLang('en')">English</button>
      <button mat-menu-item (click)="inLang('de')">German</button>
      <button *ngIf="!routing.isProd() && !routing.isScorelab()" mat-menu-item (click)="inLang('ru')">Russian</button>
      <button *ngIf="!routing.isProd() && !routing.isScorelab()" mat-menu-item (click)="inLang('zh')">Chinese</button>
    </mat-menu>
    <mat-menu #newsfeed="matMenu">
      <button mat-menu-item (click)="goFeed(null)">All sources</button>
      <button mat-menu-item (click)="goFeed('professional')">Pro (Full Text)</button>
      <button mat-menu-item (click)="goFeed('print')">Print (Full Text)</button>
      <button mat-menu-item (click)="goFeed('online')">Web (Finance)</button>
      <button mat-menu-item (click)="goFeed('non_professional')">Web (Global)</button>
    </mat-menu>
    <mat-menu #sortby="matMenu">
      <button mat-menu-item (click)="goSortBy(null)">Senti</button>
      <button mat-menu-item (click)="goSortBy('abc')">Abc</button>
      <button mat-menu-item (click)="goSortBy('trend')">Trend</button>
    </mat-menu>
    <mat-menu #theme="matMenu">
      <button mat-menu-item (click)="inTheme('dark')">Dark</button>
      <button mat-menu-item (click)="inTheme('light')">Light</button>
    </mat-menu>

  </div>
</div>

