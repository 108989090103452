<div class="newsletter-page">

    <div class="main-content-2" *ngIf="newsletterService.newsletterParamsReady">

        <mat-form-field appearance="fill">
            <mat-select [(value)]="selectedDisplayable" (selectionChange)="onSelectionChange()">
                <mat-option *ngFor="let displayable of newsletterService.newsletter.dispObjects; index as index;" [value]="index">
                    <span *ngIf="displayable.type === 'entity'">{{'Entity: ' + displayable.view}}</span>
                    <span *ngIf="displayable.type === 'portfolio'">{{'Portfolio: ' + displayable.view}}</span>
                    <span *ngIf="displayable.type === 'search_query'">{{'Query: ' + displayable.view}}</span>
                    <span *ngIf="displayable.type === 'search_query_group'">{{'Collection: ' + displayable.view}}</span>
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-news-newsfeed></app-news-newsfeed>

    </div>

</div>

