<div class="query-editor">
  <mat-form-field appearance="outline" class="inptname">
    <mat-label>Name</mat-label>
    <input class="query-name" matInput [formControl]="namequery" autocomplete="off">
    <ng-container *ngIf="!data">
      <mat-icon class="query-import" [matMenuTriggerFor]="menuImport">more_vert</mat-icon>
      <mat-menu #menuImport="matMenu">
        <button mat-menu-item (click)="fileInput.click()">Import query from file</button>
        <input #fileInput class="inptfile" type="file" accept=".json" value="" (click)="fileInput.value = null" (change)="importQuery($event)"/>
      </mat-menu>
    </ng-container>
  </mat-form-field>
  <mat-form-field appearance="outline" class="inptname">
    <mat-label>Collection</mat-label>
    <mat-select [(ngModel)]="selectedCollection" multiple>
      <mat-option *ngFor="let collection of auth.querys" [value]="collection.uid" [disabled]="collection.content.length >= auth.featureFlags.queriesPerCollectionsLimit">
        {{collection.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div mat-dialog-content>
    <form [formGroup]="queryForm">
      <div class="query-bello">
        <app-query-editor-group [data]="{index:0,form:queryForm,level:0}"></app-query-editor-group>
      </div>
    </form>
  </div>
  <div mat-dialog-actions>
    <ng-container *ngIf="idelete==0">
      <button *ngIf="data" mat-raised-button color="warn" class="btndele" (click)="idelete=1">
        <mat-icon>delete</mat-icon>
        <span>delete</span>
      </button>
      <button *ngIf="data" mat-raised-button color="primary" class="btnsave" (click)="iSave(true)" [disabled]="!queryForm.valid" [class.hide]="idelete">
        <mat-icon>update</mat-icon>
        <span>update</span>
      </button>
      <button *ngIf="data" mat-raised-button color="primary" class="btnsave" (click)="iSave(false)" [disabled]="!queryForm.valid" [class.hide]="idelete">
        <mat-icon>file_copy</mat-icon>
        <span>duplicate</span>
      </button>
      <button *ngIf="!data" mat-raised-button color="primary" class="btnsave" (click)="iSave(false)" [disabled]="!queryForm.valid" [class.hide]="idelete">
        <mat-icon>save_alt</mat-icon>
        <span>save</span>
      </button>
      <button mat-raised-button color="primary" class="btnsave" (click)="iExport()" [disabled]="!queryForm.valid" [class.hide]="idelete">
        <mat-icon>save</mat-icon>
        <span>export</span>
      </button>
    </ng-container>
    <ng-container *ngIf="idelete==1">
      <button mat-raised-button color="primary" class="btnsave" (click)="idelete=0">
        <mat-icon>save_alt</mat-icon>
        <span>ABORT</span>
      </button>
      <button mat-raised-button color="warn" class="btndele" (click)="iDelete()">
        <mat-icon>delete</mat-icon>
        <span>CONFIRM</span>
      </button>
    </ng-container>
  </div>
</div>
