import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

/**
* This commponet display the general feedback of the users.
*/

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent {

  /**
  * list of column to display
  */
  displayedColumns: string[] = ['action', 'project', 'email', 'time', 'message'];

  /**
  * angular material table 
  */
  dataSource = new MatTableDataSource();

  /**
  * constructor
  */
  constructor() { }

  /**
  * delete specific report
  */
  inDelete(element) {
    if (window.confirm('Do you want delete this feedback?')) {
    }
  }

}
