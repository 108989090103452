<div class="treemap-filters">

  <div class="newsfilter">

    <div class="filter saved" [class.active]="isFilterActive" [matMenuTriggerFor]="menuFilter" (onMenuClose)="resetFilterOptions()" matTooltip="Filter" matTooltipPosition="below">
      <mat-icon>filter_list</mat-icon>
    </div>
    <mat-menu #menuFilter="matMenu" xPosition="before">
      <div class="menuFilterClass" (click)="$event.stopPropagation()">

        <div class="list-container">
          <mat-selection-list #presetsSelectionList disableRipple="true" class="listPresets" [formControl]="menuFilterPresets" (selectionChange)="handleSingleSelection($event, 'preset');">
            <h3 matSubheader>
              <button class="help" mat-button matTooltip="Help" matTooltipPosition="below">
                <mat-icon (click)="openHelp()">help</mat-icon>
              </button>
              <span class="first-column-header">Filters</span>
              <button mat-button matTooltip="Save filter" matTooltipPosition="below">
                <mat-icon (click)="savePreset()">save_alt</mat-icon>
              </button>
            </h3>
            <mat-list-option *ngFor="let preset of filterPresets" [value]="preset.value" (click)="$event.stopPropagation();">
              <div class="filter-preset" (mouseenter)="onMouseEnter(preset)" (mouseleave)="onMouseLeave(preset)">
                <div class="filter-actions">
                  <mat-icon *ngIf="preset.label === 'Default'" [class.hidden]="idCustomDefault === undefined" (click)="$event.stopPropagation(); resetDefaultPreset();" matTooltip="Reset default filter">delete_forever</mat-icon>
                  <mat-icon *ngIf="(preset.label !== 'Default') && (preset.label !== 'E events') && (preset.label !== 'S events') && (preset.label !== 'G events')" [class.hidden]="(!preset.visible) || (preset.label === nameCustomDefault)" (click)="$event.stopPropagation(); delPreset(preset);" matTooltip="Delete filter">close</mat-icon>
                  <mat-icon *ngIf="preset.label !== 'Default'" [class.hidden]="(!preset.visible) || (preset.label === nameCustomDefault)" (click)="$event.stopPropagation(); setDefaultPreset(preset);" matTooltip="Set as a default filter">star_border</mat-icon>
                  <mat-icon *ngIf="preset.label !== 'Default'" [class.hidden]="(preset.label !== nameCustomDefault)" (click)="$event.stopPropagation(); resetDefaultPreset();" matTooltip="Remove from default filter">star</mat-icon>
                </div>
                <div class="filter-text">
                  <span *ngIf="preset.label === 'Default'" [matTooltip]="nameCustomDefault" matTooltipPosition="right">{{preset.label}}</span>
                  <span *ngIf="preset.label !== 'Default'">{{preset.label}}</span>
                </div>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listTimeframes" [formControl]="menuFilterTimeframe" (selectionChange)="handleSingleSelection($event, 'timeframe');">
            <h3 matSubheader>Timeframe</h3>
            <mat-list-option *ngFor="let timeframe of filterTimeframe" [value]="timeframe.value" (click)="$event.stopPropagation(); handleCustomTimeframeSelection(timeframe.value);">
              <div class="filter-text">
                <span>{{timeframe.label}}</span>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listSentiments" [formControl]="menuFilterSentiment" (selectionChange)="handleSingleSelection($event, 'sentiment');">
            <h3 matSubheader>Sentiment</h3>
            <mat-list-option *ngFor="let sentiment of filterSentiment" [value]="sentiment.value" (click)="$event.stopPropagation();">
              <div class="filter-text">
                <span>{{sentiment.label}}</span>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listLanguages" [formControl]="menuFilterLang" (selectionChange)="handleNoSelection()">
            <h3 matSubheader>Language</h3>
            <mat-list-option *ngFor="let language of filterLang" [value]="language.value" (click)="$event.stopPropagation();">
              <div class="filter-text">
                <span>{{language.label}}</span>
                <span class="info-label" *ngIf="!['en', 'de'].includes(language.value)">BETA</span>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>

        <!--<div class="list-container">
          <mat-selection-list disableRipple="true" class="listRanks" [formControl]="menuFilterRanks" (selectionChange)="handleNoSelection()">
            <h3 matSubheader>Ranks</h3>
            <mat-list-option *ngFor="let rank of filterRanks" [value]="rank.value" (click)="$event.stopPropagation();">
              <div class="filter-text">
                <span>{{rank.label}}</span>
              </div>
            </mat-list-option>
          </mat-selection-list>
        </div>-->

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listGeography" [formControl]="menuFilterGeography">
            <h3 matSubheader>Geography</h3>
            <form [formGroup]="geographyForm" (click)="$event.stopPropagation()">
              <mat-form-field>
                <input type="text"
                       matInput
                       placeholder="Search..."
                       formControlName="geographyGroup"
                       [matAutocomplete]="autoGroup">
                <mat-autocomplete class="distinct-panel" #autoGroup="matAutocomplete">
                  <mat-optgroup *ngFor="let group of geographyGroupOptions | async" [label]="group.type">
                    <mat-option *ngFor="let name of group.names" [value]="name" (click)="handleSearch(name, group.type)">
                      {{name}}
                    </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>
            </form>
            <ng-container *ngFor="let continent of filterGeography.continents">
              <mat-list-option *ngIf="continent.selected" [class.active]="continent.selected" [value]="continent.value" (click)="$event.stopPropagation(); handleSelection(continent);">
                <div class="filter-text">
                  <span>{{continent.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngFor="let country of filterGeography.countries">
              <mat-list-option *ngIf="country.selected" [class.active]="country.selected" [value]="country.value" (click)="$event.stopPropagation(); handleSelection(country);">
                <div class="filter-text">
                  <span>{{country.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngFor="let continent of filterGeography.continents">
              <mat-list-option *ngIf="!continent.selected" [class.active]="continent.selected" [value]="continent.value" (click)="$event.stopPropagation(); handleSelection(continent);">
                <div class="filter-text">
                  <span>{{continent.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngFor="let country of filterGeography.countries">
              <mat-list-option *ngIf="!country.selected" [class.active]="country.selected" [value]="country.value" (click)="$event.stopPropagation(); handleSelection(country);">
                <div class="filter-text">
                  <span>{{country.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        </div>

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listCategories" [formControl]="menuFilterCategories">
            <h3 matSubheader>Categories</h3>
            <ng-container *ngFor="let category of filterCategories">
              <mat-list-option *ngIf="category.selected" [class.active]="category.selected" [value]="category.value" (click)="$event.stopPropagation(); handleSelection(category);">
                <div class="filter-text">
                  <span>{{category.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
            <ng-container *ngFor="let category of filterCategories">
              <mat-list-option *ngIf="!category.selected" [class.active]="category.selected" [value]="category.value" (click)="$event.stopPropagation(); handleSelection(category);">
                <div class="filter-text">
                  <span>{{category.label}}</span>
                </div>
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        </div>

        <div class="list-container">
          <mat-selection-list disableRipple="true" class="listEvents" [formControl]="menuFilterEvents">
            <h3 matSubheader>Events</h3>
            <ng-container *ngFor="let event of filterEvents|orderBy:'label'">
              <div class="event-list-option">
                <mat-expansion-panel class="mat-elevation-z0" [@.disabled]="true" (click)="$event.stopPropagation();" [togglePosition]="'before'">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <div class="filter-text">
                        <span>{{event.label}}</span>
                      </div>
                    </mat-panel-title>
                    <mat-panel-description>
                      <div class="list-container">
                        <div class="subevent-selection-list">
                          <ng-container *ngFor="let subevent of filterSubEvents[event.value]|orderBy:'label'">
                            <mat-list-option *ngIf="subevent.selected" [class.active]="subevent.selected" [value]="subevent.value" (click)="$event.stopPropagation(); handleSelection(subevent);">
                              <div class="subevent-filter-text">
                                <span>{{subevent.label}}</span>
                              </div>
                            </mat-list-option>
                          </ng-container>
                        </div>
                      </div>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div class="list-container">
                    <div class="subevent-selection-list">
                      <ng-container *ngFor="let subevent of filterSubEvents[event.value]|orderBy:'label'">
                        <mat-list-option *ngIf="!subevent.selected" [class.active]="subevent.selected" [value]="subevent.value" (click)="$event.stopPropagation(); handleSelection(subevent);">
                          <div class="subevent-filter-text">
                            <span>{{subevent.label}}</span>
                          </div>
                        </mat-list-option>
                      </ng-container>
                    </div>
                  </div>
                </mat-expansion-panel>
              </div>
            </ng-container>
          </mat-selection-list>
        </div>

      </div>

      <div class="menu-filters-footer">
        <button mat-button (click)="setFilterOptions()">Apply Filter</button>
      </div>

    </mat-menu>

    <div class="saved filters-bar">
      <div class="txt" matTooltip="Click to remove filter or switch to default" matTooltipPosition="below">
        <ng-container *ngFor="let timeframe of filterTimeframe">
          <span *ngIf="menuFilterTimeframe.value.includes(timeframe.value)" matTooltip="Timeframe" matTooltipPosition="right" (click)="removeFilter('timeframe', timeframe.value)">{{timeframe.label}}</span>
        </ng-container>
        <span>&bull;</span>
        <ng-container *ngFor="let sentiment of filterSentiment">
          <span *ngIf="menuFilterSentiment.value.includes(sentiment.value)" matTooltip="Sentiment" matTooltipPosition="right" (click)="removeFilter('sentiment', sentiment.value)">{{(sentiment.label === 'All') ? 'all sentiments' : sentiment.label}}</span>
        </ng-container>
        <ng-container *ngIf="(menuFilterLang.value !== null) && (menuFilterLang.value.length !== 0)">
          <span>&bull;</span>
          <ng-container *ngFor="let language of filterLang">
            <span *ngIf="menuFilterLang.value.includes(language.value)" matTooltip="Language" matTooltipPosition="right" (click)="removeFilter('language', language.value)">{{language.label}}</span>
          </ng-container>
        </ng-container>
        <!--<ng-container *ngIf="(menuFilterLang.value !== null) && (menuFilterLang.value.length !== 0) && (menuFilterLang.value.length === filterLang.length)">
          <span>&bull;</span>
          <span matTooltip="Language" matTooltipPosition="right">all languages</span>
        </ng-container>-->
        <!--<ng-container *ngIf="(menuFilterRanks.value !== null) && (menuFilterRanks.value.length !== 0)">
          <span>&bull;</span>
          <ng-container *ngFor="let rank of filterRanks">
            <span *ngIf="menuFilterRanks.value.includes(rank.value)" matTooltip="Rank" matTooltipPosition="right" (click)="removeFilter('rank', rank.value)">{{rank.label}}</span>
          </ng-container>
        </ng-container>-->
        <!--<ng-container *ngIf="(menuFilterCategories.value !== null) && (menuFilterCategories.value.length !== 0)">
          <span>&bull;</span>
          <ng-container *ngFor="let category of filterCategories">
            <span *ngIf="menuFilterCategories.value.includes(category.value)" matTooltip="Category" matTooltipPosition="right" (click)="removeFilter('category', category.value)">{{category.label}}</span>
          </ng-container>
        </ng-container>-->
        <ng-container *ngIf="(menuFilterGeography.value !== null) && (menuFilterGeography.value.length !== 0)">
          <span>&bull;</span>
          <ng-container *ngFor="let continent of filterGeography.continents">
            <span *ngIf="menuFilterGeography.value.includes(continent.value)" matTooltip="Continent" matTooltipPosition="right" (click)="removeFilter('continent', continent.value)">{{continent.label}}</span>
          </ng-container>
          <ng-container *ngFor="let country of filterGeography.countries">
            <span *ngIf="menuFilterGeography.value.includes(country.value)" matTooltip="Country" matTooltipPosition="right" (click)="removeFilter('country', country.value)">{{country.label}}</span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="(menuFilterEvents.value !== null) && (menuFilterEvents.value.length !== 0)">
          <span>&bull;</span>
          <ng-container *ngFor="let event of filterEvents">
            <ng-container *ngFor="let subevent of filterSubEvents[event.value]">
              <span *ngIf="menuFilterEvents.value.includes(subevent.value)" matTooltip="Event" matTooltipPosition="right" (click)="removeFilter('subevent', subevent.value)">{{subevent.label}}</span>
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="tagFilter">
          <span>&bull;</span>
          <span class="active" *ngIf="tagFilter" matTooltip="Tag" matTooltipPosition="right" (click)="removeFilter('tag', tagFilter)">{{tagFilter.split(':')[2]}}</span>
        </ng-container>
        <ng-container *ngIf="participantFilter">
          <span>&bull;</span>
          <span class="active" *ngIf="participantFilter" matTooltip="Participant" matTooltipPosition="right" (click)="removeFilter('participant', participantFilter)">{{participantFilter.split(':')[1]}}</span>
        </ng-container>
      </div>
    </div>

    <!--<div class="filter saved separator">
      <mat-icon>play_arrow</mat-icon>
    </div>-->

  </div>
</div>
