import { Component, ElementRef, ViewChild, AfterViewChecked, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Form } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from 'src/app/service/yukkapi.service';
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';

/**
 * this component is display in the company page it display the sentiment, colume, event, peergroup, etc...
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-chartmap-company',
  templateUrl: './chartmap-company.component.html',
  styleUrls: ['./chartmap-company.component.scss']
})
export class ChartmapCompanyComponent implements AfterViewChecked {

  @ViewChild('expansionContainer') expansionContainer: ElementRef;
  contentHeight: number;

  /**
   * list of perrgeoup company
   */
  peergroup: any;

  /**
   * columns to display
   */
  ifilter: any;

  /**
   * query parameters
   */
  qparam: any;

  /**
   * company data info
   */
  entity: any;

  /**
   * list of tags
   */
  topicloud: any;

  /**
   * treemap of the company
   */
  treemapsort: any;

  /**
   * query parameters refresh
   */
  previousValue: any;

  /**
   * columns array
   */
  array_filter = ['Events', 'Topic List', 'Peergroup Comparison', 'Entity'];

  /**
   * active tag
   */
  tagword: string;

  /**
   * array of events
   */
  events: any;

  /**
   * array of score events
   */
  scoreEvents: any;

  /**
   * array of flag events
   */
  flagWarningEvents: any;

  /**
   * array of flag events
   */
  flagCautionEvents: any;

  showWarningFlag = false;
  showCautionFlag = false;

  /**
   * array of top esg events
   */
  scoreEventsESG: any;

  /**
   * array of environmental score events
   */
  scoreEventsE: any;

  /**
   * array of social score events
   */
  scoreEventsS: any;

  /**
   * array of governance score events
   */
  scoreEventsG: any;

  /**
   * current E/S/G scores
   */
  scoresESG = {
    scoreESG: null,
    scoreE: null,
    scoreS: null,
    scoreG: null,
    scoreLowest: ''
  };

  /**
   * when there is no data for a particular mosaic
   */
  nodata = {};

  /**
   * loading mosaic
   */
  loading = {};

  /**
   * when a particular mosaic is ready
   */
  iready = {};

  /**
   * project based on config file
   */
  project: any;

  /**
   * is one of the scores focused
   */
  fromScoreFocus: boolean;

  sentimentEventsCount = null;
  sentimentEventsCountAll = null;
  sentimentEventsTimeframe = null;
  scoreEventsCount = null;
  scoreEventsCountAll = null;
  scoreEventsTimeframe = null;
  flagWarningEventsCount = null;
  flagWarningEventsCountAll = null;
  flagWarningEventsTimeframe = null;
  flagCautionEventsCount = null;
  flagCautionEventsCountAll = null;
  flagCautionEventsTimeframe = null;

  /**
   * query parameters subscribe and get all the data: treemap, events, tagcloud, etc...
   */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
    public config: ConfigService,
    private cdr: ChangeDetectorRef
  ) {

    this.auth.showWarningFlag$.subscribe((value) => setTimeout(() => this.showWarningFlag = value, 0));

    this.auth.showCautionFlag$.subscribe((value) => setTimeout(() => this.showCautionFlag = value, 0));

    this.project = this.config.appConfig.routing.project;

    this.route.queryParams.subscribe(qparam => {

      if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
        this.array_filter = ['Score Events', 'Topic List', 'Peergroup Comparison', 'Events', 'Entity'];
      }

      this.qparam = JSON.parse(JSON.stringify(qparam));

      this.fromScoreFocus = ((routing.isChart() && (routing.isFolio() || routing.isMarket())) && qparam?.score);
      if (this.fromScoreFocus) {
        this.qparam.time = this.auth.scoreFocusedTimeframe;
      }

      this.tagword = decodeURI(qparam.tag).split('|')[0];
      if (this.routing.reFresh(this.qparam, this.previousValue, ['update', 'updateFlag', 'updatePayload', 'type', 'id', 'isin', 'time', 'newsday', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'period', 'score_type'])) {
        // this.nodata = false;
        // this.loading = true;
        // this.iready = false;
        this.array_filter.forEach(item => {
          this.nodata[item] = false;
          this.loading[item] = true;
          this.iready[item] = false;
        });

        this.nodata['Flag Events'] = {};
        this.loading['Flag Events'] = {};
        this.iready['Flag Events'] = {};
        this.nodata['Flag Events']['Warning'] = false;
        this.loading['Flag Events']['Warning'] = true;
        this.iready['Flag Events']['Warning'] = false;
        this.nodata['Flag Events']['Caution'] = false;
        this.loading['Flag Events']['Caution'] = true;
        this.iready['Flag Events']['Caution'] = false;

        this.peergroup = undefined;
        this.entity = {
          currency: '',
          logo: '',
          price: '',
          price_change: '',
          price_change_diff: '',
        };

        if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !this.routing.isQuery() && !this.routing.isSearch() && !(this.qparam.type === 'pne') && !(this.qparam.type === 'hierarchy')) {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Score Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Score Events', 'Topic List', 'Peergroup Comparison']);
            }
            if (localStorage.getItem('score_company_filter') != null) {
              const score_company_filter = localStorage.getItem('score_company_filter').split(',');
              this.ifilter.setValue(score_company_filter);
            }
          } else {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Score Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Score Events', 'Topic List']);
            }
            if (localStorage.getItem('score_mosaics_filter') != null) {
              const score_mosaics_filter = localStorage.getItem('score_mosaics_filter').split(',');
              this.ifilter.setValue(score_mosaics_filter);
            }
          }

        } else {

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !this.routing.isQuery() && !this.routing.isSearch() && !(this.qparam.type === 'pne') && !(this.qparam.type === 'hierarchy')) {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Events', 'Topic List', 'Peergroup Comparison']);
            }
            if (localStorage.getItem('company_filter') != null) {
              const company_filter = localStorage.getItem('company_filter').split(',');
              this.ifilter.setValue(company_filter);
            }
          } else {
            if (this.routing.isMobile()) {
              this.ifilter = new UntypedFormControl(['Events']);
            } else {
              this.ifilter = new UntypedFormControl(['Events', 'Topic List']);
            }
            if (localStorage.getItem('mosaics_filter') != null) {
              const mosaics_filter = localStorage.getItem('mosaics_filter').split(',');
              this.ifilter.setValue(mosaics_filter);
            }
          }

        }

        if (this.qparam.mosaic === 'scoreevents') {
          this.ifilter = new UntypedFormControl(['Score Events']);
        }
        if (this.qparam.mosaic === 'events') {
          this.ifilter = new UntypedFormControl(['Events']);
        }
        if (this.qparam.mosaic === 'peergroup') {
          this.ifilter = new UntypedFormControl(['Peergroup Comparison']);
        }
        if (this.qparam.mosaic === 'topic') {
          this.ifilter = new UntypedFormControl(['Topic List']);
        }
        if (this.qparam.mosaic === 'entity') {
          this.ifilter = new UntypedFormControl(['Entity']);
        }

        // TOPICLOUD ---------------------------------------------

        this.yukkApi.tagcloud(this.qparam).subscribe(result => {
          this.topicloud = result.tagcloud;
          this.nodata['Topic List'] = false;
          if (this.topicloud.length > 0) {
            this.iready['Topic List'] = true;
          } else {
            this.iready['Topic List'] = false;
            this.loading['Topic List'] = false;
          }
        }, error => {
          this.nodata['Topic List'] = true;
          this.loading['Topic List'] = false;
        });

        // FLAG EVENTS ---------------------------------------

        if (this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type !== 'sentiment') && (this.auth.scorelabSettings.defaultFlag.score_type !== 'volume') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultFlag.score_type) && (this.auth.scorelabSettings.defaultFlag.score_type !== this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') || ((this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') {
            this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
              requestScore: this.auth.scorelabSettings.defaultFlag.score_type
            }), 'chart').subscribe(result2 => {

              const crScore = (result2['score_ts'][result2['score_ts'].length - 1]['score'] !== null) ? result2['score_ts'][result2['score_ts'].length - 1]['score'] : null;

              forkJoin([
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart'),
                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null,
                  time: this.checkLongTimeframe2() ? 465 : (this.qparam.time ? this.qparam.time : null),
                  requestScore: this.auth.scorelabSettings.defaultFlag.score_type
                }), 'chart')]).subscribe(result => {
                const resCopy = JSON.parse(JSON.stringify(result));
                this.setFlagEventsInfo(resCopy[0], 'warning');
                this.setFlagEventsInfo(resCopy[1], 'caution');
                this.flagWarningEvents = result[0]['top_events'] ? result[0]['top_events'] : [];
                let nonEmptyWarning = false;
                this.flagWarningEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmptyWarning = true;
                  }
                });
                this.flagCautionEvents = result[1]['top_events'] ? result[1]['top_events'] : [];
                let nonEmptyCaution = false;
                this.flagCautionEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmptyCaution = true;
                  }
                });
                this.nodata['Flag Events']['Warning'] = false;
                this.nodata['Flag Events']['Caution'] = false;
                if (nonEmptyWarning) {
                  this.iready['Flag Events']['Warning'] = true;
                } else {
                  this.iready['Flag Events']['Warning'] = false;
                  this.loading['Flag Events']['Warning'] = false;
                }
                if (nonEmptyCaution) {
                  this.iready['Flag Events']['Caution'] = true;
                } else {
                  this.iready['Flag Events']['Caution'] = false;
                  this.loading['Flag Events']['Caution'] = false;
                }
              }, error => {
                this.nodata['Flag Events']['Warning'] = true;
                this.loading['Flag Events']['Warning'] = false;
                this.nodata['Flag Events']['Caution'] = true;
                this.loading['Flag Events']['Caution'] = false;
              });

            }, error => {
              this.nodata['Flag Events']['Warning'] = true;
              this.loading['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = true;
              this.loading['Flag Events']['Caution'] = false;
            });
          } else {

            forkJoin([
              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true,
                requestScore: this.auth.scorelabSettings.defaultFlag.score_type
              }), 'chart'),
              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true,
                time: this.checkLongTimeframe2() ? 465 : (this.qparam.time ? this.qparam.time : null),
                requestScore: this.auth.scorelabSettings.defaultFlag.score_type
              }), 'chart')]).subscribe(result => {
              const resCopy = JSON.parse(JSON.stringify(result));
              this.setFlagEventsInfo(resCopy[0], 'warning');
              this.setFlagEventsInfo(resCopy[1], 'caution');
              this.flagWarningEvents = result[0]['top_events'] ? result[0]['top_events'] : [];
              let nonEmptyWarning = false;
              this.flagWarningEvents.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmptyWarning = true;
                }
              });
              this.flagCautionEvents = result[1]['top_events'] ? result[1]['top_events'] : [];
              let nonEmptyCaution = false;
              this.flagCautionEvents.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmptyCaution = true;
                }
              });
              this.nodata['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = false;
              if (nonEmptyWarning) {
                this.iready['Flag Events']['Warning'] = true;
              } else {
                this.iready['Flag Events']['Warning'] = false;
                this.loading['Flag Events']['Warning'] = false;
              }
              if (nonEmptyCaution) {
                this.iready['Flag Events']['Caution'] = true;
              } else {
                this.iready['Flag Events']['Caution'] = false;
                this.loading['Flag Events']['Caution'] = false;
              }
            }, error => {
              this.nodata['Flag Events']['Warning'] = true;
              this.loading['Flag Events']['Warning'] = false;
              this.nodata['Flag Events']['Caution'] = true;
              this.loading['Flag Events']['Caution'] = false;
            });

          }

        }

        // EVENTS ---------------------------------------------

        this.yukkApi.relatedEvents(this.qparam, 'sub_events').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          this.setSentimentEventsInfo(resCopy);
          this.events = result;
          let nonEmpty = false;
          this.events.forEach(item => {
            if (item.count > 0) {
              nonEmpty = true;
            }
          });
          this.nodata['Events'] = false;
          if (nonEmpty) {
            this.iready['Events'] = true;
          } else {
            this.iready['Events'] = false;
            this.loading['Events'] = false;
          }
        }, error => {
          this.nodata['Events'] = true;
          this.loading['Events'] = false;
        });

        // SCORELAB EVENTS ---------------------------------------

        if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {

          if (this.isEsgSetup()) {

            forkJoin({
              esgEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true
              }), 'chart'),
              esgScores: this.yukkApi.scoresTimeSeries('score', this.qparam, 'chart'),
              eEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 'e_standard'
              }), 'chart'),
              eScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 'e_standard'
              }), 'chart'),
              sEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 's_standard'
              }), 'chart'),
              sScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 's_standard'
              }), 'chart'),
              gEvents: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                score_type: 'g_standard'
              }), 'chart'),
              gScores: this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                score_type: 'g_standard'
              }), 'chart')
            }).subscribe(result => {
              const resCopy = JSON.parse(JSON.stringify(result.esgEvents));
              this.setScoreEventsInfo(resCopy);
              // @ts-ignore
              this.scoreEventsESG = result.esgEvents.top_events ? result.esgEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreESG = result.esgScores.score_ts_last_score.score ? result.esgScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsE = result.eEvents.top_events ? result.eEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreE = result.eScores.score_ts_last_score.score ? result.eScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsS = result.sEvents.top_events ? result.sEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreS = result.sScores.score_ts_last_score.score ? result.sScores.score_ts_last_score.score.toFixed(1) : null;
              // @ts-ignore
              this.scoreEventsG = result.gEvents.top_events ? result.gEvents.top_events : [];
              // @ts-ignore
              this.scoresESG.scoreG = result.gScores.score_ts_last_score.score ? result.gScores.score_ts_last_score.score.toFixed(1) : null;
              this.scoresESG.scoreLowest = [
                {type: 'e', score: this.scoresESG.scoreE},
                {type: 's', score: this.scoresESG.scoreS},
                {type: 'g', score: this.scoresESG.scoreG},
              ].reduce((min, x) => min.score < x.score ? min : x).type;
              let nonEmpty = false;
              this.scoreEventsESG.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsE.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsS.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.scoreEventsG.forEach(item => {
                if (item.contrib_amount > 0) {
                  nonEmpty = true;
                }
              });
              this.nodata['Score Events'] = false;
              if (nonEmpty) {
                this.iready['Score Events'] = true;
              } else {
                this.iready['Score Events'] = false;
                this.loading['Score Events'] = false;
              }
            }, error => {
              this.nodata['Score Events'] = true;
              this.loading['Score Events'] = false;
            });

          } else {

            if (this.auth.scorelabSettings.defaultScore === 'credit_risk') {

              this.yukkApi.scoresTimeSeries('score', this.qparam, 'chart').subscribe(result => {

                const crScore = (result['score_ts'][result['score_ts'].length - 1]['score'] !== null) ? result['score_ts'][result['score_ts'].length - 1]['score'] : null;

                this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                  with_top_events: true,
                  with_all_events: true,
                  top_events_sign: (crScore && (crScore > 0)) ? 'neg' : null
                }), 'chart').subscribe(result2 => {
                  const resCopy = JSON.parse(JSON.stringify(result2));
                  this.setScoreEventsInfo(resCopy);
                  this.scoreEvents = result2.top_events ? result2.top_events : [];
                  let nonEmpty = false;
                  this.scoreEvents.forEach(item => {
                    if (item.contrib_amount > 0) {
                      nonEmpty = true;
                    }
                  });
                  this.nodata['Score Events'] = false;
                  if (nonEmpty) {
                    this.iready['Score Events'] = true;
                  } else {
                    this.iready['Score Events'] = false;
                    this.loading['Score Events'] = false;
                  }
                }, error => {
                  this.nodata['Score Events'] = true;
                  this.loading['Score Events'] = false;
                });

              }, error => {
                this.nodata['Score Events'] = true;
                this.loading['Score Events'] = false;
              });

            } else {

              this.yukkApi.scoresTimeSeries('score', Object.assign({}, this.qparam, {
                with_top_events: true,
                with_all_events: true
              }), 'chart').subscribe(result => {
                const resCopy = JSON.parse(JSON.stringify(result));
                this.setScoreEventsInfo(resCopy);
                this.scoreEvents = result.top_events ? result.top_events : [];
                let nonEmpty = false;
                this.scoreEvents.forEach(item => {
                  if (item.contrib_amount > 0) {
                    nonEmpty = true;
                  }
                });
                this.nodata['Score Events'] = false;
                if (nonEmpty) {
                  this.iready['Score Events'] = true;
                } else {
                  this.iready['Score Events'] = false;
                  this.loading['Score Events'] = false;
                }
              }, error => {
                this.nodata['Score Events'] = true;
                this.loading['Score Events'] = false;
              });

            }

          }

        }

        forkJoin({
          entity: this.yukkApi.isentiment(this.qparam, false),
          price: (this.qparam.type) ? this.yukkApi.scoresTimeSeries('price', this.qparam, 'chart') : of({prices: []}),
          treemap: !(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type)) ? this.yukkApi.treemap(this.qparam) : of(undefined)
        }).subscribe(result => {

          // ENTITY ---------------------------------------------

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
            if (result.entity.entity.media) {
              if (result.entity.entity.media.uni) {
                this.entity.logo = result.entity.entity.media.uni;
              }
            }
            this.entity.alpha_id = result.entity.entity.alpha_id;
            this.entity.compound_key = result.entity.entity.compound_key;
            this.entity.name = result.entity.entity.name;
            this.entity.sentiment = result.entity.sentiment.sentiment;
            this.entity.sentiment_delta = result.entity.sentiment.sentiment_delta;
          }

          // PRICE ---------------------------------------------

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
            if (result.price['prices'].length > 1) {
              const price1 = result.price['prices'][result.price['prices'].length - 1].price;
              const price2 = result.price['prices'][result.price['prices'].length - 2].price;
              this.entity.currency = result.price['currency'];
              this.entity.price = price1;
              this.entity.price_change = this.iNumber((price1 - price2).toFixed(2));
              this.entity.price_change_diff = this.iPercent(((price1 - price2) / price2 * 100).toFixed(2));
            }
          }

          // TREEMAP ---------------------------------------------

          if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy') && !(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) {
            const treemap = [];
            result.treemap.treemap.children.forEach(item => {
              item.children.forEach(itom => {
                treemap.push(itom);
              });
            });
            this.treemapsort = treemap.sort((a, b) => a.entity.sentiment_delta - b.entity.sentiment_delta);
          }

          this.iready['Entity'] = true;

          // PEERGROUP ---------------------------------------------

          if (['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type)) {
            const peergroupArray = this.auth.folio.contentInfo.map(el => {
              return {
                entity: el
              };
            });
            this.peergroup = peergroupArray;
            this.iready['Peergroup Comparison'] = true;
          } else if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
            const iparams = JSON.parse(JSON.stringify(this.qparam));
            if (result.entity.entity.description && result.entity.entity.description['Subsector']) {
              iparams.type = 'subsector';
              iparams.id = result.entity.entity.description['Subsector'].entity.alpha_id;
            } else if (result.entity.entity.description && result.entity.entity.description['Sector']) {
              iparams.type = 'sector';
              iparams.id = result.entity.entity.description['Sector'].entity.alpha_id;
            } else if (result.entity.entity.description && result.entity.entity.description['Industry']) {
              iparams.type = 'industry';
              iparams.id = result.entity.entity.description['Industry'].entity.alpha_id;
            } else if (result.entity.entity.description && result.entity.entity.description['Country']) {
              // iparams.type = 'country';
              // iparams.id = result.entity.entity.description['Country'].entity.alpha_id;
              iparams.type = null;
              iparams.id = null;
            } else {
              iparams.type = null;
              iparams.id = null;
            }
            if (iparams.type && iparams.id) {
              this.yukkApi.treemap(iparams).subscribe(peergroup => {
                const peergroupChildren = peergroup.treemap.children.filter(children => {
                  return (children.entity.type === 'company');
                });
                if (peergroupChildren && (peergroupChildren.length > 0)) {
                  this.peergroup = peergroupChildren.filter(item => {
                    if (item.entity) {
                      if (item.entity.alpha_id !== this.qparam.id) {
                        return item;
                      } else {
                        item.count = Infinity;
                        return item;
                      }
                    }
                  });
                  const content = this.peergroup.map(el => {
                    return el.entity.compound_key;
                  });
                  if (this.auth.scorelabSettings.defaultScore && (this.auth.scorelabSettings.defaultScore !== 'sentiment')) {
                    this.yukkApi.scoresTimeSeries('score', Object.assign({}, iparams, {
                      usePortfolio: true,
                      itemized: true,
                      requestScore: this.auth.scorelabSettings.defaultScore,
                      custom_payload: content
                    }), 'chart').subscribe(res => {
                      res.forEach(entity => {
                        if (this.entity.compound_key === entity.entity_info.compound_key) {
                          this.entity.score = entity.score_ts_last_score.score?.toFixed(1);
                        }
                        this.peergroup.forEach((item, index) => {
                          if (item.entity.compound_key === entity.entity_info.compound_key) {
                            this.peergroup[index].score = entity.score_ts_last_score.score?.toFixed(1);
                          }
                        });
                      });
                    });
                    this.iready['Peergroup Comparison'] = true;
                  } else {
                    this.iready['Peergroup Comparison'] = true;
                  }
                } else {
                  if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
                    this.array_filter = ['Score Events', 'Topic List', 'Events'];
                  } else {
                    this.array_filter = ['Events', 'Topic List'];
                  }
                  this.iready['Peergroup Comparison'] = true;
                }
              }, error => {
                this.nodata['Peergroup Comparison'] = true;
                this.loading['Peergroup Comparison'] = false;
              });
            } else {
              if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
                this.array_filter = ['Score Events', 'Topic List', 'Events'];
              } else {
                this.array_filter = ['Events', 'Topic List'];
              }
              this.iready['Peergroup Comparison'] = null;
            }
          } else {
            if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))))) && (this.routing.isFolio() || (qparam.type && ((qparam.type === 'company') || (qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))) || (qparam.isin))) {
              this.array_filter = ['Score Events', 'Topic List', 'Events'];
            } else {
              this.array_filter = ['Events', 'Topic List'];
            }
            this.iready['Peergroup Comparison'] = true;
          }

        }, error => {
          this.nodata['Peergroup Comparison'] = true;
          this.loading['Peergroup Comparison'] = false;
          this.nodata['Entity'] = true;
          this.loading['Entity'] = false;
        });
      }
      this.previousValue = this.qparam;
    });

  }

  ngAfterViewChecked() {
    if (this.expansionContainer) {
      this.contentHeight = Math.max((this.expansionContainer.nativeElement.offsetHeight / 7) - 1, 40);
      this.cdr.detectChanges();
    }
  }

  /**
   *
   */
  iNumber(value) {
    const icon = value > 0 ? '+' : '';
    return icon + value;
  }

  /**
   *
   */
  iPercent(value) {
    const icon = value > 0 ? '+' : '';
    return icon + value + '%';
  }

  /**
   *
   */
  iColor(value) {
    if (value > 0) { return '#3dac00'; }
    if (value === 0) { return '#f6d500'; }
    if (value < 0) { return '#dd001a'; }
  }

  /**
   *
   */
  iAbs(value) {
    return Math.abs(value * 100).toFixed(0);
  }

  /**
   * redirect specific entity
   */
  iEntity(type, id) {
    this.router.navigate([], {
      queryParams: {
        type: type,
        id: id,
        tag: null,
        news: null,
        eventid: null,
      }, queryParamsHandling: 'merge'
    });
  }

  /**
   * click the tagcloud
   */
  iTacloud(item) {
    const tag = this.tagword === item.tag ? null : encodeURI(item.tag) + '|' + item.word;
    this.router.navigate([], {
      queryParams: {
        tag: tag
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   *
   */
  iPerform3(value) {
    const perform = Math.round(this.entity.sentiment * 100) / 100 - Math.round(value * 100) / 100;
    let icon = '';
    if (perform < 0) { icon = 'better'; }
    if (perform === 0) { icon = 'equal'; }
    if (perform > 0) { icon = 'worse'; }
    return icon;
  }

  /**
   *
   */
  iPerform1(value) {
    const perform = value - this.entity.sentiment;
    const icon = perform > 0 ? '▲' : '▼';
    return icon;
  }

  /**
   *
   */
  iPerform2(value) {
    const perform = value - this.entity.sentiment;
    return Math.abs(perform * 100).toFixed(0);
  }

  /**
   * percentage of the event bar
   */
  iBaro(array, value) {
    const counts = array.map(event => event.count);
    const maxcount = Math.max.apply(Math, counts);
    return ((value * 100) / maxcount);
  }

  /**
   * percentage of the scorelab event bar
   */
  iBaroScore(array, value) {
    const scores = array.map(event => event.contrib_amount);
    const maxscore = Math.max.apply(Math, scores);
    return ((value * 100) / maxscore);
  }

  /**
   * click specific event
   */
  iEvent(item) {
    let eventid = null;
    let eventype = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.event.id) {
      item.expand = false;
    } else {
      item.expand = true;
      eventid = item.event.id;
      eventype = item.event.type;
    }
    this.router.navigate([], {
      queryParams: {
        eventid: eventid,
        eventype: eventype,
        newstype: newstype,
        newsday: null
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   * click specific scorelab event
   */
  iScoreEvent(item) {
    let eventid = null;
    let eventype = null;
    let scoreevent = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.id) {
      item.expand = false;
    } else {
      item.expand = true;
      eventid = item.id;
      eventype = item.type;
      scoreevent = true;
    }
    this.router.navigate([], {
      queryParams: {
        eventid: eventid,
        eventype: eventype,
        scoreevent: scoreevent,
        flagevent: null,
        newstype: newstype,
        newsday: null
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   * click specific caution flag's scorelab event
   */
  iFlagEvent(item) {
    let eventid = null;
    let eventype = null;
    let flagevent = null;
    const newstype = this.qparam.newstype ? this.qparam.newstype : ((this.qparam.type && !['industry', 'sector', 'supersector', 'subsector'].includes(this.qparam.type)) ? 'Events' : null);
    if (this.qparam.eventid === item.id) {
      item.expand = false;
    } else {
      item.expand = true;
      eventid = item.id;
      eventype = item.type;
      flagevent = true;
    }
    this.router.navigate([], {
      queryParams: {
        eventid: eventid,
        eventype: eventype,
        scoreevent: null,
        flagevent: flagevent,
        newstype: newstype,
        newsday: null
      },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

  /**
   * changelter the visualization columns
   */
  iFilter(event) {
    if (event === false) {
      if (!(this.auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultScore) && (!(this.auth.scorelabSettings.defaultScore === 'credit_risk') || ((this.auth.scorelabSettings.defaultScore === 'credit_risk') && (this.qparam.type && ((this.qparam.type === 'company') || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type))))) && (this.routing.isFolio() || (this.qparam.type && ((this.qparam.type === 'company') || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type))) || (this.qparam.isin))) {
        if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
          localStorage.setItem('score_company_filter', this.ifilter.value);
        } else {
          localStorage.setItem('score_mosaics_filter', this.ifilter.value);
        }
      } else {
        if (!(this.routing.isFolio() && !this.qparam.portfolioId) && !(this.qparam.type === 'hierarchy')) {
          localStorage.setItem('company_filter', this.ifilter.value);
        } else {
          localStorage.setItem('mosaics_filter', this.ifilter.value);
        }
      }
    }
  }

  /**
   *
   */
  iTool(value) {
    if ((value === 'Flag Events') && this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type !== 'sentiment') && (this.auth.scorelabSettings.defaultFlag.score_type !== 'volume') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(this.auth.scorelabSettings.defaultFlag.score_type) && (this.auth.scorelabSettings.defaultFlag.score_type !== this.auth.scorelabSettings.defaultScore) && this.qparam && (!(this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') || ((this.auth.scorelabSettings.defaultFlag.score_type === 'credit_risk') && (this.qparam.type && ((this.qparam.type === 'company')) || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type)))) && (this.routing.isFolio() || (this.qparam.type && ((this.qparam.type === 'company') || (this.qparam.type === 'pne') || ['industry', 'supersector', 'sector', 'subsector'].includes(this.qparam.type))) || (this.qparam.isin))) {
      if (this.showWarningFlag || this.showCautionFlag) {
        return true;
      } else {
        return false;
      }
    } else if ((value === 'Events') && this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment')) {
      if (this.showWarningFlag || this.showCautionFlag) {
        return true;
      } else {
        return this.ifilter.value.includes(value);
      }
    } else {
      return this.ifilter.value.includes(value);
    }
  }

  /**
   * get events mosaic title for current default score
   */
  scoreEventsTitle() {
    return ((this.routing.isWidget() && this.qparam.score_type) ? ((this.qparam.score_type === 'esg') ? 'ESG Events' : 'Risk Events') : (this.auth.getScoreName().name + ' Events'));
  }

  /**
   * check if ESG mosaic should be displayed
   */
  isEsgSetup() {
    return ((!this.routing.isWidget() && (this.auth.scorelabSettings.defaultScore === 'esg')) || (this.routing.isWidget() && (this.auth.scorelabSettings.defaultScore === 'esg') && !this.qparam.score_type) || (this.routing.isWidget() && (this.qparam.score_type === 'esg')));
  }

  checkTimeframe() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 100) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 365) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  checkLongTimeframe2() {
    if (this.qparam.time) {
      const days = this.yukkApi.myFromTo(this.qparam.time).period;
      if (days < 465) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setSentimentEventsInfo(sentimentEventsObj) {
    let count = 0;
    sentimentEventsObj.forEach(event => {
      count += event.count;
    });
    this.sentimentEventsCount = count;
    this.sentimentEventsCountAll = count;
    this.sentimentEventsTimeframe = this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7;
  }

  setScoreEventsInfo(scoreEventsObj) {
    let count = 0;
    scoreEventsObj.top_events?.forEach(event => {
      count += event.contrib_amount;
    });
    this.scoreEventsCount = count;
    this.scoreEventsTimeframe = 100;
    this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
      time: this.scoreEventsTimeframe
    }), 'sub_events').subscribe(result => {
      const resCopy = JSON.parse(JSON.stringify(result));
      let countEvents = 0;
      resCopy.forEach(item => {
        if (item.count > 0) {
          countEvents += item.count;
        }
      });
      this.scoreEventsCountAll = countEvents;
    });
  }

  setFlagEventsInfo(flagEventsObj, flagType) {
    if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
      let count = 0;
      flagEventsObj.forEach(event => {
        count += event.count;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
        this.flagWarningEventsCountAll = count;
        this.flagWarningEventsTimeframe = this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
        this.flagCautionEventsCountAll = count;
        this.flagCautionEventsTimeframe = this.checkLongTimeframe() ? 365 : (this.qparam.time ? this.yukkApi.myFromTo(this.qparam.time).period : 7);
      }
    } else {
      let count = 0;
      flagEventsObj.top_events?.forEach(event => {
        count += event.contrib_amount;
      });
      if (flagType === 'warning') {
        this.flagWarningEventsCount = count;
      } else if (flagType === 'caution') {
        this.flagCautionEventsCount = count;
      }
      if (flagType === 'warning') {
        this.flagWarningEventsTimeframe = 100;
      } else if (flagType === 'caution') {
        this.flagCautionEventsTimeframe = 465;
      }
      if (flagType === 'warning') {
        this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
          time: this.flagWarningEventsTimeframe
        }), 'sub_events').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          let countEvents = 0;
          resCopy.forEach(item => {
            if (item.count > 0) {
              countEvents += item.count;
            }
          });
          this.flagWarningEventsCountAll = countEvents;
        });
      } else if (flagType === 'caution') {
        this.yukkApi.relatedEvents(Object.assign({}, this.qparam, {
          time: this.flagCautionEventsTimeframe
        }), 'sub_events').subscribe(result => {
          const resCopy = JSON.parse(JSON.stringify(result));
          let countEvents = 0;
          resCopy.forEach(item => {
            if (item.count > 0) {
              countEvents += item.count;
            }
          });
          this.flagCautionEventsCountAll = countEvents;
        });
      }
    }
  }

}
