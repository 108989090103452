<div class="main-trending">
  <div class="trending-lhs" [class.mobile]="routing.isMobile()">
    <div class="trending-header">
      <div class="trending-header-content">
        <mat-form-field class="trending-search">
          <mat-icon matPrefix>search</mat-icon>
          <mat-label>Search by name or isin</mat-label>
          <input matInput [(ngModel)]="searchTerm">
        </mat-form-field>
        <mat-form-field class="trending-time">
          <mat-label>Time Horizon</mat-label>
          <mat-select [(ngModel)]="selectedTimeHorizon" name="time" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let time of time_horizon" [value]="time.value">
              {{time.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-country">
          <mat-label>Country</mat-label>
          <mat-select [(ngModel)]="selectedCountry" name="country" (selectionChange)="onSelectionChange('country')">
            <mat-select-trigger>
              {{(selectedCountry === 'all') ? selectedCountry : selectedCountry.toUpperCase()}}
            </mat-select-trigger>
            <mat-option *ngFor="let country of countries" [value]="country.value">
              {{(country.value === 'all') ? (country.value) : (country.value.toUpperCase() + ' - ' + country.label)}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-index">
          <mat-label>Index</mat-label>
          <mat-select [(ngModel)]="selectedIndex" name="index" (selectionChange)="onSelectionChange('index')">
            <mat-option *ngFor="let index of indices" [value]="index">
              {{index}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-industry">
          <mat-label>Industry</mat-label>
          <mat-select [(ngModel)]="selectedIndustry" name="industry" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let industry of industries" [value]="industry.value">
              {{industry.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="trending-trend">
          <mat-label>Bull/Bear</mat-label>
          <mat-select [(ngModel)]="selectedTrend" name="trend" (selectionChange)="onSelectionChange()">
            <mat-option *ngFor="let trend of trends" [value]="trend">
              {{trend}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!--<mat-form-field class="trending-score">
          <mat-label>ESG Rating</mat-label>
          <mat-select [(ngModel)]="selectedScores" name="score" multiple>
            <mat-select-trigger>
              {{selectedScores}}
            </mat-select-trigger>
            <mat-option *ngFor="let score of scores" [value]="score">
              {{score + ' : ' + getScoreInfo(score)}}
            </mat-option>
          </mat-select>
        </mat-form-field>-->
      </div>
    </div>
    <div class="trending-cont">
      <app-trending-column [trending]="filteredEntitiesList()" [searchTerm]="searchTerm" [loading]="loading" [nodata]="nodata"></app-trending-column>
    </div>
  </div>
  <div class="trending-rhs" *ngIf="!routing.isMobile()">
    <app-news-newsfeed></app-news-newsfeed>
  </div>
</div>
