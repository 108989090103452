import { Component, ViewChild, ViewChildren, QueryList, ElementRef, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, startWith, debounceTime } from 'rxjs/operators';
import { UntypedFormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-alerts-setup',
  templateUrl: './alerts-setup.component.html',
  styleUrls: ['./alerts-setup.component.scss']
})
export class AlertsSetupComponent implements OnInit, OnChanges {

  @ViewChild('selectorAlertSchedule', { static: false }) scheduleField: MatSelect;
  @ViewChild('selectorAlertChannel', { static: false }) channelField: MatSelect;
  @ViewChild('selectorAlertType', { static: false }) alertTypeField: MatSelect;
  @ViewChild('selectorAlertObject', { static: false }) alertObjectSelectorField: MatSelect;
  @ViewChild('inputAlertObject', { static: false, read: ElementRef }) alertObjectInputField: ElementRef;

  @ViewChildren('selectorTriggerScore') triggerScoreFields: QueryList<MatSelect>;
  @ViewChildren('selectorTriggerThresholdSetting') triggerThresholdSettingFields: QueryList<MatSelect>;
  @ViewChildren('inputTriggerThreshold') triggerThresholdFields: QueryList<ElementRef>;

  currentAlert: any;
  alerts = [];

  notitle = false;

  scoreTypes = [];

  items$: Observable<any>;
  folio$: Observable<any>;
  query$: Observable<any>;
  filteredQuery$: Observable<any>;
  collection$: Observable<any>;

  folio: any;
  query: any;
  collection: any;

  loading: boolean;

  searchFormControl = new UntypedFormControl();
  queryFormControl = new UntypedFormControl();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {

    if (this.auth.scorelabSettings.sentimentAccess) {
      this.scoreTypes.push({
        value: 'sentiment',
        label: 'Sentiment'
      });
    }
    if (this.auth.scorelabSettings.generalRiskAccess) {
      this.scoreTypes.push({
        value: 'general_risk',
        label: 'General Risk Score'
      });
    }
    if (this.auth.scorelabSettings.creditRiskAccess) {
      this.scoreTypes.push({
        value: 'credit_risk',
        label: 'Credit Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgRiskAccess) {
      this.scoreTypes.push({
        value: 'esg_risk',
        label: 'ESG Risk Score'
      });
    }
    if (this.auth.scorelabSettings.immediateRiskAccess) {
      this.scoreTypes.push({
        value: 'immediate_risk',
        label: 'Immediate Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgAccess) {
      this.scoreTypes.push({
        value: 'esg',
        label: 'ESG Score'
      });
    }
    if (this.auth.scorelabSettings.bbdAccess) {
      this.scoreTypes.push({
        value: 'bbd',
        label: 'Bull-Bear Daily Score'
      });
    }
    if (this.auth.scorelabSettings.bbwAccess) {
      this.scoreTypes.push({
        value: 'bbw',
        label: 'Bull-Bear Weekly Score'
      });
    }
    if (this.auth.scorelabSettings.bbmAccess) {
      this.scoreTypes.push({
        value: 'bbm',
        label: 'Bull-Bear Monthly Score'
      });
    }
    if (this.auth.scorelabSettings.bbqAccess) {
      this.scoreTypes.push({
        value: 'bbq',
        label: 'Bull-Bear Quarterly Score'
      });
    }
    if (this.auth.scorelabSettings.customScoreAccess && this.auth.scorelabSettings.customScores) {
      this.auth.scorelabSettings.customScores.forEach(item => {
        this.scoreTypes.push({
          value: item.uid,
          label: item.name
        });
      });
    }

    this.notitle = false;

    this.currentAlert = {
      uid: 'create',
      name: '',
      is_active: false,
      alert_type: 'entity',
      schedule: '1d',
      channel: 'digest',
      object_type: '',
      object_id: '',
      object_name: '',
      operator: 'OR',
      alerts: [
        {
          score_type: '',
          threshold: '',
          threshold_setting: ''
        }
      ]
    };
    this.auth.getMultiAlerts().subscribe(result => {
      result.forEach(item => {
        const alertObject = {
          alert_type: '',
          object_type: '',
          object_id: '',
          object_name: '',
          score_type: '',
          alerts: []
        };
        const additionalReferenceArray = item['additional_reference'].split(':');
        if (item.alerts) {
          alertObject.alert_type = additionalReferenceArray[0];
          alertObject.object_type = additionalReferenceArray[1];
          alertObject.object_id = additionalReferenceArray[2];
          if (additionalReferenceArray[0] === 'portfolio') {
            const subAlertObject = {
              score_type: '',
              threshold: '',
              threshold_setting: ''
            };
            const endpointPathArray = item.alerts[0]['endpoint_path'].split('/');
            if (endpointPathArray[4] === 'sentiment') {
              subAlertObject.score_type = 'sentiment';
            } else if (endpointPathArray[4].split('?')[0] === 'score_ts') {
              if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=general_risk') {
                subAlertObject.score_type = 'general_risk';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=esg_risk') {
                subAlertObject.score_type = 'esg_risk';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=immediate_risk') {
                subAlertObject.score_type = 'immediate_risk';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=esg') {
                subAlertObject.score_type = 'esg';
              } else {
                subAlertObject.score_type = item.alerts[0]['additional_reference'];
              }
            } else if (endpointPathArray[4].split('?')[0] === 'ml_score_ts') {
              subAlertObject.score_type = 'credit_risk';
            } else if (endpointPathArray[4].split('?')[0] === 'bull_bear_score_ts') {
              if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1D') {
                subAlertObject.score_type = 'bbd';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1W') {
                subAlertObject.score_type = 'bbw';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1M') {
                subAlertObject.score_type = 'bbm';
              } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=3M') {
                subAlertObject.score_type = 'bbq';
              } else {
                subAlertObject.score_type = item.alerts[0]['additional_reference'];
              }
            }
            subAlertObject.threshold_setting = item.alerts[0].threshold_setting;
            subAlertObject.threshold = (subAlertObject.score_type === 'sentiment') ? (parseFloat(item.alerts[0].threshold) * 100.0) : item.alerts[0].threshold;
            alertObject.alerts.push(Object.assign({}, item.alerts[0], subAlertObject));
          } else {
            item.alerts.forEach(subAlert => {
              const subAlertObject = {
                score_type: '',
                threshold: '',
                threshold_setting: ''
              };
              const endpointPathArray = subAlert['endpoint_path'].split('/');
              if (endpointPathArray[4] === 'sentiment') {
                subAlertObject.score_type = 'sentiment';
              } else if (endpointPathArray[4].split('?')[0] === 'score_ts') {
                if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=general_risk') {
                  subAlertObject.score_type = 'general_risk';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=esg_risk') {
                  subAlertObject.score_type = 'esg_risk';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=immediate_risk') {
                  subAlertObject.score_type = 'immediate_risk';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'score_type=esg') {
                  subAlertObject.score_type = 'esg';
                } else {
                  subAlertObject.score_type = subAlert['additional_reference'];
                }
              } else if (endpointPathArray[4].split('?')[0] === 'ml_score_ts') {
                subAlertObject.score_type = 'credit_risk';
              } else if (endpointPathArray[4].split('?')[0] === 'bull_bear_score_ts') {
                if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1D') {
                  subAlertObject.score_type = 'bbd';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1W') {
                  subAlertObject.score_type = 'bbw';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=1M') {
                  subAlertObject.score_type = 'bbm';
                } else if (endpointPathArray[4].split('?')[1].split('&')[0] === 'time_horizon=3M') {
                  subAlertObject.score_type = 'bbq';
                } else {
                  subAlertObject.score_type = item.alerts[0]['additional_reference'];
                }
              }
              subAlertObject.threshold_setting = subAlert.threshold_setting;
              subAlertObject.threshold = (subAlertObject.score_type === 'sentiment') ? (parseFloat(subAlert.threshold) * 100.0) : subAlert.threshold;
              alertObject.alerts.push(Object.assign({}, subAlert, subAlertObject));
            });
          }
        }
        this.alerts.push(Object.assign({}, item, alertObject));
      });
      if (this.alerts.length > 0) {
        this.currentAlert = this.alerts[0];
      }
      this.updateForms();
    });

    this.folio$ = this.auth.portFolios();
    this.query$ = this.auth.getQuery();
    this.collection$ = this.auth.getGroupsQuery();

    this.folio$.subscribe(result => {
      this.folio = result;
    });
    this.query$.subscribe(result => {
      this.query = result;
      if (this.currentAlert) {
        this.updateForms();
      }
    });
    this.collection$.subscribe(result => {
      this.collection = result;
    });

  }

  ngOnChanges() {
    if (this.currentAlert) {
      this.updateForms();
    }
  }

  selectAlert(alert) {
    this.notitle = false;
    this.currentAlert = alert;
    this.updateForms();
  }

  activateAlert(alert) {
    this.notitle = false;
    this.currentAlert = alert;
    this.saveAlert(true);
  }

  deactivateAlert(alert) {
    this.notitle = false;
    this.currentAlert = alert;
    this.saveAlert(false);
  }

  createAlert() {
    this.notitle = false;
    this.currentAlert = {
      uid: 'create',
      name: '',
      is_active: false,
      alert_type: 'entity',
      schedule: '1d',
      channel: 'digest',
      object_type: '',
      object_id: '',
      object_name: '',
      operator: 'OR',
      alerts: [
        {
          score_type: '',
          threshold: '',
          threshold_setting: ''
        }
      ]
    };
    this.updateForms();
  }

  isValidAlert(alert) {
    let isValid = true;
    if (!alert.name.trim()) {
      this.notitle = true;
      isValid = false;
    }
    if (!alert.alert_type) {
      if (this.alertTypeField) {
        this.alertTypeField.focus();
      }
      isValid = false;
    }
    if (!alert.schedule) {
      if (this.scheduleField) {
        this.scheduleField.focus();
      }
      isValid = false;
    }
    if (!alert.channel) {
      if (this.channelField) {
        this.channelField.focus();
      }
      isValid = false;
    }
    if (!alert.object_type || !alert.object_id) {
      if (this.alertObjectSelectorField) {
        this.alertObjectSelectorField.focus();
      }
      if (this.alertObjectInputField) {
        this.alertObjectInputField.nativeElement.focus();
      }
      isValid = false;
    }
    const subAlertTriggers = {
      scores: this.triggerScoreFields.toArray(),
      thresholdSettings: this.triggerThresholdSettingFields.toArray(),
      thresholds: this.triggerThresholdFields.toArray()
    };
    alert.alerts.forEach((subAlert, index) => {
      if (!subAlert.score_type) {
        subAlertTriggers.scores[index].focus();
        isValid = false;
      }
      if (!subAlert.threshold) {
        subAlertTriggers.thresholds[index].nativeElement.focus();
        isValid = false;
      }
      if (!subAlert.threshold_setting) {
        subAlertTriggers.thresholdSettings[index].focus();
        isValid = false;
      }
    });
    return isValid;
  }

  saveAlert(isActive) {
    this.notitle = false;
    if (this.isValidAlert(this.currentAlert)) {
      this.currentAlert.is_active = isActive;
      const payloadObject = {};
      payloadObject['alerts'] = [];
      let isNew;
      if (this.currentAlert.uid === 'create') {
        isNew = true;
      } else {
        isNew = false;
      }
      if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
        payloadObject['uid'] = this.currentAlert.uid;
      }
      payloadObject['is_active'] = this.currentAlert.is_active;
      payloadObject['schedule'] = this.currentAlert.schedule;
      payloadObject['channel'] = this.currentAlert.channel;
      payloadObject['operator'] = this.currentAlert.operator;
      payloadObject['name'] = this.currentAlert.name;
      payloadObject['additional_reference'] = this.currentAlert.alert_type + ':' + this.currentAlert.object_type + ':' + this.currentAlert.object_id;
      if (this.currentAlert.alert_type) {
        if (this.currentAlert.alert_type === 'entity') {
          payloadObject['url_reference'] = 'https://' + window.location.hostname + '/cockpit/news/market/chart?id=' + this.currentAlert.object_id + '&type=' + this.currentAlert.object_type;
        } else if (this.currentAlert.alert_type === 'portfolio') {
          payloadObject['url_reference'] = 'https://' + window.location.hostname + '/cockpit/news/portfolio/chart?id=' + this.currentAlert.object_id;
        } else if (this.currentAlert.alert_type === 'search_query') {
          payloadObject['url_reference'] = 'https://' + window.location.hostname + '/cockpit/news/query/chart?id=' + this.currentAlert.object_id + '&groupId=default';
        } else if (this.currentAlert.alert_type === 'search_query_group') {
          payloadObject['url_reference'] = 'https://' + window.location.hostname + '/cockpit/news/query/chart?id=' + this.currentAlert.object_id + '&groupId=' + this.currentAlert.object_id;
        }
        if (this.currentAlert.alert_type === 'portfolio') {
          payloadObject['operator'] = 'OR';
          this.folio.filter(el => {
            return el.uid === this.currentAlert.object_id;
          })[0].content.forEach(object => {
            const object_type = object.split(':')[0];
            const object_id = object.split(':')[1];
            const payloadObjectSubAlert = {};
            payloadObjectSubAlert['url_reference'] = 'https://' + window.location.hostname + '/cockpit/news/market/chart?id=' + object_id + '&type=' + object_type;
            payloadObjectSubAlert['additional_reference'] = this.currentAlert.alerts[0].score_type;
            if (this.currentAlert.alerts[0].score_type === 'sentiment') {
              payloadObjectSubAlert['threshold'] = (this.currentAlert.alerts[0].threshold / 100.0) + '';
            } else {
              payloadObjectSubAlert['threshold'] = this.currentAlert.alerts[0].threshold + '';
            }
            payloadObjectSubAlert['threshold_setting'] = this.currentAlert.alerts[0].threshold_setting;
            if (this.currentAlert.alerts[0].score_type === 'sentiment') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/sentiment';
              // payloadObjectSubAlert['json_path'] = 'sentiment.sentiment';
              payloadObjectSubAlert['json_path'] = 'quotient_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'general_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/score_ts?score_type=general_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'esg_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/score_ts?score_type=esg_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'immediate_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/score_ts?score_type=immediate_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'credit_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/ml_score_ts';
              // payloadObjectSubAlert['json_path'] = 'ml_score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'ml_score_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'esg') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/score_ts?score_type=esg&disable_industry_materiality=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (this.currentAlert.alerts[0].score_type === 'bbd') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/bull_bear_score_ts?time_horizon=1D';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (this.currentAlert.alerts[0].score_type === 'bbw') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/bull_bear_score_ts?time_horizon=1W';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (this.currentAlert.alerts[0].score_type === 'bbm') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/bull_bear_score_ts?time_horizon=1M';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (this.currentAlert.alerts[0].score_type === 'bbq') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/bull_bear_score_ts?time_horizon=1Q';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (this.currentAlert.alerts[0].score_type) {
              let sentimentType = '';
              let eventWeights = '';
              this.auth.scorelabSettings.customScores.forEach(item => {
                if (item.uid === this.currentAlert.alerts[0].score_type) {
                  if (item.sentiment_type === 'neg') {
                    sentimentType = '&with_black_swan=true';
                  }
                  if (item.event_weights && (item.event_weights.length > 0)) {
                    item.event_weights.forEach(event => {
                      let event_id, weight, polarity, weight_persistence;
                      if (event.event_id) {
                        event_id = event.event_id;
                      } else {
                        event_id = undefined;
                      }
                      if (event.weight) {
                        weight = event.weight;
                      } else {
                        weight = 5;
                      }
                      if (event.polarity) {
                        polarity = event.polarity;
                      } else {
                        polarity = -1;
                      }
                      if (event.weight_persistence) {
                        weight_persistence = event.weight_persistence;
                      } else {
                        weight_persistence = 90;
                      }
                      if (event_id) {
                        eventWeights += '&' + event_id + '_weight=' + (weight * polarity) + ',' + weight_persistence;
                      }
                    });
                  }
                }
              });
              payloadObjectSubAlert['endpoint_path'] = '/api/' + object_type + '/' + object_id + '/score_ts?' + sentimentType + '' + eventWeights;
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            }
            // payloadObjectSubAlert['name'] = '';
            payloadObject['alerts'].push(payloadObjectSubAlert);
          });
        } else {
          this.currentAlert.alerts.forEach(subAlert => {
            const payloadObjectSubAlert = {};
            payloadObjectSubAlert['additional_reference'] = subAlert.score_type;
            if (subAlert.score_type === 'sentiment') {
              payloadObjectSubAlert['threshold'] = (subAlert.threshold / 100.0) + '';
            } else {
              payloadObjectSubAlert['threshold'] = subAlert.threshold + '';
            }
            payloadObjectSubAlert['threshold_setting'] = subAlert.threshold_setting;
            if (subAlert.score_type === 'sentiment') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/sentiment';
              // payloadObjectSubAlert['json_path'] = 'sentiment.sentiment';
              payloadObjectSubAlert['json_path'] = 'quotient_ts';
            } else if (subAlert.score_type === 'general_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/score_ts?score_type=general_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (subAlert.score_type === 'esg_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/score_ts?score_type=esg_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (subAlert.score_type === 'immediate_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/score_ts?score_type=immediate_risk&with_black_swan=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (subAlert.score_type === 'credit_risk') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/ml_score_ts';
              // payloadObjectSubAlert['json_path'] = 'ml_score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'ml_score_ts';
            } else if (subAlert.score_type === 'bbd') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/bull_bear_score_ts?time_horizon=1D';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (subAlert.score_type === 'bbw') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/bull_bear_score_ts?time_horizon=1W';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (subAlert.score_type === 'bbm') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/bull_bear_score_ts?time_horizon=1M';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (subAlert.score_type === 'bbq') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/bull_bear_score_ts?time_horizon=1Q';
              payloadObjectSubAlert['json_path'] = 'bull_bear_scores';
            } else if (subAlert.score_type === 'esg') {
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/score_ts?score_type=esg&disable_industry_materiality=true';
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            } else if (subAlert.score_type) {
              let sentimentType = '';
              let eventWeights = '';
              this.auth.scorelabSettings.customScores.forEach(item => {
                if (item.uid === subAlert.score_type) {
                  if (item.sentiment_type === 'neg') {
                    sentimentType = '&with_black_swan=true';
                  }
                  if (item.event_weights && (item.event_weights.length > 0)) {
                    item.event_weights.forEach(event => {
                      let event_id, weight, polarity, weight_persistence;
                      if (event.event_id) {
                        event_id = event.event_id;
                      } else {
                        event_id = undefined;
                      }
                      if (event.weight) {
                        weight = event.weight;
                      } else {
                        weight = 5;
                      }
                      if (event.polarity) {
                        polarity = event.polarity;
                      } else {
                        polarity = -1;
                      }
                      if (event.weight_persistence) {
                        weight_persistence = event.weight_persistence;
                      } else {
                        weight_persistence = 90;
                      }
                      if (event_id) {
                        eventWeights += '&' + event_id + '_weight=' + (weight * polarity) + ',' + weight_persistence;
                      }
                    });
                  }
                }
              });
              payloadObjectSubAlert['endpoint_path'] = '/api/' + this.currentAlert.object_type + '/' + this.currentAlert.object_id + '/score_ts?' + sentimentType + '' + eventWeights;
              // payloadObjectSubAlert['json_path'] = 'score_ts_last_score.score';
              payloadObjectSubAlert['json_path'] = 'score_ts';
            }
            // payloadObjectSubAlert['name'] = '';
            payloadObject['alerts'].push(payloadObjectSubAlert);
          });
        }
      }
      this.auth.addMultiAlert(payloadObject).subscribe(result => {
        this.currentAlert.uid = result.created.uid;
        if (isNew) {
          this.alerts.push(this.currentAlert);
        }
        this.snackBar.open('Alert saved.', 'OK', { duration: 5000 });
      });
    } else {
      alert('Error: all of the required fields must be filled in.');
    }
  }

  deleteAlert() {
    if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
      this.notitle = false;
      this.auth.removeMultiAlert(this.currentAlert.uid).subscribe();
      this.alerts = this.alerts.filter(item => {
        return item.uid !== this.currentAlert.uid;
      });
      if (this.alerts.length > 0) {
        this.currentAlert = this.alerts[0];
      } else {
        this.currentAlert = {
          uid: 'create',
          name: '',
          is_active: false,
          alert_type: 'entity',
          schedule: '1d',
          channel: 'digest',
          object_type: '',
          object_id: '',
          object_name: '',
          operator: 'OR',
          alerts: [
            {
              score_type: '',
              threshold: '',
              threshold_setting: ''
            }
          ]
        };
      }
      this.updateForms();
      this.snackBar.open('Alert deleted.', 'OK', { duration: 5000 });
    }
  }

  addSubAlert() {
    this.currentAlert.alerts.push(
      {
        score_type: '',
        threshold: '',
        threshold_setting: ''
      }
    );
  }

  delSubAlert(index) {
    this.currentAlert.alerts.splice(index, 1);
  }

  iClose() {
    this.dialog.closeAll();
  }

  optSelect(option) {
    this.searchFormControl.setValue(option.entity.name);
    this.currentAlert.object_type = option.entity.type;
    this.currentAlert.object_id = option.entity.alpha_id;
    this.currentAlert.object_name = option.entity.name;
  }

  optSelect2() {
    this.currentAlert.object_type = '';
    this.currentAlert.object_id = '';
    this.currentAlert.object_name = '';
    this.currentAlert.alerts = [
      {
        score_type: '',
        threshold: '',
        threshold_setting: ''
      }
    ];
    this.searchFormControl.setValue('');
  }

  optSelect3(event, type) {
    let name = '';
    if (type === 'portfolio') {
      name = this.folio.filter(element => {
        return element.uid === event.value;
      })[0].name;
      this.currentAlert.object_type = 'portfolio';
    } else if (type === 'query') {
      name = this.query.filter(element => {
        return element.uid === event.value;
      })[0].name;
      this.currentAlert.object_type = 'search_query';
    } else if (type === 'collection') {
      name = this.collection.filter(element => {
        return element.uid === event.value;
      })[0].name;
      this.currentAlert.object_type = 'search_query';
    }
    this.currentAlert.object_name = name;
  }

  updateForms() {
    this.searchFormControl = new UntypedFormControl();
    this.queryFormControl = new UntypedFormControl();
    // this.loading = true;

    let name = '';
    this.searchFormControl.setValue('');

    if (this.currentAlert.object_type && this.currentAlert.object_id) {
      if (this.currentAlert.alert_type === 'entity') {
        this.yukkApi.isentiment(
          {
            type: this.currentAlert.object_type,
            id: this.currentAlert.object_id,
            fromAlertsSetup: true
          },
          false
        ).subscribe(result => {
          this.searchFormControl.setValue(result.entity.name);
          this.currentAlert.object_name = result.entity.name;
        });
      } else if (this.currentAlert.alert_type === 'portfolio') {
        name = this.folio ? this.folio.filter(element => {
          return element.uid === this.currentAlert.object_id;
        })[0].name : '';
        this.currentAlert.object_name = name;
      } else if (this.currentAlert.alert_type === 'search_query') {
        const query = this.query ? this.query.filter(element => {
          return element.uid === this.currentAlert.object_id;
        })[0] : {};
        name = this.query ? this.query.filter(element => {
          return element.uid === this.currentAlert.object_id;
        })[0].name : '';
        this.queryFormControl.setValue(query);
        this.currentAlert.object_name = name;
      } else if (this.currentAlert.alert_type === 'search_query_group') {
        name = this.collection ? this.collection.filter(element => {
          return element.uid === this.currentAlert.object_id;
        })[0].name : '';
        this.currentAlert.object_name = name;
      }
    }

    this.searchFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      if ( value && ( value.length > 1 ) ) {
        this.loading = true;
        this.items$ = this.yukkApi.search(value, false).pipe(map(result => {
          this.loading = false;
          return result;
        }));
      }
    });

    if (this.query) {
      this.filteredQuery$ = this.queryFormControl.valueChanges
        .pipe(
          tap(value => {
            this.currentAlert.object_name = value['name'];
            this.currentAlert.object_id = value['uid'];
            this.currentAlert.object_type = 'search_query';
          }),
          startWith(''),
          map(value => typeof value === 'string' ? value : value['name']),
          map(value => value ? this.elementMatches(this.query, value) : this.query.slice())
        );
    }
  }

  onChannelChange() {
    if (this.currentAlert.channel === 'digest') {
      this.currentAlert.operator = 'OR';
    }
  }

  onScoreChange(event, index) {
    this.currentAlert.alerts[index].threshold_setting = 'CROSS_ABOVE';
    if (event.value === 'sentiment') {
      this.currentAlert.alerts[index].threshold = 50;
    } else if (event.value === 'general_risk') {
      this.currentAlert.alerts[index].threshold = 5;
    } else if (event.value === 'esg_risk') {
      this.currentAlert.alerts[index].threshold = 4;
    } else if (event.value === 'immediate_risk') {
      this.currentAlert.alerts[index].threshold = 3.5;
    } else if (event.value === 'credit_risk') {
      this.currentAlert.alerts[index].threshold = 5.5;
    } else if (event.value === 'esg') {
      this.currentAlert.alerts[index].threshold = 5;
    } else if (event.value === 'bbd') {
      this.currentAlert.alerts[index].threshold = 0;
    } else if (event.value === 'bbw') {
      this.currentAlert.alerts[index].threshold = 0;
    } else if (event.value === 'bbm') {
      this.currentAlert.alerts[index].threshold = 0;
    } else if (event.value === 'bbq') {
      this.currentAlert.alerts[index].threshold = 0;
    }
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(element) {
    return (element && element.name) ? element.name : '';
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0);
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num) {
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

}
