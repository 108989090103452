<mat-drawer-container class="mainapp" autosize>
    <div class="example-sidenav-content">
        <div class="contmobile">


            <div class="contabv">
                <div class="search">
                    <app-menu-above></app-menu-above>
                </div>
            </div>


            <div class="greetings">
                <div class="greetings-left">Good Afternoon, {{user.email.split('@')[0].toUpperCase()}}</div>
                <div class="greetings-right">{{clock}}</div>
            </div>


            <div class="contmid">

                <div class="midtop">

                    <mat-form-field appearance="fill">
                        <mat-label>General Risk Update</mat-label>
                        <mat-select [(value)]="selected">
                            <mat-option value="Hackathon Portfolio">Hackathon Portfolio - Overall</mat-option>
                            <mat-option *ngFor="let item of portfolio" [value]="item" [disabled]="true">
                                {{item}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="chart-container">
                        <app-news-chart></app-news-chart>
                    </div>

                    <div class="scanner-mosaic-contents">

                        <div class="company-block">
                            <div class="company-menu">
                                <div class="treemap-item">
                                    <div class="item-bar">
                                        <div class="item-bar-cont">
                                            <div class="bar-header">
                                                <div class="bar-name">{{'General Risk Score'}}</div>
                                                <div class="bar-number">
                                                    <span class="bar-count">{{mosaicScore ? (mosaicScore + ' / 10') : ''}}</span>
                                                </div>
                                            </div>
                                            <div class="bar-cont">
                                                <mat-progress-bar class="bar-main score-neg" mode="determinate" [value]="100 - (mosaicScore * 10)"></mat-progress-bar>
                                            </div>
                                            <div class="table-header">
                                                <div>Events</div>
                                                <div>No. Articles</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="company-treemap" *ngIf="iready else loadingScoreEvents">
                                <ng-container *ngFor="let item of scoreEvents | orderBy:'-contrib_amount' | slice:0:5">
                                    <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0">
                                        <div class="item-bar">
                                            <div class="item-bar-cont">
                                                <div class="bar-header">
                                                    <div class="bar-name">{{item.name}}</div>
                                                    <div class="bar-number">
                                                        <span class="bar-count">{{item.contrib_amount}}</span>
                                                    </div>
                                                </div>
                                                <div class="bar-cont">
                                                    <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEvents,item.contrib_amount)"></mat-progress-bar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                                <div *ngIf="scoreEvents==0" class="noevent">NO EVENTS</div>
                            </div>
                            <ng-template #loadingScoreEvents>
                                <div class="loading" *ngIf="loading">
                                    <mat-spinner [diameter]="50"></mat-spinner>
                                </div>
                                <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
                                <div class="nodata" *ngIf="!nodata && !loading">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
                            </ng-template>
                        </div>

                    </div>

                    <nav class="news-count-nav" mat-tab-nav-bar *ngIf="!routing.isNewsletter()">
                        <a mat-tab-link [active]="params.cutnoise === 'true'" (click)="changeNews(true)">
                            <div>Cut the noise</div>
                        </a>
                        <a mat-tab-link [active]="params.cutnoise !== 'true'" (click)="changeNews(false)">
                            <div>All News</div>
                        </a>
                    </nav>

                </div>

                <div class="news-newsfeed">

                    <div class="feednews" *ngIf="staticNewsfeedTopData && (params.cutnoise === 'true')">
                        <app-hack-news-feed [newsfeedData]="staticNewsfeedTopData"></app-hack-news-feed>
                    </div>

                    <div class="feednews" *ngIf="staticNewsfeedAllData && (params.cutnoise !== 'true')">
                        <app-hack-news-feed [newsfeedData]="staticNewsfeedAllData"></app-hack-news-feed>
                    </div>

                </div>

            </div>


            <div class="contbot">

                <nav mat-tab-nav-bar *ngIf="routing.isFolio()">
                    <a mat-tab-link routerLink="news/portfolio/scanner" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge" routerLinkActive="active" [active]="routing.isScanner()">
                        <mat-icon>compass_calibration</mat-icon>
                    </a>
                    <a mat-tab-link routerLink="news/portfolio/newsfeed" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge" routerLinkActive="active" [active]="routing.isFeed()">
                        <mat-icon svgIcon="newspaper-variant"></mat-icon>
                    </a>
                </nav>

                <nav mat-tab-nav-bar *ngIf="routing.isMarket()">
                    <a mat-tab-link routerLink="news/market/chart" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge"
                       routerLinkActive="active" [active]="routing.isChart()">
                        <mat-icon>insert_chart</mat-icon>
                    </a>
                    <a mat-tab-link routerLink="news/market/newsfeed" [queryParams]="{tag:null,newsday:null}" queryParamsHandling="merge"
                       routerLinkActive="active" [active]="routing.isFeed()">
                        <mat-icon svgIcon="newspaper-variant"></mat-icon>
                    </a>
                </nav>

            </div>


        </div>
    </div>
</mat-drawer-container>

