import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';

/**
* This guard check if your token is not expired
*/

@Injectable()
export class ExpireGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.auth.authToken();
    const date1 = new Date(user.exp * 1000);
    const date2 = new Date();
    if (date1 < date2) {
      this.router.navigate(['/message/expired'], { queryParams: { url: state.url } });
      return false;
    } else {
      return true;
    }
  }
}
