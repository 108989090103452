import { Component, ViewEncapsulation, Input, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NewsReportComponent } from '../news-report/news-report.component';
import { NewsReportboxComponent } from '../news-reportbox/news-reportbox.component';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { Location } from '@angular/common';
import { ConfigService } from 'src/app/service/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import * as jwt_decode from 'jwt-decode';
import { AnnotationComponent } from '../../news-annotation/news-annotation.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpComponent } from '../../../help/help.component';

/**
 * This is the most important component it display the news with the source, time, target, event, tag, etc....
 */
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-view2',
  templateUrl: './news-view2.component.html',
  styleUrls: ['./news-view2.component.scss']
})
export class NewsView2Component implements OnInit {



  /**
   * main data of the news
   */
  newsinfo: any;

  /**
   * main tag of the news
   */
  mytag: any;

  /**
   * main tag of the news
   */
  tag: string;

  /**
   * tag selected
   */
  btntag: number;

  /**
   * loading
   */
  loading: boolean;

  /**
   * usert auth
   */
  user: any;

  /**
   * maybe do not need it
   */
  report: any;

  /**
   * array of matched founded
   */
  matches: any;

  /**
   * array of entities to be audited or dismissed from audit
   */

  auditEntities: any;

  /**
   * checks if at least one entity was chosen
   */

  validateAuditEntities: boolean;

  /**
   * form control for news audit comments
   */

  newsAuditComment: UntypedFormControl;

  /**
   * form control for news audit emails
   */

  newsAuditNotify: UntypedFormControl;

  /**
   * toggle between audit and news views
   */

  auditView: boolean;

  /**
   * toggle between dismiss and news views
   */

  dismissView: boolean;

  /**
   * array of match related
   */
  inmatches: any;

  /**
   *
   */
  instart: any;

  /**
   *
   */
  inrelated: any;

  /**
   * data from the api
   */
  @Input('apicall') apicall: any;

  /**
   * match selected
   */
  btnmatch: string;

  /**
   * query parameters
   */
  params: any;

  /**
   * show hide api reference
   */
  apiref: boolean;

  /**
   * preferred news
   */
  mystar: boolean;

  /**
   *
   */
  stars = [];

  /**
   * event id
   */
  eventid: string;

  /**
   * event index
   */
  eventindex: number;

  /**
   * previous event index
   */
  oldEventIndex: number;

  /**
   * highlight text spans defined in the url
   */
  showHighlights: boolean;

  /**
   * array of participants
   */
  participants: any;

  /**
   * data corresponding to MAT_DIALOG_DATA injection
   */
  data: any;

  /**
   * setup the user and check the news curation
   */
  constructor(
      private route: ActivatedRoute,
      private dialog: MatDialog,
      public auth: AuthService,
      public location: Location,
      public snackBar: MatSnackBar,
      public routing: RoutingService,
      private router: Router,
      public config: ConfigService,
      // @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (localStorage.getItem('news_curation')) {
      this.stars = localStorage.getItem('news_curation').split(',');
    }
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (!this.routing.isReader()) {
        this.user = this.auth.authToken();
      } else if (params.jwt) {
        this.user = jwt_decode(params.jwt);
      } else {
        this.user = this.auth.authToken();
      }
    });
  }

  /**
   * init the component at the start
   */
  ngOnInit() {
    this.data = null;
    this.showHighlights = false;
    if (this.apicall) {
      if (this.params.highlight) {
        this.iHighlight();
      }
      this.loading = false;
      this.newsinfo = this.apicall.apicall;
      if (typeof this.newsinfo === 'string') { this.newsinfo = JSON.parse(this.newsinfo); }
      this.matches = this.newsinfo.matches.filter(risp => {
        if ((this.config.appConfig.environment === 'dev') || (this.params.type && (this.params.type === 'pne'))) { return risp; }
        return risp.sentiment != null;
      });
      this.instart = {
        type: undefined,
        alpha_id: undefined,
        compound_key: undefined
      };
      this.inmatches = this.instart;
      this.inrelated = this.instart;
      this.newsinfo.matches.forEach(match => {
        if (match.entity.compound_key === this.apicall.match) {
          this.mytag = match;
          this.btnmatch = this.apicall.match;
          this.instart = {
            type: match.entity.type,
            alpha_id: match.entity.alpha_id,
            compound_key: match.entity.compound_key
          };
          this.inmatches = this.instart;
          this.inrelated = this.instart;
        }
      });
    } else {
      this.newsinfo = this.data.newsinfo;
      this.params = this.data.params;
      this.mytag = undefined;
      this.tag = null;
      if (this.params.tag) {
        this.tag = decodeURI(this.params.tag.split('|')[0]);
      }
      this.btntag = undefined;
      this.matches = this.newsinfo.matches;
      this.matches = this.newsinfo.matches.filter(risp => {
        if ((this.config.appConfig.environment === 'dev') || (this.params.type && (this.params.type === 'pne'))) { return risp; }
        return risp.sentiment != null;
      });
      this.instart = {
        type: this.params.type,
        alpha_id: this.params.id,
        compound_key: this.params.type + ':' + this.params.id
      };
      this.inmatches = this.instart;
      if (this.routing.isNewsaudit() && this.routing.isFolio()) {
        let entities = [];
        const portfolioEntities = this.auth.folio.content;
        entities = this.newsinfo.matches.filter(item => {
          return portfolioEntities.includes(item.entity.compound_key);
        });
        this.auditEntities = entities.map(entity => ({
          name: entity.entity.name,
          selected: false
        }));
        this.validateAuditEntities = false;
        this.newsAuditComment = new UntypedFormControl('');
        this.newsAuditNotify = new UntypedFormControl('');
        if (this.params.audit === 'true') {
          this.auditView = true;
          this.dismissView = false;
        } else if (this.params.dismiss === 'true') {
          this.dismissView = true;
          this.auditView = false;
        } else {
          this.dismissView = false;
          this.auditView = false;
        }
      }
      if (this.params.highlight) {
        this.iHighlight();
      }
      if (this.params.tag) {
        this.newsinfo.tags.forEach(item => {
          if (item.tag === this.tag) {
            this.mytag = item;
          }
        });
      } else {
        this.eventindex = undefined;
        if (this.data.params.eventid) {
          this.newsinfo.events.forEach(event => {
            if (event.id === this.data.params.eventid) {
              this.eventid = event.id;
              event['spans'] = [
                {
                  location: event.where,
                  start: event.span.start,
                  end: event.span.end,
                  sentiment: null,
                }
              ];
              this.mytag = event;
            }
          });
        } else {
          this.newsinfo.matches.forEach(match => {
            if (match.entity.compound_key === this.inmatches.compound_key) {
              this.mytag = match;
              this.inrelated = this.instart;
              this.btnmatch = this.inmatches.compound_key;
            }
          });
        }
      }
    }
  }

  /**
   * toggling between chosen entities for news audit or dismiss
   */

  chooseEntity(index) {
    this.auditEntities[index].selected = !this.auditEntities[index].selected;
    const entities = this.auditEntities.filter(el => {
      return el.selected === true;
    });
    this.validateAuditEntities = entities.length !== 0;
  }

  /**
   * submit news for audit or dismiss
   */

  newsSubmit() {
    const entities = this.auditEntities.filter(el => {
      return el.selected === true;
    }).map(el => el.name);
    const comment = this.newsAuditComment.value;
    const email = this.newsAuditNotify.value;
    let data = {};
    if (entities.length !== 0) {
      if (this.auditView) {
        data = {
          comment: comment,
          article_id: this.params.news,
          title: this.newsinfo.title,
          content: this.newsinfo.content,
          read_more_link: this.newsinfo.read_more_link || '',
          publish_time: this.newsinfo.publish_time,
          subjects: entities,
          notify: [email]
        };
        this.auth.newsAudit(data).subscribe(() => {
          this.snackBar.open('News submitted for audit!', 'OK', { duration: 5000 });
        });
      } else if (this.dismissView) {
        data = {
          comment: comment,
          article_id: this.params.news,
          title: this.newsinfo.title,
          content: this.newsinfo.content,
          read_more_link: this.newsinfo.read_more_link || '',
          publish_time: this.newsinfo.publish_time,
          subjects: entities
        };
        this.auth.newsDismiss(data).subscribe(() => {
          this.snackBar.open('News dismissed from audit!', 'OK', { duration: 5000 });
        });
      }
    }
  }

  /**
   * trigger the target entities to display
   */
  clickTarget(match) {
    this.eventid = undefined;
    if (this.mytag === match) {
      this.mytag = undefined;
      this.btntag = undefined;
      this.inmatches = this.instart;
      this.inrelated = undefined;
    } else {
      this.mytag = match;
      this.btntag = undefined;
      this.inmatches = match.entity;
      this.inrelated = match.entity;
    }
  }

  /**
   * trigger the relate entities to display
   */
  clickRelate(match) {
    this.eventid = undefined;
    if (this.mytag !== match) {
      this.mytag = match;
      this.btntag = undefined;
      this.inrelated = match.entity;
    }
  }

  /**
   * send a report
   */
  goReport() {

    let uniqueEvents = [];
    let uniqueParticipants = [];
    this.newsinfo.events.forEach(event => {
      uniqueEvents.push({
        id: event.id,
        type: event.type,
        name: event.name
      });
      event.participants.forEach(participant => {
        uniqueParticipants.push({
          alpha_id: participant.alpha_id,
          type: participant.type,
          name: participant.name
        });
      });
    });
    uniqueEvents = [...new Map(uniqueEvents.map(item => [item['id'], item])).values()];
    uniqueParticipants = [...new Map(uniqueParticipants.map(item => [item['alpha_id'], item])).values()];
    const reportData = {
      newsId: this.params.news,
      newsInfo: this.newsinfo,
      matches: this.matches,
      tag: this.tag,
      email: this.user.email,
      project: this.config.appConfig.routing.project,
      events: uniqueEvents,
      participants: uniqueParticipants,
      inmatches: this.inmatches
    };

    this.dialog.open(NewsReportboxComponent, {
      data: reportData
    });

    // const match = this.inmatches.type + ':' + this.inmatches.alpha_id;
    // this.dialog.open(NewsReportComponent, {
    //   data: {
    //     report: report,
    //     match: match
    //   },
    //   disableClose: true
    // }).afterClosed().subscribe(message => {
    //   if (message.valid) {
    //     const data = {
    //       time: Date.now(),
    //       email: this.user.email,
    //       message: message.value,
    //       report: report,
    //       label: 'todo',
    //       match: match,
    //       tag: this.tag,
    //       apicall: JSON.stringify(this.newsinfo),
    //       project: this.config.appConfig.routing.project,
    //       language: this.newsinfo.lang,
    //       url: window.location.href
    //     };
    //     this.auth.storeReport(data).subscribe(result => {
    //       this.snackBar.open('Report sent!', 'OK', { duration: 5000 });
    //       this.auth.iSlack({ report: data, id: result }).subscribe();
    //     });
    //   }
    // });

  }

  /**
   * add an annotation
   */
  addNote() {
    this.dialog.open(AnnotationComponent, {
      data: {
        newone: true,
        entity: this.params.type + ':' + this.params.id,
        newsday: moment(this.newsinfo.publish_time).format('YYYY-MM-DD')
      },
    });
  }

  toggleAuditView() {
    this.auditView = !this.auditView;
    if (this.dismissView) {
      this.dismissView = false;
    }
  }

  toggleDismissView() {
    this.dismissView = !this.dismissView;
    if (this.auditView) {
      this.auditView = false;
    }
  }

  /**
   * bookmark the news
   */
  addStar(id) {
    if (this.stars.includes(id)) {
      this.auth.delStars(id).subscribe(result => {
        this.stars.splice(this.stars.indexOf(id), 1);
        localStorage.setItem('news_curation', this.stars.join(','));
      });
    } else {
      this.auth.addStars(id).subscribe(result => {
        this.stars.push(id);
        localStorage.setItem('news_curation', this.stars.join(','));
      });
    }
  }

  /**
   * display the bookamark
   */
  iBookmark(id) {
    return this.stars.includes(id);
  }

  /**
   * navigate thru the news list
   */
  iNavigo(value) {
    this.dialog.closeAll();
    const index = this.data.arraid.indexOf(this.params.news);
    let id = this.data.arraid[index - 1];
    if (value === 'next') {
      id = this.data.arraid[index + 1];
    }
    this.router.navigate([], {
      queryParams: { news: id },
      queryParamsHandling: 'merge',
    });
  }

  /**
   *
   */
  iDisable(value) {
    const index = this.data.arraid.indexOf(this.params.news);
    if (value === 'previus' && (index === 0 || index === -1)) {
      return true;
    }
    if (value === 'next' && index === this.data.arraid.length - 1) {
      return true;
    }
    return false;
  }

  /**
   * close the dialog
   */
  iClose() {
    this.dialog.closeAll();
    this.router.navigate([], {
      queryParams: {
        news: null,
      }, queryParamsHandling: 'merge',
    });
  }

  /**
   * show the related entities
   */
  isRelated(array) {
    if (this.mytag && array) {
      const data = array.filter(item => item[1].visible.length > 0);
      return data.length > 0;
    }
    return false;
  }

  /**
   * display the event highlight
   */
  iEvent(index) {
    this.eventindex = index;
    if (this.eventindex === this.oldEventIndex) {
      this.oldEventIndex = undefined;
      this.mytag = undefined;
    } else {
      this.newsinfo.events.forEach((event, index2) => {
        if (index2 === index) {
          event['spans'] = [
            {
              location: event.where,
              start: event.span.start,
              end: event.span.end,
              sentiment: null,
            }
          ];
          this.mytag = event;
        }
      });
      this.oldEventIndex = index;
    }
  }

  /**
   * display url defined highlight
   */
  iHighlight() {
    this.showHighlights = !this.showHighlights;
    if (!this.showHighlights) {
      this.mytag = undefined;
    } else {
      const highlightSpans = JSON.parse(decodeURIComponent(this.params.highlight));
      this.mytag = {
        spans: highlightSpans.map(fragment => {
          return {
            location: fragment.where,
            start: fragment.span.start,
            end: fragment.span.end,
            sentiment: null,
          };
        })
      };
    }
  }

  /**
   * get link with highlights
   */
  getHighlightLink() {
    this.showHighlights = !this.showHighlights;
    this.iHighlight();
    const path = window.location.host + '/cockpit/news/portfolio?news=' + this.params.news + '&highlight=' + this.params.highlight;
    this.copyMessage(path);
    this.snackBar.open('Link with highlights copied to clipboard.', 'OK', { duration: 5000 });
  }

  copyMessage(val) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /**
   * open help component
   */
  openHelp() {
    this.dialog.open(HelpComponent, {
      data: { component: 'article' }
    });
  }

  /**
   * get news original link
   */
  getNewsOriginalLink(link) {
    const path = link;
    this.copyMessage(path);
    this.snackBar.open('Link to the original article copied to clipboard.', 'OK', { duration: 5000 });
  }

}

