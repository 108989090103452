import { Pipe, PipeTransform } from '@angular/core';

/**
 * This pipe generate the right name in singular or plural
 */

@Pipe({
  name: 'nicetxt'
})
export class NicetxtPipe implements PipeTransform {

  transform(value: any, multi: boolean): any {

    const mynicetxt = {
      event: {
        singular: 'Event',
        multiple: 'Events',
      },
      multi: {
        singular: 'Multi',
        multiple: 'Multies',
      },
      de: {
        singular: 'German',
        multiple: 'German',
      },
      en: {
        singular: 'English',
        multiple: 'English',
      },
      all: {
        singular: 'Market',
        multiple: 'Market',
      },
      search_query: {
        singular: 'Query',
        multiple: 'Query',
      },
      city: {
        singular: 'City',
        multiple: 'Cities',
      },
      company: {
        singular: 'Company',
        multiple: 'Companies',
      },
      stock_index: {
        singular: 'Stock Index',
        multiple: 'Stock Indices',
      },
      country: {
        singular: 'Country',
        multiple: 'Countries',
      },
      continent: {
        singular: 'Continent',
        multiple: 'Continents',
      },
      industry: {
        singular: 'Industry',
        multiple: 'Industries',
      },
      sector: {
        singular: 'Sector',
        multiple: 'Sectors',
      },
      subsector: {
        singular: 'Sub-sector',
        multiple: 'Sub-sectors',
      },
      supersector: {
        singular: 'Super-sector',
        multiple: 'Super-sectors',
      },
      product: {
        singular: 'Product',
        multiple: 'Product',
      },
      product_type: {
        singular: 'Product Type',
        multiple: 'Product Type',
      },
      product_model: {
        singular: 'Product Model',
        multiple: 'Product Models',
      },
      pne: {
        singular: 'Potential Entity',
        multiple: 'Potential Entities',
      },
      tag: {
        singular: 'Tag',
        multiple: 'Tag',
      },
      ceo: {
        singular: 'CEO',
        multiple: 'CEO',
      },
      collection: {
        singular: 'Collection',
        multiple: 'Collection',
      },
      automotive_industry_sector: {
        singular: 'Automotive Sector',
        multiple: 'Automotive Sector',
      },
      entity_type: {
        singular: 'Entity Type',
        multiple: 'Entity Type',
      },
      etf: {
        singular: 'Etf',
        multiple: 'Etf',
      },
      hierarchy: {
        singular: 'Hierarchy',
        multiple: 'Hierarchy',
      },
      icb: {
        singular: 'ICB',
        multiple: 'ICB',
      },
      index_etf: {
        singular: 'Index',
        multiple: 'Index',
      },
      industry_grouping: {
        singular: 'Industry Grouping',
        multiple: 'Industry Grouping',
      },
      person: {
        singular: 'Person',
        multiple: 'Persons',
      },
      portfolio: {
        singular: 'Portfolio',
        multiple: 'Portfolio',
      },
      security: {
        singular: 'Security',
        multiple: 'Security',
      },
      source: {
        singular: 'Source',
        multiple: 'Source',
      },
      supersector_etf: {
        singular: 'Supersector Etf',
        multiple: 'Supersector Etf',
      },
      automotive_ceo: {
        singular: 'Automotive Ceo',
        multiple: 'Automotive Ceo',
      },
      automotive_products: {
        singular: 'Automotive Poducts',
        multiple: 'Automotive Poducts',
      },
      automotive_product_categories: {
        singular: 'Automotive Poducts Categories',
        multiple: 'Automotive Poducts Categories',
      },
      organization: {
        singular: 'Organization',
        multiple: 'Organizations',
      },
      region: {
        singular: 'Region',
        multiple: 'Regions',
      },
      currency: {
        singular: 'Currency',
        multiple: 'Currency',
      },
      political_party: {
        singular: 'Political Party',
        multiple: 'Political Parties',
      },
      professional: {
        singular: 'Pro (Full Text)',
        multiple: 'Pro (Full Text)',
      },
      non_professional: {
        singular: 'Web (Global)',
        multiple: 'Web (Global)',
      },
      online: {
        singular: 'Web (Finance)',
        multiple: 'Web (Finance)',
      },
      print: {
        singular: 'Print (Full Text)',
        multiple: 'Print (Full Text)',
      },
      topic: {
        singular: 'Topic',
        multiple: 'Topics',
      },
      topics: {
        singular: 'Topic',
        multiple: 'Topics',
      },
      topic_group: {
        singular: 'Topic',
        multiple: 'Topics',
      },
      cryptocurrency: {
        singular: 'Crypto-Currency',
        multiple: 'Crypto-Currencies',
      },
      location: {
        singular: 'Location',
        multiple: 'Locations',
      },
      politician: {
        singular: 'Politician',
        multiple: 'Politicians',
      },
    };

    let nicevalue = value;
    if (mynicetxt[value]) {
      nicevalue = mynicetxt[value].singular ? mynicetxt[value].singular : value;
      if (multi) {
        nicevalue = mynicetxt[value].multiple ? mynicetxt[value].multiple : value;
      }
    }

    return nicevalue;
  }

}
