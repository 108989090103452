import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { YukkApi } from '../../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../../service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-trending-column',
  templateUrl: './trending-column.component.html',
  styleUrls: ['./trending-column.component.scss']
})
export class TrendingColumnComponent implements OnInit {

  @Input('trending') trending: any;
  @Input('searchTerm') searchTerm: any;
  @Input('loading') loading: any;
  @Input('nodata') nodata: any;

  // arraytrend: any;
  // myOrder: any;
  // previousValue: any;
  // rsub: any;

  qparams: any;

  params = {
    sort: 'trend'
  };
  myOrder: any;

  /**
   * columns to display
   */
  ifilter: any;

  /**
   * columns array
   */
  array_filter = ['Country', 'Trend', 'Volatility', 'Price', 'Price Change', 'ESG Score'];

  constructor(
    private yukkApi: YukkApi,
    private router: Router,
    private route: ActivatedRoute,
    private routing: RoutingService,
    private auth: AuthService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.qparams = params;
    });

  }

  ngOnInit() {

    this.auth.getUserSettings('trendColumns').subscribe(result => {
      if (result.entry) {
        let resultObj = JSON.parse(result.entry);
        resultObj = resultObj.filter(item => {
          return this.array_filter.includes(item);
        });
        this.ifilter = new UntypedFormControl(resultObj);
      } else {
        this.ifilter = new UntypedFormControl(this.array_filter);
      }
    });

    // this.loading = false;

    if (this.params.sort) {
      if (this.params.sort === 'trend') { this.myOrder = '-trend_value'; }
      if (this.params.sort === '-trend') { this.myOrder = 'trend_value'; }
      if (this.params.sort === 'abc') { this.myOrder = 'name'; }
      if (this.params.sort === '-abc') { this.myOrder = '-name'; }
      if (this.params.sort === 'price') { this.myOrder = '-price_delta'; }
      if (this.params.sort === '-price') { this.myOrder = 'price_delta'; }
      if (this.params.sort === 'score') { this.myOrder = '-score'; }
      if (this.params.sort === '-score') { this.myOrder = 'score'; }
    } else {
      this.myOrder = 'trend';
    }
    // this.myOrder = 'sentiment_delta';
    // const newParams = Object.assign({}, this.params, {how: this.myOrder, time: 30});
    // this.params = newParams;
    // if (this.routing.reFresh(this.params, this.previousValue, ['entity', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'how'])) {
    //   this.loading = true;
    //   this.trending = [];
    //   this.rsub = this.yukkApi.trending(this.params, this.entity).subscribe(result => {
    //     this.trending = result.trending;
    //     this.loading = false;
    //   });
    // }
    // this.previousValue = this.params;
  }

  /**
   * apply chosen sorting option
   */
  goSort(value) {
    if (this.params.sort === value) {
      this.params.sort = '-' + value;
    } else {
      this.params.sort = value;
    }
    if (this.params.sort === 'trend') { this.myOrder = '-trend_value'; }
    if (this.params.sort === '-trend') { this.myOrder = 'trend_value'; }
    if (this.params.sort === 'vola') { this.myOrder = '-volatility_value'; }
    if (this.params.sort === '-vola') { this.myOrder = 'volatility_value'; }
    if (this.params.sort === 'abc') { this.myOrder = 'name'; }
    if (this.params.sort === '-abc') { this.myOrder = '-name'; }
    if (this.params.sort === 'price') { this.myOrder = '-price_delta'; }
    if (this.params.sort === '-price') { this.myOrder = 'price_delta'; }
    if (this.params.sort === 'score') { this.myOrder = '-score'; }
    if (this.params.sort === '-score') { this.myOrder = 'score'; }
  }

  /**
   * add tag to the url on item click
   */
  addTag(item) {
    if (!this.routing.isMobile()) {
      const tagItem = 'm:' + item.type + ':' + item.alpha_id;
      const tag = (this.qparams.tag === tagItem) ? null : encodeURI(tagItem);
      this.router.navigate([], {
        queryParams: {
          tag: tag,
          eventid: null,
          eventype: null,
          scoreevent: null,
          newstype: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate(['/mobile/news/market/chart'], {
        queryParams: {
          type: item.type,
          id: item.alpha_id,
          tag: null,
          news: null,
          eventid: null,
          eventype: null,
          newstype: null
        }, queryParamsHandling: 'merge'
      });
    }
  }

  /**
   * changed columns to be displayed
   */
  iFilter(event) {
    if (event === false) {
      const trendColumns = this.ifilter.value;
      const trendColumnsValue = JSON.stringify(trendColumns);
      this.auth.setUserSettings('trendColumns', trendColumnsValue).subscribe();
    }
  }

  /**
   * check if column should be displayed
   */
  iTool(value) {
    return this.ifilter.value.includes(value);
  }

}
