import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth.service';
/**
* check if your account is a REVIEW
*/
@Injectable()
export class ReviewGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {
    const user = this.auth.authToken();
    if (user.roles.includes('ADMIN') || user.roles.includes('REVIEW')) {
      return true;
    } else {
      this.router.navigate(['/message/notreview'], { queryParams: { url: state.url } });
    }
  }
}
