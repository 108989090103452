import { Component, OnInit } from '@angular/core'

/**
 * This dialog appear when there is no internet.
 */

@Component({
  selector: 'app-internet',
  templateUrl: './internet.component.html',
  styleUrls: ['./internet.component.scss']
})
export class InternetComponent implements OnInit {

  /**
  * constructor
  */

  constructor() { }

  /**
  * ngOnInit
  */

  ngOnInit() {
  }

}
