<div class="scanner-lhs-main">
    <div class="loading" *ngIf="loading">
        <mat-spinner [diameter]="50"></mat-spinner>
    </div>
    <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
    <ng-container *ngIf="!(loading || nodata) && ifilter">
        <div class="titolo">
            <!--<div class="border">
                <div class="bar" [style.background-color]="'transparent'" [style.color]="'transparent'"></div>
            </div>-->
            <div class="box-trend">
                <div class="tito">
                    <div class="cont">
                        <!--<span class="value rank">
                          <span class="sort"># </span>
                        </span>-->
                        <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">
                          <span class="sort" (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Entity </span>
                          <span [class.no-sort]="!(params.sort==='abc'||params.sort==='-abc')">
                            {{((params.sort==='abc') ? -1 : 1 )|trend}}
                          </span>
                        </span>
                    </div>
                </div>
                <div class="info" [class.mobile]="routing.isMobile()">
                    <div class="cont">

                        <span *ngIf="(auth.scorelabSettings.defaultFlag)" class="value" [class.sort-active]="params.sort===auth.scorelabSettings.defaultFlag.score_type||params.sort===('-' + auth.scorelabSettings.defaultFlag.score_type)">
                          <span class="sort" (click)="goSort(auth.scorelabSettings.defaultFlag.score_type)" [matTooltip]="auth.scorelabSettings.defaultFlag.name" matTooltipPosition="above">Flag</span>
                          <span [class.no-sort]="!(params.sort===auth.scorelabSettings.defaultFlag.score_type||params.sort===('-' + auth.scorelabSettings.defaultFlag.score_type))">
                            {{((params.sort===auth.scorelabSettings.defaultFlag.score_type) ? 1 : -1 )|trend}}
                          </span>
                        </span>

                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'esg')" class="value" [class.sort-active]="params.sort==='esg_industry_bench'||params.sort==='-esg_industry_bench'">
                          <span class="sort" (click)="goSort('esg_industry_bench')" [matTooltip]="'ESG score relative to industry'" matTooltipPosition="above">&#8691;ESG</span>
                          <span [class.no-sort]="!(params.sort==='esg_industry_bench'||params.sort==='-esg_industry_bench')">
                            {{((params.sort==='esg_industry_bench') ? 1 : -1 )|trend}}
                          </span>
                        </span>

                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment')" class="value" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'">
                          <span class="sort" (click)="goSort('senti')" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">Sent </span>
                          <span [class.no-sort]="!(params.sort==='senti'||params.sort==='-senti')">
                            {{((params.sort==='senti') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment') && !routing.isMobile()" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
                          <span class="sort" (click)="goSort('trend')" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">ΔSent </span>
                          <span [class.no-sort]="!(params.sort==='trend'||params.sort==='-trend')">
                            {{((params.sort==='trend') ? 1 : -1 )|trend}}
                          </span>
                        </span>

                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment')" class="value" [class.sort-active]="params.sort==='score'||params.sort==='-score'">
                          <span class="sort" (click)="goSort('score')" [matTooltip]="(auth.getScoreName().name && (auth.getScoreName().name === 'Risk')) ? 'Risk score based on the event data from the last 100 days' : auth.getScoreName().name" matTooltipPosition="above">{{auth.getScoreName().short}} </span>
                          <span [class.no-sort]="!(params.sort==='score'||params.sort==='-score')">
                            {{((params.sort==='score') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment') && !routing.isMobile()" class="value" [class.sort-active]="params.sort==='score_delta'||params.sort==='-score_delta'">
                          <span class="sort" (click)="goSort('score_delta')" [matTooltip]="(auth.getScoreName().name && (auth.getScoreName().name === 'Risk')) ? 'The relative change in the risk score when compared to the previous based on the timeframe selected' : (auth.getScoreName().name + ' change')" matTooltipPosition="above">Δ{{auth.getScoreName().short}} </span>
                          <span [class.no-sort]="!(params.sort==='score_delta'||params.sort==='-score_delta')">
                            {{((params.sort==='score_delta') ? 1 : -1 )|trend}}
                          </span>
                        </span>

                        <ng-container *ngFor="let score of getAvailableScores()">
                            <span *ngIf="!getScoreId(score).isDelta" class="value" [class.sort-active]="params.sort===score||params.sort===('-' + score)">
                              <span class="sort" (click)="goSort(score)" [matTooltip]="(auth.getScoresInfo(score)['name'] && (auth.getScoresInfo(score)['name'] === 'Risk')) ? 'Risk score based on the event data from the last 100 days' : auth.getScoresInfo(score)['name']" matTooltipPosition="above">{{auth.getScoresInfo(score)['short']}} </span>
                              <span [class.no-sort]="!(params.sort===score||params.sort===('-' + score))">
                                {{((params.sort===score) ? 1 : -1 )|trend}}
                              </span>
                            </span>
                            <span *ngIf="getScoreId(score).isDelta" class="value" [class.sort-active]="params.sort===score||params.sort===('-'+score)">
                              <span class="sort" (click)="goSort(score)" [matTooltip]="(auth.getScoresInfo(getScoreId(score).scoreId)['name'] && (auth.getScoresInfo(getScoreId(score).scoreId)['name'] === 'Risk')) ? 'The relative change in the risk score when compared to the previous based on the timeframe selected' : (auth.getScoresInfo(getScoreId(score).scoreId)['name'] + ' change')" matTooltipPosition="above">Δ{{auth.getScoresInfo(getScoreId(score).scoreId)['short']}} </span>
                              <span [class.no-sort]="!(params.sort===score||params.sort===('-' + score))">
                                {{((params.sort===score) ? 1 : -1 )|trend}}
                              </span>
                            </span>
                        </ng-container>

                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment') && iTool('sentiment')" class="value" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'">
                          <span class="sort" (click)="goSort('senti')" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">Sent </span>
                          <span [class.no-sort]="!(params.sort==='senti'||params.sort==='-senti')">
                            {{((params.sort==='senti') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment') && iTool('sentiment_delta')" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
                          <span class="sort" (click)="goSort('trend')" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">ΔSent </span>
                          <span [class.no-sort]="!(params.sort==='trend'||params.sort==='-trend')">
                            {{((params.sort==='trend') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span *ngIf="iTool('volume')" class="value" [class.sort-active]="params.sort==='count'||params.sort==='-count'">
                          <span class="sort" (click)="goSort('count')" matTooltip="The number of news articles for the selected timeframe" matTooltipPosition="above">Vol </span>
                          <span [class.no-sort]="!(params.sort==='count'||params.sort==='-count')">
                            {{((params.sort==='count') ? 1 : -1 )|trend}}
                          </span>
                        </span>
                        <span *ngIf="iTool('volume_ratio')" class="value" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'">
                          <span class="sort" (click)="goSort('ratio')" matTooltip="The relevant changes in the total number of news articles from the previous period based on the timeframe selected" matTooltipPosition="above">ΔVol </span>
                          <span [class.no-sort]="!(params.sort==='ratio'||params.sort==='-ratio')">
                            {{((params.sort==='ratio') ? 1 : -1 )|trend}}
                          </span>
                        </span>

                        <span *ngIf="!routing.isMobile()" class="value edit header-menu"  (click)="menuFilter.open()">
                            <mat-icon matTooltip="Adjust columns to be displayed">more_vert</mat-icon>
                            <mat-select [formControl]="ifilter" multiple (openedChange)="iFilter($event)" #menuFilter>
                                <mat-option *ngFor="let filter of array_filter" [value]="filter" [disabled]="isOptionDisabled(filter)">{{getFilterName(filter)}}</mat-option>
                            </mat-select>
                        </span>

                    </div>
                </div>
            </div>
        </div>
        <div class="item-cont" [class.empty]="items.length==0">
            <div class="nodata" *ngIf="items.length==0 && !auth.folio.is_aggregated && !(auth.folio.sharing_mode === 'read') && auth.featureFlags.addEntities">
                <button mat-stroked-button (click)="newIndex()">Add Company</button>
            </div>
            <div class="box-trend" *ngFor="let item of items | orderByImpure: myOrder; let index = index;" [class.active]="params.tag == ('m:' + item.entity.type + ':' + item.entity.alpha_id)" (click)="addTag(item)" (mouseenter)="onMouseEnterRow(item)" (mouseleave)="onMouseLeaveRow(item)">
                <!--<div class="border">
                    <div class="bar" [style.background-color]="item.sentiment|color" [style.color]="item.sentiment|color"></div>
                </div>-->
                <div class="tito">
                    <div class="cont">
                        <!--<span class="value rank">{{(index + 1)}}</span>-->
                        <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'" [class.highlight-entity]="currentEntity === item.entity.alpha_id" (mouseenter)="onMouseEnterName(item.entity.alpha_id)" (mouseleave)="onMouseLeaveName()" (click)="$event.stopPropagation(); navEntity(item.entity);">{{item.entity.name}}</span>
                    </div>
                </div>
                <div class="info" [class.mobile]="routing.isMobile()">
                    <div class="cont">

                        <span *ngIf="(auth.scorelabSettings.defaultFlag)" class="value">
                          <div *ngIf="isFlagged(item)" [matTooltip]="getFlagTooltip(item)" matTooltipPosition="above" class="flag">
                              <mat-icon>flag</mat-icon>
                          </div>
                          <div class="loading-item" *ngIf="loadingFlag(item)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>

                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'esg')" class="value">
                          <div *ngIf="item['esg_industry_bench'] || (item['esg_industry_bench'] === 0)" [matTooltip]="item['esg_industry_bench_info']" matTooltipPosition="above" class="bench">
                              <mat-icon *ngIf="item['esg_industry_bench'] === 0." svgIcon="timer-sand-full" class="laggard"></mat-icon>
                              <mat-icon *ngIf="item['esg_industry_bench'] === 1." svgIcon="trending-down" class="below"></mat-icon>
                              <mat-icon *ngIf="item['esg_industry_bench'] === 2." svgIcon="trending-up" class="above"></mat-icon>
                              <mat-icon *ngIf="item['esg_industry_bench'] === 3." svgIcon="medal" class="leader"></mat-icon>
                          </div>
                          <div class="loading-item" *ngIf="!item['esg_industry_bench'] && !(item['esg_industry_bench'] === null) && !(item['esg_industry_bench'] === 0)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>

                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment')" class="value" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'" [style.color]="(params.sort==='senti' || params.sort==='-senti') ? getScoreColor(item) : 'inherit'">
                          <span>{{item.sentiment|percent}}</span>
                        </span>
                        <span *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment') && !routing.isMobile()" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'" [style.color]="(params.sort==='trend' || params.sort==='-trend') ? getScoreColor(item) : 'inherit'">
                          <span>{{item.sentiment_delta*100|round|trend}}</span>
                          <span>{{item.sentiment_delta*100|round|negative}}</span>
                        </span>

                        <span *ngIf="!['sentiment', 'bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)" class="value" [class.sort-active]="params.sort==='score'||params.sort==='-score'" [style.color]="(params.sort==='score' || params.sort==='-score') ? getScoreColor(item) : 'inherit'">
                          <span *ngIf="item.score || (item.score === 0)">{{item.score| round:1}}</span>
                          <div class="loading-item" *ngIf="!item.score && !(item.score === null) && !(item.score === 0)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>
                        <span *ngIf="!['sentiment', 'bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore) && !routing.isMobile()" class="value" [class.sort-active]="params.sort==='score_delta'||params.sort==='-score_delta'" [style.color]="(params.sort==='score_delta' || params.sort==='-score_delta') ? getScoreColor(item) : 'inherit'">
                          <span *ngIf="item.score_delta || (item.score_delta === 0)">{{item.score_delta| round:1|trend}}</span>
                          <span *ngIf="item.score_delta || (item.score_delta === 0)">{{item.score_delta| round:1|negative}}</span>
                          <div class="loading-item" *ngIf="!item.score_delta && !(item.score_delta === null) && !(item.score_delta === 0)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>

                        <span *ngIf="['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)" class="value" [class.sort-active]="params.sort==='score'||params.sort==='-score'" [style.color]="'inherit'">
                          <span class="icon">
                            <img *ngIf="item.trend_type === 'bear'" src="assets/icon/bear.svg">
                            <img *ngIf="item.trend_type === 'bull'" src="assets/icon/bull.svg">
                          </span>
                          <span *ngIf="item.score || (item.score === 0)">{{Math.round((Math.abs(item.score) * 100))}}%</span>
                          <div class="loading-item" *ngIf="!item.score && !(item.score === null) && !(item.score === 0)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>
                        <span *ngIf="['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore) && !routing.isMobile()" class="value" [class.sort-active]="params.sort==='score_delta'||params.sort==='-score_delta'" [style.color]="'inherit'">
                          <span *ngIf="item.score_delta || (item.score_delta === 0)">{{(item.score_delta * 100)| round:0|trend}}</span>
                          <span *ngIf="item.score_delta || (item.score_delta === 0)">{{(item.score_delta * 100)| round:0|negative}}</span>
                          <div class="loading-item" *ngIf="!item.score_delta && !(item.score_delta === null) && !(item.score_delta === 0)">
                            <mat-spinner [diameter]="18"></mat-spinner>
                          </div>
                        </span>

                        <ng-container *ngFor="let score of getAvailableScores()">
                            <span *ngIf="!getScoreId(score).isDelta && !['bbd', 'bbw', 'bbm', 'bbq'].includes(getScoreId(score).scoreId)" class="value" [class.sort-active]="params.sort===score||params.sort===('-' + score)" [style.color]="(params.sort===score || params.sort===('-' + score)) ? getScoreColor(item) : 'inherit'">
                              <span *ngIf="item[score] || (item[score] === 0)">{{item[score]| round:1}}</span>
                              <div class="loading-item" *ngIf="!item[score] && !(item[score] === null) && !(item[score] === 0)">
                                <mat-spinner [diameter]="18"></mat-spinner>
                              </div>
                            </span>
                            <span *ngIf="getScoreId(score).isDelta && !['bbd_delta', 'bbw_delta', 'bbm_delta', 'bbq_delta'].includes(getScoreId(score).scoreId)" class="value" [class.sort-active]="params.sort===score||params.sort===('-' + score)" [style.color]="(params.sort===score || params.sort===('-' + score)) ? getScoreColor(item) : 'inherit'">
                              <span *ngIf="item[score] || (item[score] === 0)">{{item[score]| round:1|trend}}</span>
                              <span *ngIf="item[score] || (item[score] === 0)">{{item[score]| round:1|negative}}</span>
                              <div class="loading-item" *ngIf="!item[score] && !(item[score] === null) && !(item[score] === 0)">
                                <mat-spinner [diameter]="18"></mat-spinner>
                              </div>
                            </span>
                            <span *ngIf="!getScoreId(score).isDelta && ['bbd', 'bbw', 'bbm', 'bbq'].includes(getScoreId(score).scoreId)" class="value" [class.sort-active]="params.sort===score||params.sort===('-' + score)" [style.color]="'inherit'">
                              <span class="icon">
                                <img *ngIf="item[score + '_trend_type'] === 'bear'" src="assets/icon/bear.svg">
                                <img *ngIf="item[score + '_trend_type'] === 'bull'" src="assets/icon/bull.svg">
                              </span>
                              <span *ngIf="item[score] || (item[score] === 0)">{{Math.round((Math.abs(item[score]) * 100))}}%</span>
                              <div class="loading-item" *ngIf="!item[score] && !(item[score] === null) && !(item[score] === 0)">
                                <mat-spinner [diameter]="18"></mat-spinner>
                              </div>
                            </span>
                            <span *ngIf="getScoreId(score).isDelta && ['bbd_delta', 'bbw_delta', 'bbm_delta', 'bbq_delta'].includes(getScoreId(score).scoreId)" class="value" [class.sort-active]="params.sort===score||params.sort===('-' + score)" [style.color]="'inherit'">
                              <span *ngIf="item[score] || (item[score] === 0)">{{(item[score] * 100)| round:0|trend}}</span>
                              <span *ngIf="item[score] || (item[score] === 0)">{{(item[score] * 100)| round:0|negative}}</span>
                              <div class="loading-item" *ngIf="!item[score] && !(item[score] === null) && !(item[score] === 0)">
                                <mat-spinner [diameter]="18"></mat-spinner>
                              </div>
                            </span>
                        </ng-container>

                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment') && iTool('sentiment')" class="value" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'" [style.color]="(params.sort==='senti' || params.sort==='-senti') ? getScoreColor(item) : 'inherit'">
                          <span>{{item.sentiment|percent}}</span>
                        </span>
                        <span *ngIf="(auth.scorelabSettings.defaultScore !== 'sentiment') && iTool('sentiment_delta')" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'" [style.color]="(params.sort==='trend' || params.sort==='-trend') ? getScoreColor(item) : 'inherit'">
                          <span>{{item.sentiment_delta*100|round|trend}}</span>
                          <span>{{item.sentiment_delta*100|round|negative}}</span>
                        </span>
                        <span *ngIf="iTool('volume')" class="value" [class.sort-active]="params.sort==='count'||params.sort==='-count'" [style.color]="(params.sort==='count' || params.sort==='-count') ? getScoreColor(item) : 'inherit'">
                          <span>{{item.count}}</span>
                        </span>
                        <span *ngIf="iTool('volume_ratio')" class="value" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'" [style.color]="(params.sort==='ratio' || params.sort==='-ratio') ? getScoreColor(item) : 'inherit'">
                          <span>{{(item.volume_ratio !== null) ? ((item.volume_ratio*100 - 100.0)|round|trend) : ''}}</span>
                          <span>{{(item.volume_ratio !== null) ? ((item.volume_ratio*100 - 100.0)|round|negative)+'%' : '\u2014'}}</span>
                        </span>

                        <span *ngIf="!routing.isMobile()" class="value edit" [class.transparent]="!item.visible || (auth.folio.sharing_mode === 'read') || (auth.folio.is_aggregated) || !auth.featureFlags.editEntities" matTooltip="Edit / Delete" (click)="$event.stopPropagation(); editIndex(item.entity);">
                            <mat-icon>mode_edit</mat-icon>
                        </span>

                    </div>
                </div>
            </div>
        </div>
        <div class="folio-editin" *ngIf="!routing.isMobile() && !(auth.folio.sharing_mode === 'read') && auth.featureFlags.addEntities">
            <button mat-raised-button class="goindex" (click)="newIndex()" matTooltip="{{auth.folio.is_aggregated ? 'Add / Remove Portfolios' : 'Add Company'}}">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </ng-container>
</div>

