<div class="chartmap-company">

  <!-- <app-news-title [titolo]="titolo" *ngIf="titolo"></app-news-title> -->

  <button mat-icon-button class="isett" *ngIf="!qparam.mosaic && !qparam.score" (click)="menuFilter.open()">
    <mat-icon>more_vert</mat-icon>
    <mat-select [formControl]="ifilter" multiple (openedChange)="iFilter($event)" #menuFilter>
      <mat-option *ngFor="let filter of array_filter" [value]="filter" [disabled]="false">{{filter}}</mat-option>
    </mat-select>
  </button>

  <div class="company-row">

    <!--<button mat-icon-button [matMenuTriggerFor]="menu" class="isett">
      <mat-icon>more_vert</mat-icon>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>Events</button>
        <button mat-menu-item>Peergroup Comparison</button>
        <button mat-menu-item>Topic List</button>
        <button mat-menu-item>Entity</button>
      </mat-menu>
    </button>-->

    <div class="company-block" *ngIf="showWarningFlag && iTool('Flag Events') && !routing.isMobile() && !routing.isWidget() && !(qparam?.score && (qparam.score !== auth.scorelabSettings.defaultFlag.score_type))">

      <div class="company-menu">
        <div class="menu-left" *ngIf="checkTimeframe() && !qparam?.score">{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}} (Last 100 days)</div>
        <div class="menu-left" *ngIf="!checkTimeframe() && !qparam?.score">{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}}</div>
        <div class="menu-left" *ngIf="qparam?.score" [style.color]="(auth.scoreFocusedColor && (auth.scoreFocusedColor !== 'transparent')) ? auth.scoreFocusedColor : 'inherit'">
          <span class="score-icon">
            <mat-icon>flag</mat-icon>
          </span>
          <span>{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}}</span>
        </div>
        <div class="menu-right" *ngIf="qparam?.score && flagWarningEventsCountAll">
          <div class="distribution">
            <ngx-charts-pie-chart [view]="[60, 60]" [scheme]="{domain: ['#008B8B', 'rgb(120, 120, 120)']}" [results]="[{'name': 'Score Events', 'value': flagWarningEventsCount}, {'name': 'All Events', 'value': (flagWarningEventsCountAll - flagWarningEventsCount)}]"></ngx-charts-pie-chart>
          </div>
          <span>{{(flagWarningEventsCount/flagWarningEventsCountAll)*100|ceil}}% of all events</span>
        </div>
      </div>
      <div class="company-treemap" *ngIf="iready['Flag Events']['Warning'] else loadingFlagEventsTemplate">
        <ng-container *ngFor="let item of flagWarningEvents | orderBy:'-contrib_amount' | slice:0:7">
          <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
            <div class="item-bar" [class.active]="qparam.eventid==item.id">
              <div class="item-bar-cont">
                <div class="bar-header">
                  <div class="bar-name">{{item.name}}</div>
                  <div class="bar-number">
                    <span class="bar-count">{{item.contrib_amount}}</span>
                  </div>
                </div>
                <div class="bar-cont">
                  <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(flagWarningEvents,item.contrib_amount)"></mat-progress-bar>
                </div>
              </div>
            </div>
            <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
              <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
            </div>
          </div>
        </ng-container>
        <div *ngIf="flagWarningEvents==0" class="noevent">NO EVENTS</div>
      </div>

      <ng-template #loadingFlagEventsTemplate>
        <div class="loading" *ngIf="loading['Flag Events']['Warning']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Flag Events']['Warning']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="!nodata['Flag Events']['Warning'] && !loading['Flag Events']['Warning']">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="showCautionFlag && !showWarningFlag && iTool('Flag Events') && !routing.isMobile() && !routing.isWidget() && !(qparam?.score && (qparam.score !== auth.scorelabSettings.defaultFlag.score_type))">

      <div class="company-menu">
        <div class="menu-left" *ngIf="checkLongTimeframe() && !qparam?.score">{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}} (Last 465 days)</div>
        <div class="menu-left" *ngIf="!checkLongTimeframe() && !qparam?.score">{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}}</div>
        <div class="menu-left" *ngIf="qparam?.score" [style.color]="(auth.scoreFocusedColor && (auth.scoreFocusedColor !== 'transparent')) ? auth.scoreFocusedColor : 'inherit'">
          <span class="score-icon">
            <mat-icon>flag</mat-icon>
          </span>
          <span>{{auth.getScoresInfo(auth.scorelabSettings.defaultFlag.score_type)['name'] + ' Events'}}</span>
        </div>
        <div class="menu-right" *ngIf="qparam?.score && flagCautionEventsCountAll">
          <div class="distribution">
            <ngx-charts-pie-chart [view]="[60, 60]" [scheme]="{domain: ['#008B8B', 'rgb(120, 120, 120)']}" [results]="[{'name': 'Score Events', 'value': flagCautionEventsCount}, {'name': 'All Events', 'value': (flagCautionEventsCountAll - flagCautionEventsCount)}]"></ngx-charts-pie-chart>
          </div>
          <span>{{(flagCautionEventsCount/flagCautionEventsCountAll)*100|ceil}}% of all events</span>
        </div>
      </div>
      <div class="company-treemap" *ngIf="iready['Flag Events']['Caution'] else loadingFlagEventsTemplate">
        <ng-container *ngFor="let item of flagCautionEvents | orderBy:'-contrib_amount' | slice:0:7">
          <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iFlagEvent(item)">
            <div class="item-bar" [class.active]="qparam.eventid==item.id">
              <div class="item-bar-cont">
                <div class="bar-header">
                  <div class="bar-name">{{item.name}}</div>
                  <div class="bar-number">
                    <span class="bar-count">{{item.contrib_amount}}</span>
                  </div>
                </div>
                <div class="bar-cont">
                  <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(flagCautionEvents,item.contrib_amount)"></mat-progress-bar>
                </div>
              </div>
            </div>
            <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
              <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
            </div>
          </div>
        </ng-container>
        <div *ngIf="flagCautionEvents==0" class="noevent">NO EVENTS</div>
      </div>

      <ng-template #loadingFlagEventsTemplate>
        <div class="loading" *ngIf="loading['Flag Events']['Caution']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Flag Events']['Caution']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="!nodata['Flag Events']['Caution'] && !loading['Flag Events']['Caution']">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="iTool('Events') && !(qparam?.score && !['sentiment', 'bbd', 'bbw', 'bbm', 'bbq'].includes(qparam.score))">
      <div class="company-menu">
        <div class="menu-left" *ngIf="!qparam?.score">Sentiment Events</div>
        <div class="menu-left" *ngIf="qparam?.score" [style.color]="(auth.scoreFocusedColor && (auth.scoreFocusedColor !== 'transparent')) ? auth.scoreFocusedColor : 'inherit'">
          <span class="score-icon" *ngIf="!['bbd', 'bbw', 'bbm', 'bbq'].includes(qparam.score)">
            <svg-icon src="assets/icon/o_sentiment.svg"></svg-icon>
          </span>
          <span>Sentiment Events</span>
        </div>
        <div class="menu-right" *ngIf="qparam?.score && sentimentEventsCountAll">
          <div class="distribution">
            <ngx-charts-pie-chart [view]="[60, 60]" [scheme]="{domain: ['#008B8B']}" [results]="[{'name': 'All Events', 'value': sentimentEventsCountAll}]"></ngx-charts-pie-chart>
          </div>
          <span>{{1|percent}} of all events</span>
        </div>
      </div>
      <div class="company-treemap" *ngIf="iready['Events'] else loadingEvents">
        <ng-container *ngFor="let item of events | orderBy:'-count' | slice:0:7">
          <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.count > 0" (click)="iEvent(item)">
            <div class="item-bar" [class.active]="qparam.eventid==item.event.id">
              <div class="item-bar-cont">
                <div class="bar-header">
                  <div class="bar-name">{{item.event.name}}</div>
                  <div class="bar-number">
                    <span class="bar-count">{{item.count}}</span>
                  </div>
                </div>
                <div class="bar-cont">
                  <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaro(events,item.count)"></mat-progress-bar>
                </div>
              </div>
            </div>
            <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.event.id">
              <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.event.id"></app-micro-chart>
            </div>
          </div>
        </ng-container>
        <div *ngIf="events==0" class="noevent">NO EVENTS</div>
      </div>
      <ng-template #loadingEvents>
        <div class="loading" *ngIf="loading['Events']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Events']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="!nodata['Events'] && !loading['Events']">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="iTool('Score Events') && !(qparam?.score && (qparam.score !== auth.scorelabSettings.defaultScore))">

      <ng-container *ngIf="isEsgSetup()">
        <div class="company-menu">
          <div class="menu-left" *ngIf="checkTimeframe() && !qparam?.score">ESG Events (Last 100 days)</div>
          <div class="menu-left" *ngIf="!checkTimeframe() && !qparam?.score">ESG Events</div>
          <div class="menu-left" *ngIf="qparam?.score" [style.color]="(auth.scoreFocusedColor && (auth.scoreFocusedColor !== 'transparent')) ? auth.scoreFocusedColor : 'inherit'">
            <span class="score-icon">
              <svg-icon src="{{'assets/icon/o_esg.svg'}}"></svg-icon>
            </span>
            <span>ESG Events</span>
          </div>
          <div class="menu-right" *ngIf="qparam?.score && scoreEventsCountAll">
            <div class="distribution">
              <ngx-charts-pie-chart [view]="[60, 60]" [scheme]="{domain: ['#008B8B', 'rgb(120, 120, 120)']}" [results]="[{'name': 'Score Events', 'value': scoreEventsCount}, {'name': 'All Events', 'value': (scoreEventsCountAll - scoreEventsCount)}]"></ngx-charts-pie-chart>
            </div>
            <span>{{(scoreEventsCount/scoreEventsCountAll)*100|ceil}}% of all events</span>
          </div>
        </div>
        <div #expansionContainer class="company-treemap" *ngIf="iready['Score Events'] else loadingScoreEvents">
          <mat-accordion>
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header [style.height.px]="contentHeight">
                <mat-panel-title>
                  Overall
                </mat-panel-title>
                <mat-panel-description [style.color]="scoresESG.scoreESG | colorScore2">
                  {{scoresESG.scoreESG}} / 10
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-table-header">
                <div>Events</div>
                <div>No. Articles</div>
              </div>
              <div class="panel-table-body">
                <ng-container *ngFor="let item of scoreEventsESG | orderBy:'-contrib_amount'">
                  <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
                    <div class="item-bar" [class.active]="qparam.eventid==item.id">
                      <div class="item-bar-cont">
                        <div class="bar-header">
                          <div class="bar-name" [class.pos-impact]="item.impact === 1" [class.neg-impact]="item.impact === -1">{{item.name}}</div>
                          <div class="bar-number">
                            <span class="bar-count">{{item.contrib_amount}}</span>
                          </div>
                        </div>
                        <div class="bar-cont">
                          <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEventsESG,item.contrib_amount)"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                    <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
                      <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header [style.height.px]="contentHeight">
                <mat-panel-title>
                  Environmental
                </mat-panel-title>
                <mat-panel-description [style.color]="scoresESG.scoreE | colorScore2">
                  {{scoresESG.scoreE}} / 10
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-table-header">
                <div>Events</div>
                <div>No. Articles</div>
              </div>
              <div class="panel-table-body">
                <ng-container *ngFor="let item of scoreEventsE | orderBy:'-contrib_amount'">
                  <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
                    <div class="item-bar" [class.active]="qparam.eventid==item.id">
                      <div class="item-bar-cont">
                        <div class="bar-header">
                          <div class="bar-name" [class.pos-impact]="item.impact === 1" [class.neg-impact]="item.impact === -1">{{item.name}}</div>
                          <div class="bar-number">
                            <span class="bar-count">{{item.contrib_amount}}</span>
                          </div>
                        </div>
                        <div class="bar-cont">
                          <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEventsE,item.contrib_amount)"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                    <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
                      <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header [style.height.px]="contentHeight">
                <mat-panel-title>
                  Social
                </mat-panel-title>
                <mat-panel-description [style.color]="scoresESG.scoreS | colorScore2">
                  {{scoresESG.scoreS}} / 10
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-table-header">
                <div>Events</div>
                <div>No. Articles</div>
              </div>
              <div class="panel-table-body">
                <ng-container *ngFor="let item of scoreEventsS | orderBy:'-contrib_amount'">
                  <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
                    <div class="item-bar" [class.active]="qparam.eventid==item.id">
                      <div class="item-bar-cont">
                        <div class="bar-header">
                          <div class="bar-name" [class.pos-impact]="item.impact === 1" [class.neg-impact]="item.impact === -1">{{item.name}}</div>
                          <div class="bar-number">
                            <span class="bar-count">{{item.contrib_amount}}</span>
                          </div>
                        </div>
                        <div class="bar-cont">
                          <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEventsS,item.contrib_amount)"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                    <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
                      <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="false">
              <mat-expansion-panel-header [style.height.px]="contentHeight">
                <mat-panel-title>
                  Governance
                </mat-panel-title>
                <mat-panel-description [style.color]="scoresESG.scoreG | colorScore2">
                  {{scoresESG.scoreG}} / 10
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div class="panel-table-header">
                <div>Events</div>
                <div>No. Articles</div>
              </div>
              <div class="panel-table-body">
                <ng-container *ngFor="let item of scoreEventsG | orderBy:'-contrib_amount'">
                  <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
                    <div class="item-bar" [class.active]="qparam.eventid==item.id">
                      <div class="item-bar-cont">
                        <div class="bar-header">
                          <div class="bar-name" [class.pos-impact]="item.impact === 1" [class.neg-impact]="item.impact === -1">{{item.name}}</div>
                          <div class="bar-number">
                            <span class="bar-count">{{item.contrib_amount}}</span>
                          </div>
                        </div>
                        <div class="bar-cont">
                          <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEventsG,item.contrib_amount)"></mat-progress-bar>
                        </div>
                      </div>
                    </div>
                    <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
                      <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
                    </div>
                  </div>
                </ng-container>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </ng-container>

      <ng-container *ngIf="!isEsgSetup()">
        <div class="company-menu">
          <div class="menu-left" *ngIf="checkTimeframe() && !qparam?.score">{{scoreEventsTitle()}} (Last 100 days)</div>
          <div class="menu-left" *ngIf="!checkTimeframe() && !qparam?.score">{{scoreEventsTitle()}}</div>
          <div class="menu-left" *ngIf="qparam?.score" [style.color]="(auth.scoreFocusedColor && (auth.scoreFocusedColor !== 'transparent')) ? auth.scoreFocusedColor : 'inherit'">
            <span class="score-icon">
              <svg-icon src="{{'assets/icon/o_' + qparam.score + '.svg'}}"></svg-icon>
            </span>
            <span>{{scoreEventsTitle()}}</span>
          </div>
          <div class="menu-right" *ngIf="qparam?.score && scoreEventsCountAll">
            <div class="distribution">
              <ngx-charts-pie-chart [view]="[60, 60]" [scheme]="{domain: ['#008B8B', 'rgb(120, 120, 120)']}" [results]="[{'name': 'Score Events', 'value': scoreEventsCount}, {'name': 'All Events', 'value': (scoreEventsCountAll - scoreEventsCount)}]"></ngx-charts-pie-chart>
            </div>
            <span>{{(scoreEventsCount/scoreEventsCountAll)*100|ceil}}% of all events</span>
          </div>
        </div>
        <div class="company-treemap" *ngIf="iready['Score Events'] else loadingScoreEvents">
          <ng-container *ngFor="let item of scoreEvents | orderBy:'-contrib_amount' | slice:0:7">
            <div class="treemap-item" [class.microchart]="qparam?.score" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
              <div class="item-bar" [class.active]="qparam.eventid==item.id">
                <div class="item-bar-cont">
                  <div class="bar-header">
                    <div class="bar-name">{{item.name}}</div>
                    <div class="bar-number">
                      <span class="bar-count">{{item.contrib_amount}}</span>
                    </div>
                  </div>
                  <div class="bar-cont">
                    <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEvents,item.contrib_amount)"></mat-progress-bar>
                  </div>
                </div>
              </div>
              <div class="microchart-container" *ngIf="qparam?.score && auth.scoreFocusedEvents && auth.scoreFocusedTimeframe" [class.active]="qparam.eventid==item.id">
                <app-micro-chart [name]="''" [data]="auth.scoreFocusedEvents" [timeframe]="auth.scoreFocusedTimeframe" [event]="item.id"></app-micro-chart>
              </div>
            </div>
          </ng-container>
          <div *ngIf="scoreEvents==0" class="noevent">NO EVENTS</div>
        </div>
      </ng-container>

      <ng-template #loadingScoreEvents>
        <div class="loading" *ngIf="loading['Score Events']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Score Events']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="!nodata['Score Events'] && !loading['Score Events']">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="iTool('Topic List') && !qparam?.score">
      <div class="company-menu">
        <div class="menu-left">Topic List</div>
      </div>
      <div class="company-treemap" *ngIf="iready['Topic List'] else loadingTopic">
        <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngFor="let item of topicloud|orderBy:'-total'|slice:0:100" [class.active]="tagword==item.tag">
          <div class="item-cont" (click)="iTacloud(item)">
            <!--<span class="item-left" [style.color]="item.sentiment|color">{{item.word}}</span>-->
            <span class="item-left">{{item.word}}</span>
            <div class="item-rigt">
              <span>{{item.total}}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loadingTopic>
        <div class="loading" *ngIf="loading['Topic List']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Topic List']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="!nodata['Topic List'] && !loading['Topic List']">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="iTool('Peergroup Comparison') && !qparam?.score">
      <div class="company-menu">
        <div class="menu-left">Peergroup Comparison</div>
      </div>
      <div class="company-treemap" *ngIf="(iready['Peergroup Comparison'] === true) else loadingPeergroup">
        <ng-container *ngIf="!(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))">

          <ng-container *ngIf="auth.scorelabSettings.defaultScore === 'sentiment'">
            <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngFor="let item of peergroup | orderBy:'-count' | slice:0:7 | orderBy: '-sentiment'">
              <div class="item-cont peergroup" (click)="iEntity(item.entity.type,item.entity.alpha_id)">
                <!--<div class="item-border">
                  <div class="item-border-fill" *ngIf="item.entity.alpha_id != entity.alpha_id" [style.backgroundColor]="item.sentiment-entity.sentiment|colorTrend"></div>
                  <div class="item-border-fill" *ngIf="item.entity.alpha_id == entity.alpha_id" style ="background-color: rgba(0, 0, 0, 0.2)"></div>
                </div>-->
                <div class="item-left peergroup">
                  <div>{{item.entity.name}}</div>
                </div>
                <div class="item-rigt">
                  <div [style.color]="item.sentiment|color">{{item.sentiment|percent}}</div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="auth.scorelabSettings.defaultScore !== 'sentiment'">
            <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngFor="let item of peergroup | orderBy:'-count' | slice:0:7 | orderBy: '-score'">
              <div class="item-cont peergroup" (click)="iEntity(item.entity.type,item.entity.alpha_id)">
                <!--<div class="item-border">
                  <div class="item-border-fill" *ngIf="item.entity.alpha_id != entity.alpha_id" [style.backgroundColor]="(item.score-entity.score)|colorTrend"></div>
                  <div class="item-border-fill" *ngIf="item.entity.alpha_id == entity.alpha_id" style ="background-color: rgba(0, 0, 0, 0.2)"></div>
                </div>-->
                <div class="item-left peergroup">
                  <div>{{item.entity.name}}</div>
                </div>
                <div class="item-rigt">
                  <div [style.color]="(auth.getScoreType() === 'pos') ? (item.score|colorScore2) : (item.score|colorScore)">{{item.score}}</div>
                </div>
              </div>
            </div>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="(['industry', 'supersector', 'sector', 'subsector'].includes(qparam.type))">
          <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngFor="let item of peergroup | orderBy:'-count' | orderBy: '-sentiment'">
            <div class="item-cont peergroup" (click)="iEntity(item.entity.type,item.entity.alpha_id)">
              <!--<div class="item-border">
                <div class="item-border-fill" *ngIf="item.entity.alpha_id != entity.alpha_id" [style.backgroundColor]="item.sentiment-entity.sentiment|colorTrend"></div>
                <div class="item-border-fill" *ngIf="item.entity.alpha_id == entity.alpha_id" style ="background-color: rgba(0, 0, 0, 0.2)"></div>
              </div>-->
              <div class="item-left peergroup">
                <div>{{item.entity.name}}</div>
              </div>
              <div class="item-rigt">
                <div [style.color]="item.sentiment|color">{{item.sentiment|percent}}</div>
                <!--<div *ngIf="item.entity.alpha_id != entity.alpha_id" class="lageat" [style.backgroundColor]="item.sentiment-entity.sentiment|colorTrend">
                  {{iPerform3(item.sentiment)}}
                </div>-->
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-template #loadingPeergroup>
        <div class="loading" *ngIf="loading['Peergroup Comparison'] && !(iready['Peergroup Comparison'] === null)">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Peergroup Comparison']">NO DATA AVAILABLE</div>
        <div class="nodata" *ngIf="iready['Peergroup Comparison'] === null">NO DIRECT PEERGROUP IDENTIFIED</div>
      </ng-template>
    </div>

    <div class="company-block" *ngIf="iTool('Entity') && !qparam?.score">
      <div class="company-menu">
        <div class="menu-logo" *ngIf="entity.logo">
          <img [src]="entity.logo">
        </div>
        <div>
          <ng-container [ngSwitch]="entity.currency">
            <span *ngSwitchCase="'CHF'" class="price-tito">₣{{entity.price}}</span>
            <span *ngSwitchDefault class="price-tito">{{entity.price|currency:entity.currency}}</span>
          </ng-container>
          <span class="price-tino">{{entity.price_change}}</span>
          <span class="price-tino">{{entity.price_change_diff}}</span>
        </div>
      </div>
      <div class="company-treemap" *ngIf="iready['Entity'] else loadingEntity">
        <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngFor="let item of treemapsort|slice:0:7|orderBy:'item.sentiment'">
          <div class="item-cont" (click)="iEntity(item.entity.type,item.entity.alpha_id)">
            <div class="item-left">
              <div class="item-labe">{{item.entity.type|nicetxt}}</div>
              <div [style.color]="item.sentiment|color">{{item.entity.name}}</div>
            </div>
            <div class="item-rigt">
              <span class="perce">{{item.sentiment|percent}}</span>
              <span class="trend" [style.color]="item.sentiment_delta|colorTrend">{{item.sentiment_delta|trend}}</span>
              <span class="spano">{{iAbs(item.sentiment_delta)}} </span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #loadingEntity>
        <div class="loading" *ngIf="loading['Entity']">
          <mat-spinner [diameter]="50"></mat-spinner>
        </div>
        <div class="nodata" *ngIf="nodata['Entity']">NO DATA AVAILABLE</div>
      </ng-template>
    </div>

  </div>
</div>
