<div class="query-dialog">
  <div mat-dialog-title>
    <!--<nav mat-tab-nav-bar>
      <a mat-tab-link [active]="itab==3" (click)="itab=3">Query</a>
      <a mat-tab-link [active]="itab==0" (click)="itab=0; insave=false;">Saved</a>
    </nav>-->
    <div class="query-header" *ngIf="itab==3">
      <div class="query-name">{{queryTitle}}</div>
      <ng-container *ngIf="!savedQuery">
        <mat-icon class="query-import" [matMenuTriggerFor]="menuImport">more_vert</mat-icon>
        <mat-menu #menuImport="matMenu">
          <button mat-menu-item (click)="fileInput.click()">Import query from file</button>
          <input #fileInput class="inptfile" type="file" accept=".json" value="" (click)="fileInput.value = null" (change)="importQuery($event)"/>
        </mat-menu>
      </ng-container>
    </div>
    <div class="query-collection">
      <mat-form-field appearance="outline">
        <mat-label>Collection</mat-label>
        <mat-select [(ngModel)]="selectedCollections" multiple>
          <mat-option *ngFor="let collection of collectionsList" [value]="collection.uid" [disabled]="collection.content.length >= auth.featureFlags.queriesPerCollectionsLimit">
            {{collection.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-content>
    <div *ngIf="itab!=0">
      <form [formGroup]="queryForm">
        <div class="query-bello">
          <div *ngIf="itab==3">
            <app-query2-group *ngIf="routing.theme!='expo'" [data]="{index:0,form:queryForm,level:0}">
            </app-query2-group>
            <app-query-group *ngIf="routing.theme=='expo'" [data]="{index:0,form:queryForm,level:0}"></app-query-group>
          </div>
        </div>
      </form>
    </div>
    <!--<div class="query-saved" *ngIf="itab==0">
      <div class="query" *ngFor="let query of $querylist|async|orderBy:'name'">
        <div class="butt" (click)="viewQuery(query)">{{query.name}}</div>
        <button mat-icon-button class="delete" matTooltip="Delete query" (click)="delQuery(query.uid)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button class="export" matTooltip="Export query to file" (click)="exportQuery(query)">
          <mat-icon>save</mat-icon>
        </button>
      </div>
    </div>-->
  </div>
  <div mat-dialog-actions *ngIf="itab!=0" [class.insave]="insave">
    <form class="saveme" [formGroup]="namequery" (ngSubmit)="saveQuery()" *ngIf="insave">
      <mat-icon class="close" (click)="insave=false; update=false;">close</mat-icon>
      <input matInput autocomplete="off" placeholder="Name..." formControlName="name">
      <button class="btno" mat-button type="submit" [disabled]="!namequery.valid">
        <mat-icon *ngIf="!savedQuery">save</mat-icon>
        <span *ngIf="!savedQuery">Save</span>
        <mat-icon *ngIf="update && savedQuery">update</mat-icon>
        <span *ngIf="update && savedQuery">Update</span>
        <mat-icon *ngIf="!update && savedQuery">file_copy</mat-icon>
        <span *ngIf="!update && savedQuery">Duplicate</span>
      </button>
    </form>
    <button mat-raised-button class="btnq" (click)="inStart()">
      <mat-icon>create_new_folder</mat-icon>
      <span>New</span>
    </button>
    <button *ngIf="savedQuery" mat-raised-button class="btnq" (click)="inUpdate()">
      <mat-icon>update</mat-icon>
      <span>Update</span>
    </button>
    <button *ngIf="savedQuery" mat-raised-button class="btnq" (click)="inSave()">
      <mat-icon>file_copy</mat-icon>
      <span>Duplicate</span>
    </button>
    <button *ngIf="!savedQuery" mat-raised-button class="btnq" (click)="inSave()">
      <mat-icon>save</mat-icon>
      <span>Save</span>
    </button>
    <button mat-raised-button class="btnq" (click)="goView()" [disabled]="!queryForm.valid">
      <mat-icon>search</mat-icon>
      <span>Search</span>
    </button>
  </div>
</div>
