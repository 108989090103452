<div mat-dialog-content class="news-annotation" *ngIf="note">
  <div class="menu">
    <span>
      <span>{{note.position | date}} </span>
    </span>
    <button mat-icon-button color="primary" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div *ngIf="isAuthor()">
    <form class="saveme" (ngSubmit)="goSave()">
      <input matInput #input [formControl]="title" placeholder="Title" autocomplete="off">
      <span class="minmax">{{title.value.length}}/30</span>
    </form>
    <ckeditor class="ckeditor" [editor]="Editor" [config]="config" [(ngModel)]="comment"></ckeditor>
  </div>
  <div *ngIf="!isAuthor()">
    <h3 [innerHTML]="title.value"></h3>
    <div [innerHTML]="comment"></div>
  </div>
  <div class="note-acto" *ngIf="isAuthor()">
    <div>
      <mat-slide-toggle [(ngModel)]="public" *ngIf="user.roles.includes('ADMIN') && authpro">Public</mat-slide-toggle>
    </div>
    <div>
      <button mat-raised-button (click)="delNote()" *ngIf="!data.newone">
        <span>Delete &nbsp;&nbsp;</span>
        <mat-icon>delete</mat-icon>
      </button>
      <button mat-raised-button [disabled]="!title.valid" (click)="goSave()">
        <span>Save &nbsp;&nbsp;</span>
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </div>
</div>