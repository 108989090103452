<div class="menu-above" *ngIf="auth.featureFlags.showMenuTop" [class.inblur]="params.tutorial" [class.noblur]="params.tutorial=='menuabove'">
  <div class="menu-above-left" [class.mobile]="routing.isMobile()" *ngIf="auth.featureFlags.showLogo">
    <!--<button mat-icon-button (click)="asideMenu()" *ngIf="routing.isDev() && !routing.isWidget() && !routing.isMobile()">
      <mat-icon>more_vert</mat-icon>
    </button>-->
    <img *ngIf="!routing.isMobile() && (logo !== 'universal')" class="navlogo" src="assets/logo/{{logo}}_menu.svg" alt="yukkalab" (click)="inLogo()">
    <img *ngIf="routing.isMobile() && (logo !== 'universal')" class="navlogo" src="assets/logo/{{logo}}_mobile.svg" alt="yukkalab" (click)="inLogo()">
    <img *ngIf="!routing.isMobile() && (logo === 'universal')" class="navlogo" src="assets/logo/{{logo}}_menu.png" alt="yukkalab" (click)="inLogo()">
    <img *ngIf="routing.isMobile() && (logo === 'universal')" class="navlogo" src="assets/logo/{{logo}}_mobile.png" alt="yukkalab" (click)="inLogo()">
  </div>
  <div class="menu-above-rigt">
    <div class="abovel">
      <app-menu-search *ngIf="!routing.isHackathon()" [component]="'menuabove'"></app-menu-search>
      <div *ngIf="routing.isHackathon()" class="hackathon-header">
        <div class="hackathon-header-text">YUKKA Lab - News Digest</div>
        <mat-icon>wb_sunny</mat-icon>
      </div>
    </div>
    <div class="abover" [class.mobile]="routing.isMobile()">
      <app-menu-options></app-menu-options>
    </div>
  </div>
</div>
