<nav class="news-count-nav" mat-tab-nav-bar *ngIf="!routing.isNewsletter()">
  <a mat-tab-link *ngFor="let link of navLinks" [active]="newstype==link" (click)="changeNews(link)" [matTooltip]="getTooltip(link)">
    <div>{{renameTab(link)}}</div>
  </a>
  <div class="moreinfo" *ngIf="routing.isScanner()" (click)="moreinfo=!moreinfo">
    <mat-icon *ngIf="!moreinfo">arrow_drop_down</mat-icon>
    <mat-icon *ngIf="moreinfo">arrow_drop_up</mat-icon>
  </div>
</nav>

<div class="news-count" *ngIf="!routing.isMobile() && !loading && params && (params.id !== 'default')">

  <!-- <div *ngIf="routing.isMobile() || routing.isIframe()">
    <app-news-title *ngIf="titolo$|async as titolo" [titolo]="titolo"></app-news-title>
  </div> -->

  <!--<nav mat-tab-nav-bar *ngIf="!routing.isNewsletter()">
    <a mat-tab-link *ngFor="let link of navLinks" [active]="newstype==link" (click)="changeNews(link)" [matTooltip]="getTooltip(link)">{{renameTab(link)}}</a>
  </nav>-->

  <div [class.hide]="params.nocount=='true'">
    <div class="feed-note" *ngIf="params.newsday">
      <div class="menu">
        <div class="tito" (click)="onote=!onote">
          <span *ngIf="notes.length">
            <mat-icon *ngIf="onote==true">arrow_drop_up</mat-icon>
            <mat-icon *ngIf="onote==false">arrow_drop_down</mat-icon>
          </span>
          <span *ngIf="params.newsday">
            <span>{{params.newsday | date}}</span>
          </span>
          <span>
            <span>&nbsp;&#8226;&nbsp;</span>
            <span>{{notes.length}} Annotations</span>
          </span>
        </div>
        <div class="icon" *ngIf="params.newsday">
          <mat-icon (click)="newNote()" matTooltip="Annotation" *ngIf="params.id">add</mat-icon>
          <mat-icon (click)="outNote()" matTooltip="Exit">close</mat-icon>
        </div>
      </div>
      <div class="notes" *ngIf="onote">
        <div class="note" *ngFor="let note of notes; index as index" [class.active]="params.note==note.uid"
          (click)="linkNote(note)">
          <div class="cont">
            <div class="tito">{{note.annotation}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="count-message" *ngIf="params.time === '2'">
      <span>News counts for the past 24h are approximate</span>
    </div>
    <div class="main-count" *ngIf="datacount || (params.newstype === 'Stories') || (newstype === 'Stories') || (params.newstype === 'Events') || (newstype === 'Events')">
      <div *ngIf="!noSentiments && !(params.newstype === 'Stories') && !(newstype === 'Stories') && !(params.newstype === 'Events') && !(newstype === 'Events') && !routing.isScanner()" class="chartpie">
        <ngx-charts-pie-chart [view]="[80, 80]" [scheme]="ngxpie.color" [results]="ngxpie.data" [doughnut]="true"
          [arcWidth]=".3" (select)="inFilter($event.name)">
        </ngx-charts-pie-chart>
      </div>
      <div *ngIf="!noSentiments && !(params.newstype === 'Stories') && !(newstype === 'Stories') && !(params.newstype === 'Events') && !(newstype === 'Events') && !routing.isScanner()" class="legend">
        <div class="label" (click)="inFilter('pos')" [class.active]="isActive('pos')">
          <div class="bord pos"></div>
          <div class="tito">
            <div>Positive</div>
            <div>{{datacount.positiv | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('neu')" [class.active]="isActive('neu')">
          <div class="bord neu"></div>
          <div class="tito">
            <div>Neutral</div>
            <div>{{datacount.neutral | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('neg')" [class.active]="isActive('neg')">
          <div class="bord neg"></div>
          <div class="tito">
            <div>Negative</div>
            <div>{{datacount.negativ | thousandSuff}}</div>
          </div>
        </div>
        <div class="label" (click)="inFilter('all')" [class.active]="isActive('all')">
          <div class="bord all"></div>
          <div class="tito">
            <div>Total</div>
            <div>{{datacount.total | thousandSuff}}</div>
          </div>
        </div>
      </div>
      <div class="moreinfo" *ngIf="!routing.isScanner()" (click)="moreinfo=!moreinfo">
        <mat-icon *ngIf="!moreinfo">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="moreinfo">arrow_drop_up</mat-icon>
      </div>
    </div>
    <div class="more-info" *ngIf="moreinfo">
      <div class="info-blok">
        <mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isLicensedContent(licensedContent)" [(ngModel)]="licensedContent">Licensed / Full Text Articles</mat-slide-toggle>
        <mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isTitleEntity()" [(ngModel)]="titleEntity">Entity in Title</mat-slide-toggle>
        <mat-slide-toggle *ngIf="!routing.isNewsletter()" (change)="isDupli()" [(ngModel)]="duplicate">Exclude Duplicate News</mat-slide-toggle>
        <mat-slide-toggle *ngIf="routing.isDev()" (change)="isNoise(noise)" [(ngModel)]="noise">Sentiment News</mat-slide-toggle>
        <mat-slide-toggle *ngIf="(params.newstype !== 'Flagged') && (params.newstype !== 'Dismissed')" (change)="fadeoutSeen()" [(ngModel)]="fadeout">Dim Previously Read Articles</mat-slide-toggle>
        <mat-slide-toggle *ngIf="(params.newstype === 'Flagged')" (change)="dimResolved()" [(ngModel)]="dimmed">Dim resolved cases</mat-slide-toggle>
        <mat-slide-toggle (change)="changeNoSentiments()" [(ngModel)]="noSentiments">Hide Sentiment</mat-slide-toggle>
      </div>
      <div class="line-row"></div>
      <div class="line-row"></div>
    </div>
  </div>
</div>
