import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { MatDialog } from '@angular/material/dialog';
// import { StepsWelcomeComponent } from '../../main-steps/steps-welcome/steps-welcome.component'
import { UntypedFormControl, Form } from '@angular/forms';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-menu-bside',
  templateUrl: './menu-bside.component.html',
  styleUrls: ['./menu-bside.component.scss']
})
export class BsideComponent {

  // filter: any
  sort: any;
  mypath: any;
  path: string;
  newsinfo: any;
  params: any;
  project: any;
  topicloud: boolean;
  entities = [];
  menuFilter: UntypedFormControl;
  menuFilter2: string;
  setup: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public dialog: MatDialog,
    public auth: AuthService,
    public config: ConfigService
  ) {

    this.project = this.config.appConfig.routing.project;
    this.setup = this.config.appConfig.setup;

    if (routing.isDev()) {
      this.entities = ['subsector', 'company', 'stock_index', 'country', 'region', 'sector'];
    } else {
      this.entities = ['subsector', 'company', 'stock_index', 'country'];
    }

    this.router.events.subscribe(event => {
      this.path = this.router.routerState.snapshot.url.split(/\?/)[0].split(/\//)[3];
      this.route.queryParams.subscribe(params => {
        // params.filter ? this.filter = params.filter : this.filter = 'all'
        if (params.defaultsort) {
          this.sort = params.defaultsort;
        } else if (params.sort) {
          this.sort = params.sort;
        } else {
          this.sort = 'trend';
        }
      });
    });

    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (params.defaultsort) {
        this.sort = params.defaultsort;
      } else if (params.sort) {
        this.sort = params.sort;
      }
      this.menuFilter = new UntypedFormControl('');
      params.topicloud ? this.topicloud = this.getBool(params.topicloud) : this.topicloud = true;
      if (params.entity) {
        this.menuFilter = new UntypedFormControl(params.entity.split(','));
      }
      if (params.how) {
        params.how ? this.menuFilter2 = params.how : this.menuFilter2 = 'sentiment_delta';
      }
    });

  }

  getBool(bool) {
    return bool === 'true' ? true : false;
  }

  // isFilter(filter) {
  //   if (this.filter === filter) { return 'radio_button_checked' }
  //   return 'radio_button_unchecked'
  // }

  goSort(value) {
    if (this.sort === value) {
      const sort = this.sort.split('-');
      if (sort[1]) {
        this.sort = sort[1];
      } else {
        this.sort = '-' + this.sort;
      }
      if (this.params.defaultsort) {
        this.router.navigate([], {
          queryParams: { defaultsort: this.sort },
          queryParamsHandling: 'merge',
          replaceUrl: false
        });
      } else {
        this.router.navigate([], {
          queryParams: { sort: this.sort },
          queryParamsHandling: 'merge',
          replaceUrl: false
        });
      }
    } else {
      if (this.params.defaultsort) {
        this.router.navigate([], {
          queryParams: { defaultsort: value },
          queryParamsHandling: 'merge',
          replaceUrl: false
        });
      } else {
        this.router.navigate([], {
          queryParams: { sort: value },
          queryParamsHandling: 'merge',
          replaceUrl: false
        });
      }
    }
  }

  goFilter(event) {
    const entity = this.menuFilter.value.join(',');
    // if (this.menuFilter.value.length === this.entities.length) {
    //   lang = null
    // }
    // if (this.menuFilter.value.length === 0) {
    // this.menuFilter = new FormControl(this.entities.map(option => option))
    // lang = null
    // }
    this.router.navigate([], {
      queryParams: { entity: entity },
      queryParamsHandling: 'merge',
    });
  }

  goFilter2(event) {
    this.router.navigate([], {
      queryParams: { how: event.value },
      queryParamsHandling: 'merge',
    });
  }

  // haveParams() {
  //   if (this.params.id || this.params.query || this.params.folio) {
  //     return true
  //   }
  //   return false
  // }

  iMain() {
    this.router.navigate(['./main'], {
      queryParams: Object.assign({}, this.iQparams(), (this.params.groupId ? {id: this.params.groupId} : null), (this.params.portfolioId ? {id: this.params.portfolioId, type: null, portfolioId: null} : null), {sort: (this.routing.isQuery()) ? 'ratio' : (!(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti')}),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iScan() {
    this.router.navigate(['./scanner'], {
      queryParams: Object.assign({}, this.iQparams(), (this.params.groupId ? {id: this.params.groupId} : null), (this.params.portfolioId ? {id: this.params.portfolioId, type: null, portfolioId: null} : null), {sort: !(this.auth.scorelabSettings.defaultScore === 'sentiment') ? 'score' : '-senti'}),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iChart() {
    this.router.navigate(['./chart'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iChart2() {
    this.router.navigate(['./chart2'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iParticipants() {
    this.router.navigate(['./participants'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iMulti() {
    this.router.navigate(['./multi'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iRadar() {
    this.router.navigate(['./radar'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iNetwork() {
    this.router.navigate(['./network'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iNewsaudit() {
    this.router.navigate(['./audit'], {
      queryParams: this.iNewsauditParams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iPreview() {
    this.router.navigate(['./preview'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iArticles() {
    this.router.navigate(['./articles'], {
      queryParams: this.iQparams(),
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
  iQparams() {
    return {
      updatePayload: null,
      news: null,
      event: null,
      newsaudit: null,
      eventid: null,
      eventype: null,
      scoreevent: null,
      flagevent: null,
      tag: null,
      participant: null,
      newstype: null,
      eventsFromScanner: null,
      reflow: Math.random(),
      queryid: null,
      newsfeedFromQuery: null,
      score: null
    };
  }
  iNewsauditParams() {
    return {
      updatePayload: null,
      news: null,
      event: null,
      newsaudit: true,
      eventid: null,
      eventype: null,
      scoreevent: null,
      flagevent: null,
      tag: null,
      participant: null,
      newstype: 'Chronological',
      eventsFromScanner: null,
      queryid: null,
      newsfeedFromQuery: null,
      score: null
    };
  }
  scoreTooltip() {
    return this.auth.getScoreName().full;
  }

}
